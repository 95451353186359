import React from 'react';
import { avatarSad } from 'assets';

const TcEmptyList = () => {
  return (
    <div className="tw-mt-4 tw-flex tw-flex-col tw-justify-center tw-items-center">
      <img src={avatarSad} />
      <div className="tw-pt-2">No Data To Display</div>
    </div>
  );
};

export default TcEmptyList;
