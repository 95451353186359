import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { TcToastsList } from './common/components';
import AppStackRoute from './routes';
import defaultMaterialTheme from 'configs/themes/defaultMaterialTheme';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={defaultMaterialTheme}>
        <TcToastsList>
          <AppStackRoute />
        </TcToastsList>
      </ThemeProvider>
    </Router>
  );
};

export default App;
