import {
  CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH,
  CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS,
} from 'store/actions';
import { INITIAL_STATE } from './defaultCashPrizeRequestListPaginationState';

const cashPrizeRequestListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE:
      return { ...state, page: action.payload };
    case CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH:
      return { ...state, search: action.payload };
    case CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

export default cashPrizeRequestListPaginationReducer;
