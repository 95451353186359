// controlled version of TcInput without memo and forwardRef
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { FormInput } from './index';

const TcInputWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const StyledIconContainer = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const PasswordFormInput = (props) => {
  const { full, isHidden, setHidden } = props;

  return (
    <TcInputWrapperStyled full={full}>
      <FormInput {...props} type={isHidden ? 'password' : 'text'} />
      <StyledIconContainer>
        {isHidden ? (
          <Visibility onClick={() => setHidden(!isHidden)} />
        ) : (
          <VisibilityOff onClick={() => setHidden(!isHidden)} />
        )}
      </StyledIconContainer>
    </TcInputWrapperStyled>
  );
};

PasswordFormInput.propTypes = {
  icon: PropTypes.object,
  renderIcon: PropTypes.any,
  setHidden: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  textArea: PropTypes.bool,
  full: PropTypes.bool,
};

export default PasswordFormInput;
