import { inviteCodesAPI } from 'configs';
import { handleAsync } from 'utils';

const getInvitations = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.getInvitations(parameters));
  if (error) throw error;
  return res;
};

const deleteInvitation = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.deleteInvitation(parameters));
  if (error) throw error;
  return res;
};

const deactivateInvitation = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.deactivateInvitation(parameters));
  if (error) throw error;
  return res;
};

const createTaggerReferralInvitation = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.createTaggerReferralInvitation(parameters));
  if (error) throw error;
  return res;
};

const createNonTaggerReferralInvitation = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.createNonTaggerReferralInvitation(parameters));
  if (error) throw error;
  return res;
};

const checkCodeAvailable = async (parameters) => {
  const [res, error] = await handleAsync(inviteCodesAPI.checkCodeAvailable(parameters));
  if (error) throw error;
  return res;
};

const InviteCodesService = {
  getInvitations,
  deleteInvitation,
  deactivateInvitation,
  createTaggerReferralInvitation,
  createNonTaggerReferralInvitation,
  checkCodeAvailable,
};

export { InviteCodesService };
