import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { removeToast } from 'store/actions';
import TcSvgIcon from '../TcSvgIcon';

const TcToastItem = styled.li`
  display: flex;
  min-width: 250px;
  top: 10px;
  right: 10px;
  border-color: ${({ type }) => handleTypeColor(type)};
  background-color: ${({ type }) => handleTypeColor(type)};
`;

const TcToastClass = (styleType, capitalize) => `
  tw-mb-2
  ${capitalize ? 'tw-capitalize' : ''}
  tw-text-center
  tw-rounded
  tw-p-4
  ${styleType === 'warning' ? 'tw-text-black' : 'tw-text-white'}
`;

const TcToastContent = styled.p`
  flex: 1 1 auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TcToastDismiss = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
  &:focus {
    outline: none;
  }
`;

const handleTypeColor = (styleType) => {
  switch (styleType) {
    case 'success':
      return 'var(--color-green-300)';
    case 'error':
      return 'var(--color-red-500)';
    case 'warning':
    case 'info':
    default:
      return '#ffc107';
  }
};

const TcToastNew = (props) => {
  const {
    id,
    styleType,
    message,
    onDismissClick,
    closeTiming,
    removeToast,
    icon,
    iconId,
    toastItemclassName,
    toastContentClassName,
    capitalize = true,
  } = props;

  useEffect(() => {
    if (closeTiming) {
      setTimeout(() => removeToast(id), closeTiming);
    }
  }, [closeTiming]);

  return (
    <TcToastItem className={`${TcToastClass(styleType, capitalize)} ${toastItemclassName}`} type={styleType}>
      {icon && <TcSvgIcon id={iconId} src={icon} width={'35px'} />}
      <TcToastContent className={toastContentClassName}>{message}</TcToastContent>
      <TcToastDismiss onClick={onDismissClick}>x</TcToastDismiss>
    </TcToastItem>
  );
};

TcToastNew.propTypes = {
  id: PropTypes.number,
  styleType: PropTypes.string,
  message: PropTypes.string.isRequired,
  onDismissClick: PropTypes.func.isRequired,
  closeTiming: PropTypes.number,
  removeToast: PropTypes.func,
  icon: PropTypes.func,
  iconId: PropTypes.string,
  toastItemclassName: PropTypes.string,
  toastContentClassName: PropTypes.string,
  capitalize: PropTypes.bool,
};

export default connect(null, { removeToast })(TcToastNew);
