import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';

const TcCheckbox = (props) => {
  const { checked, setChecked, type, label, disabled, indeterminate, classes } = props;

  return type === 'label' ? (
    <FormControlLabel
      control={
        <Checkbox
          indeterminate={indeterminate}
          disabled={disabled}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
          inputProps={{ 'aria-label': 'filter checkbox' }}
        />
      }
      classes={classes}
      label={label}
    />
  ) : (
    <Checkbox
      indeterminate={indeterminate}
      disabled={disabled}
      checked={checked}
      onChange={setChecked}
      inputProps={{ 'aria-label': 'filter checkbox' }}
      disableRipple
      {...props}
    />
  );
};

TcCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  classes: PropTypes.object,
};

export default TcCheckbox;
