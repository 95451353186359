import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { angleDownIcon } from 'assets';

const TcButtonClass = `
  tw-bg-transparent
  tw-outline-none
  focus:tw-outline-none
`;

const TcButtonExpandIcon = styled.img`
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.25s;
  outline: none;
`;

const TcButtonExpand = (props) => {
  const { expanded, onClick } = props;

  return (
    <button className={TcButtonClass} onClick={onClick}>
      <TcButtonExpandIcon expanded={expanded} src={angleDownIcon} width="18" />
    </button>
  );
};

TcButtonExpand.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TcButtonExpand;
