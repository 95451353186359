import { leaderboardAPI } from 'configs';
import { handleAsync } from 'utils';

const getList = async (parameters) => {
  const [res, error] = await handleAsync(leaderboardAPI.getList(parameters));
  if (error) throw error;
  return res;
};

const LeaderboardService = {
  getList,
};

export { LeaderboardService };
