import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { backIcon } from 'assets';
import { TcLabel, TcButton } from 'common/components';
import { JobsActions } from 'store/actions';

const SidebarClass = `
  tw-px-8
`;

const StepWrapper = styled.div`
  & .step-item {
    &:last-child {
      & .step-index {
        &:after {
          display: none;
        }
      }
    }
  }
`;

const StepItemClass = `
  step-item
  tw-flex
  tw-items-center
  tw-py-6
`;

const ContentStepItemClass = ({ isDisabled }) => `
  tw-font-extrabold
  tw-text-md
  ${
    isDisabled
      ? `
    tw-text-gray-500
    tw-cursor-default`
      : `
    tw-text-gray-1300
    tw-cursor-pointer`
  }
    
`;

const IndexStepItem = styled.div`
  border-radius: 100px;
  height: 25px;
  width: 25px;
  z-index: 20;

  &:after {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;
    width: 2px;
    height: 29px;
    background-color: #cccccc;
    transform: translate(0, 33px);
  }
`;
const getIndexStepItemStyling = ({ active, isDisabled }) => {
  if (active) {
    return `
		tw-bg-green-500
    tw-text-white`;
  } else if (isDisabled) {
    return `  
		tw-bg-gray-200
		tw-text-gray-500
		tw-cursor-default`;
  } else {
    return `
		tw-bg-gray-400
		tw-text-gray-1300
		tw-cursor-pointer`;
  }
};

const IndexStepItemClass = ({ active, isDisabled }) => `
  step-index
  tw-flex
  tw-justify-center
  tw-items-center
  tw-mr-4
  tw-font-extrabold
  ${getIndexStepItemStyling({ active, isDisabled })}
`;

const JobCreateSidebar = (props) => {
  const { isQcJobCreation, currentStep, steps } = props;
  const goToDashboard = () => {
    if (currentStep > 1) props.resetCurrentStep();

    window.directTo.push('job-list');
  };

  const RenderStepItem = () => {
    return Object.entries(steps).map(([step, name], index) => {
      const isDisabled = isQcJobCreation && (index === 0 || index === 2);
      return (
        <div className={StepItemClass} key={name}>
          <IndexStepItem
            className={IndexStepItemClass({
              active: currentStep === Math.trunc(step),
              isDisabled: isDisabled,
            })}
          >
            {step}
          </IndexStepItem>
          <div className={ContentStepItemClass({ isDisabled: isDisabled })}>{name}</div>
        </div>
      );
    });
  };

  return (
    <div className={SidebarClass}>
      <TcButton
        className="tw-mb-4 tw-font-extrabold tw-text-gray-300"
        onClick={() => goToDashboard()}
        label="Back to Dashboard"
        type="plain"
        icon={{
          src: backIcon,
          alt: 'back icon',
        }}
        renderIcon
        noPadding
      />
      <hr />
      <TcLabel className="tw-pt-4 tw-text-gray-1200 tw-font-extrabold tw-text-xl" title="Add New Job" />
      <StepWrapper>
        <RenderStepItem />
      </StepWrapper>
    </div>
  );
};

JobCreateSidebar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.object.isRequired,
  resetCurrentStep: PropTypes.func,
  isQcJobCreation: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.jobCreateStep.currentStep,
    steps: state.jobCreateStep.steps,
  };
};

export default connect(mapStateToProps, { resetCurrentStep: JobsActions.resetCurrentStep })(JobCreateSidebar);
