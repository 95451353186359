// eslint-disable-next-line import/no-extraneous-dependencies
import { Experiment } from '@amplitude/experiment-js-client';
import { featureFlagValue } from 'common/constants/global';

const experiment = Experiment.initialize(process.env.REACT_APP_FEATURE_FLAGS_DEPLOYMENT_KEY);

const extractKey = (variants) => {
  const result = {};

  for (const keyOfVariant of Object.keys(variants)) {
    result[keyOfVariant] = variants[keyOfVariant].key;
  }

  return result;
};

const FeatureFlagsUtils = {
  getFlag: async (flagName) => {
    await experiment.fetch();
    const variant = experiment.variant(flagName).key;

    return variant === undefined ? featureFlagValue.off : variant;
  },
  getAllFlags: async () => {
    await experiment.fetch();
    const variants = experiment.all();

    return extractKey(variants);
  },
};

export default FeatureFlagsUtils;
