import React from 'react';
import styled from 'styled-components';
import { TcTictagLoader } from 'common/components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingScreen = () => {
  return (
    <Container>
      <TcTictagLoader />
    </Container>
  );
};

export default LoadingScreen;
