/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useRef, useContext } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import { TcBoundingPolygon, TcBoundingBox } from 'common/components';
import { JobMethod } from 'common/constants/global';
import Polygon from 'pages/PreTaskScreening/components/polygon';
import PolygonContext from 'pages/PreTaskScreening/context';
import { useFunctionDebounce } from 'utils';

const CanvasImage = (props) => {
  const {
    canvasDraggable,
    imageCanvas,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onZoomOnScroll,
    stageReference,
    layerReference,
    shapesToDraw,
    shapeDraggable,
    selectedShapeId,
    onSelectShape,
    onEditShape,
    resize,
    forceAlignCenter,
    taskMethod,
    zoomValue,
    currentMousePos,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handleDragMovePoint,
    handleDragPointBox,
    onShapeClick,
    onStageDragStart = () => {},
    onStageContentClick = () => {},
    onCenterScale = () => {},
    shapesLabels,
  } = props;

  const canvasContainerReference = useRef(null);
  const [stageWidth, setStageWidth] = useState(1000);
  const [stageHeight, setStageHeight] = useState(window.innerHeight);
  const [previousStageX, setPreviousStageX] = useState(0);
  const [baseScale, setBaseScale] = useState(1);
  const [autoDraggable, setAutoDraggable] = useState(null);
  const [isElementDragging, setIsElementDragging] = useState(false);

  const { dragablePoints, polygon, dragableImage, polygonActive, boundingBoxActive } = useContext(PolygonContext);
  const dragableCanvas = canvasDraggable ? canvasDraggable : dragableImage;
  const checkSize = () => {
    const { offsetWidth, offsetHeight } = canvasContainerReference.current || {};

    if (offsetWidth && offsetHeight) {
      setStageWidth(offsetWidth);
      setStageHeight(offsetHeight);
    }
  };

  const adaptStageSizeChanges = useFunctionDebounce(checkSize, 200);

  useEffect(() => {
    checkSize();

    const resizeObserver = new ResizeObserver(() => {
      adaptStageSizeChanges();
    });

    resizeObserver.observe(canvasContainerReference.current);

    window.addEventListener('resize', adaptStageSizeChanges);
    return () => window.removeEventListener('resize', adaptStageSizeChanges);
  }, []);

  useEffect(() => {
    if (stageReference?.current && imageCanvas) {
      const stage = stageReference.current;
      // resize to fit the width
      let baseScale = stage.width() / imageCanvas.width;
      const newImageHeight = imageCanvas.height * baseScale;
      // shrink to fit the height if necessary
      if (newImageHeight > stage.height()) {
        baseScale = stage.height() / imageCanvas.height;
      }
      setBaseScale(baseScale);
      stage.scale({ x: baseScale, y: baseScale });
      onCenterScale(baseScale);

      // Calculate stage's x to make it centered in the canvas
      const stageX = (stageWidth - baseScale * imageCanvas.width) / 2;
      // Do not reposition unless the image is changed
      // or forced aligned with forceAlignCenter property
      // Might also need to check by image's url (id)
      // if the image dimensions happen to be identical?
      if (stageX !== previousStageX || forceAlignCenter) {
        stage.position({ x: stageX, y: 0 });
        setPreviousStageX(stageX);
      }
      stage.batchDraw();
    }
  }, [stageReference, imageCanvas, stageWidth, stageHeight, forceAlignCenter]);

  return (
    <div
      ref={canvasContainerReference}
      style={{
        cursor: polygonActive || boundingBoxActive ? 'crosshair' : 'default',
      }}
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === 'Control' && !isElementDragging) {
          setAutoDraggable(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Control' && autoDraggable) {
          setAutoDraggable(null);
        }
      }}
    >
      <Stage
        width={stageWidth}
        height={stageHeight}
        draggable={autoDraggable ?? dragableCanvas}
        onDragStart={(event) => onStageDragStart(event, autoDraggable)}
        onContentClick={(event) => onStageContentClick(event)}
        onMouseUp={onMouseUp}
        onWheel={onZoomOnScroll}
        ref={stageReference}
      >
        <Layer ref={layerReference} onMouseMove={onMouseMove} onMouseDown={onMouseDown}>
          <Image image={imageCanvas} perfectDrawEnabled={false} />
          {polygon?.map((point, index) => {
            return (
              <Polygon
                taskMethod={taskMethod}
                key={index}
                label={point?.label}
                zoomValue={zoomValue}
                scaleX={layerReference?.current?.scaleX()}
                scaleY={layerReference?.current?.scaleY()}
                polygon={point}
                isFinished={point?.isFinished}
                boundingBoxActive={boundingBoxActive}
                handleShapeLineClick={(e) => onShapeClick(e, index)}
                handleDragMovePoint={(e) => handleDragMovePoint(e, index)}
                handleMouseOverStartPoint={handleMouseOverStartPoint}
                handleMouseOutStartPoint={handleMouseOutStartPoint}
                currentMousePos={currentMousePos}
                dragablePoints={dragablePoints}
                handleDragPointBox={(e) => handleDragPointBox(e, index)}
                onElementDragStart={() => setIsElementDragging(true)}
                onElementDragEnd={() => setIsElementDragging(false)}
              />
            );
          })}
          {shapesToDraw.map((rect, index) => {
            switch (taskMethod) {
              case JobMethod.ImageBoundingPolygon:
              case JobMethod.ImageBoundingPolygonQcImageSwipeTwoWay:
                return (
                  <TcBoundingPolygon
                    key={index}
                    draggable={shapeDraggable}
                    boxProps={rect}
                    isSelected={rect.id === selectedShapeId}
                    onSelect={() => onSelectShape?.(rect.id)}
                    // Radius value is 5, default zoom value is 100
                    // Calculation to keep radius unchanging when scaling the image
                    pointRadius={5 / baseScale / ((zoomValue || 100) / 100)}
                  />
                );

              default:
                return (
                  <TcBoundingBox
                    key={index}
                    draggable={shapeDraggable}
                    boxProps={rect}
                    isSelected={rect.id === selectedShapeId}
                    onSelect={() => onSelectShape && onSelectShape(rect.id)}
                    onChange={onEditShape}
                    layerReference={layerReference}
                    resize={resize}
                    label={(shapesLabels && shapesLabels[index]) || undefined}
                    zoomValue={zoomValue}
                  />
                );
            }
          })}
        </Layer>
      </Stage>
    </div>
  );
};
CanvasImage.propTypes = {
  canvasDraggable: PropTypes.bool,
  imageCanvas: PropTypes.object,
  onMouseDown: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseUp: PropTypes.func,
  onZoomOnScroll: PropTypes.func,
  stageReference: PropTypes.shape({ current: PropTypes.any }),
  layerReference: PropTypes.shape({ current: PropTypes.any }),
  shapesToDraw: PropTypes.array,
  shapeDraggable: PropTypes.bool,
  selectedShapeId: PropTypes.number,
  onSelectShape: PropTypes.func,
  onEditShape: PropTypes.func,
  resize: PropTypes.bool,
  forceAlignCenter: PropTypes.bool,
  taskMethod: PropTypes.string,
  zoomValue: PropTypes.number,
  polygonActive: PropTypes.bool,
  dragableImage: PropTypes.bool,
  currentMousePos: PropTypes.object,
  handleDragMovePoint: PropTypes.func,
  handleMouseOverStartPoint: PropTypes.func,
  handleMouseOutStartPoint: PropTypes.func,
  boundingBoxActive: PropTypes.bool,
  onShapeClick: PropTypes.func,
  handleDragPointBox: PropTypes.func,
  setZoomValue: PropTypes.func,
  onStageDragStart: PropTypes.func,
  onStageContentClick: PropTypes.func,
  onCenterScale: PropTypes.func,
  shapesLabels: PropTypes.array,
};

CanvasImage.defaultProps = {
  resize: true,
  forceAlignCenter: false,
};

export default memo(CanvasImage);
