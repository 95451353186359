import { combineReducers } from 'redux';
import badgesListPaginationReducer from './BadgesList/badgesListPaginationReducers';
import cashPrizeRequestListPaginationReducer from './CashPrizeRequestList/cashPrizeRequestListPaginationReducers';
import coinTaggerListPaginationReducer from './CoinTaggerList/coinTaggerListPaginationReducers';
import deletionRequestListPaginationReducer from './DeletionRequestList/deletionRequestPaginationReducers';
import inviteCodesListPaginationReducer from './InviteCodesList/inviteCodesListPaginationReducers';
import jobListPaginationReducer from './JobList/jobListPaginationReducers';
import logListPaginationReducers from './LogList/logListPaginationReducers';
import qaMechanismDecisionListPaginationReducer from './QaMechanismDecisionList/qaMechanismDecisionListReducers';
import referralRecordListPaginationReducer from './ReferralRecordsList/referralRecordsListPaginationReducers';
import subJobsTaggerListPaginationReducer from './SubJobsTaggerList/subJobsTaggerListPaginationReducers';
import taggingReviewJobLevelListPaginationReducer from './TaggingReviewJobLevelList/reducers';
import userListPaginationReducer from './UserList/userListPaginationReducers';
import userTaskListPaginationReducers from './UserTaskList/userTaskListPaginationReducers';

export default combineReducers({
  jobListPagination: jobListPaginationReducer,
  userListPagination: userListPaginationReducer,
  userTaskListPagination: userTaskListPaginationReducers,
  coinTaggerListPagination: coinTaggerListPaginationReducer,
  inviteCodesListPagination: inviteCodesListPaginationReducer,
  badgesListPagination: badgesListPaginationReducer,
  cashPrizeRequestListPagination: cashPrizeRequestListPaginationReducer,
  subJobsTaggerListPagination: subJobsTaggerListPaginationReducer,
  taggingReviewJobLevelListPagination: taggingReviewJobLevelListPaginationReducer,
  referralRecordListPagination: referralRecordListPaginationReducer,
  deletionRequestListPagination: deletionRequestListPaginationReducer,
  qaMechanismDecisionListPagination: qaMechanismDecisionListPaginationReducer,
  logListPagination: logListPaginationReducers,
});
