import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

const TcIconClass = (onClick) => `
  tw-inline-block
  ${onClick ? 'tw-cursor-pointer' : ''}
`;

const StyledTcIcon = styled.img`
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  filter: ${({ active }) =>
    active ? 'invert(57%) sepia(77%) saturate(986%) hue-rotate(0deg) brightness(90%) contrast(100%)' : 'none'};
`;

const TcIcon = memo((props) => {
  const { src, alt, width, className, onClick, active } = props;

  return (
    <StyledTcIcon
      src={src}
      alt={alt}
      className={TcIconClass(onClick) + className}
      width={width}
      onClick={onClick}
      active={active}
    />
  );
});

TcIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

TcIcon.defaultProps = {
  width: '20px',
  className: '',
  active: false,
};

export default TcIcon;
