import { CHANGE_ACTIVE_MENU, CHANGE_SIDEBAR_TYPE } from './types';

export const changeSidebarType = (sidebarType) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
  };
};

export const changeActiveMenu = (selectedMenu) => {
  return {
    type: CHANGE_ACTIVE_MENU,
    payload: selectedMenu,
  };
};
