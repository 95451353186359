let id = 0;

// should contain message and closeTiming (optional) as properties in config object
const createToast = (config) => {
  return {
    id: id++,
    message: config.message,
    closeTiming: config.closeTiming,
    ...config,
  };
};

export default createToast;
