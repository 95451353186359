import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TcLabel from '../TcLabel';

const TcSelectClass = styled(Select)`
  && .MuiInput-input {
    padding: 7px 14px;
    background-color: white;
    border: 1px solid #ededed;
    border-radius: 0.5rem;
    line-height: 1.5;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 2rem;
    ${(props) => props.height && `height: ${props.height}px;`}
  }

  .MuiSelect-icon.MuiSelect-icon {
    width: 2rem;
    color: ${(props) => props.iconColor};
  }
`;

const TcMenuItemClass = styled(MenuItem)`
  && {
    font-weight: 700;
    padding-left: 10px;
    cursor: none;
    pointer-events: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
  icon: {
    right: '0.6rem',
  },
}));

const TcSelect = (props) => {
  const {
    onChange,
    value,
    options,
    name,
    error,
    helperText,
    className,
    variant,
    disabled,
    full,
    placeholder,
    onClick,
    textColor,
    height,
    boldPlaceholder,
  } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth={full} error={error}>
      <TcSelectClass
        height={height}
        disableUnderline
        className={className}
        variant={variant}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        classes={{ icon: classes.icon }}
        onClick={onClick}
        style={{ color: textColor }}
        iconColor={textColor}
      >
        {placeholder && boldPlaceholder ? (
          <TcMenuItemClass>
            <TcLabel title={placeholder} />
          </TcMenuItemClass>
        ) : (
          <MenuItem className="tw-text-base tw-font-black" disabled={boldPlaceholder} value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem className="tw-font-extrabold" key={option.key} value={option.key}>
              {option.text}
            </MenuItem>
          );
        })}
      </TcSelectClass>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

TcSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  height: PropTypes.number,
  boldPlaceholder: PropTypes.bool,
};

TcSelect.defaultValue = {
  boldPlaceholder: false,
};

export default TcSelect;
