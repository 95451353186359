import { SIGN_IN_ERROR, SIGN_IN_SUCCESS, SIGN_OUT } from 'store/actions';
import { checkCredentials, destroy, setCredentials } from 'utils';

// empty login object
const INITIAL_STATE = {
  isLogin: false,
  isRegister: false,
  signinMessage: null,
  user: {},
};

const init = (initState) => {
  const authCredentials = checkCredentials();

  if (!authCredentials) return initState;
  if (Object.keys(authCredentials).length === 0) return initState;
  if (authCredentials.accessToken === '') return initState;

  return {
    ...initState,
    isLogin: true,
    user: authCredentials,
  };
};

const authReducer = (state = init(INITIAL_STATE), action) => {
  const { type, payload } = action;
  switch (type) {
    case SIGN_IN_SUCCESS:
      setCredentials(payload);
      return {
        ...state,
        isLogin: true,
        signinMessage: null,
        user: { ...payload },
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        isLogin: false,
        signinMessage: payload,
        user: {},
      };
    case SIGN_OUT:
      destroy();
      return {
        ...state,
        isLogin: false,
        signinMessage: null,
        user: {},
      };
    default:
      return state;
  }
};

export default authReducer;
