import React, { Suspense, useState } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { TcMainTemplate } from 'common/components';
import LoadingScreen from 'pages/LoadingScreen';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';
import PublicRoutes from './PublicRoutes';
import { AdminRoute, ClientRoute, PublicRoute, ExceptionalRoute } from './components';

const AppStackRoute = withRouter(({ history }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  window.directTo = history;
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <TcMainTemplate showSidebar={showSidebar}>
          <Switch>
            {AdminRoutes.map((route) => (
              <AdminRoute key={route} setShowSidebar={setShowSidebar} {...route} />
            ))}
            {ClientRoutes.map((route) => (
              <ClientRoute key={route} {...route} />
            ))}
            {PublicRoutes.map((route) => (
              <PublicRoute key={route} {...route} />
            ))}
            <ExceptionalRoute />
          </Switch>
        </TcMainTemplate>
      </Suspense>
    </>
  );
});

export default AppStackRoute;
