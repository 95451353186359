import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PaginationWrapperClass = `
  tw-float-right
  tw-inline-block
  tw-my-2
`;

const PaginationWrapper = styled.div``;

const PaginationItem = styled.button`
  transition: 0.3s;
  width: 30px;
  height: 30px;

  &:hover&:not([disabled]),
  &.active {
    color: var(--color-white);
    background-color: var(--color-orange-400);
  }
`;

const PaginationItemClass = `
  tw-btn
  tw-flex-inline
  tw-justify-center
  tw-items-center
  tw-text-center
  tw-py-1
  tw-mx-1
  tw-text-gray-1200
  tw-border
  tw-border-solid
  tw-border-white
  tw-rounded-full
  focus:tw-outline-none
  disabled:tw-cursor-not-allowed
  disabled:tw-opacity-50
`;

const PaginationItemNavigation = styled.button`
  transition: 0.3s;

  &:hover&:not([disabled]),
  &.active {
    color: var(--color-orange-400);
  }
`;

const PaginationItemNavigationClass = `
  tw-btn
  tw-flex-inline
  tw-justify-center
  tw-items-center
  tw-text-center
  tw-py-1
  tw-mx-3
  tw-text-gray-1200
  tw-border
  tw-border-solid
  tw-border-white
  focus:tw-outline-none
  disabled:tw-cursor-not-allowed
  disabled:tw-opacity-50
`;

const TcPagination = (props) => {
  const { paginate, activePage, intervalPage, handleCallback, noGuide } = props;

  const [startShowPage, setStartShowPage] = useState(1);
  const [endShowPage, setEndShowPage] = useState(intervalPage);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    const newTotalPage = paginate?.pages?.last || 1;

    setTotalPage(newTotalPage);

    if (activePage <= intervalPage) {
      setStartShowPage(1);
    } else {
      setStartShowPage(activePage - intervalPage);
    }

    if (activePage >= newTotalPage - intervalPage) {
      setEndShowPage(newTotalPage);
    } else {
      setEndShowPage(activePage + intervalPage);
    }
  }, [paginate, activePage, intervalPage]);

  const handleChangePagination = (page) => {
    switch (page) {
      case 'first':
        setStartShowPage(1);
        setEndShowPage(intervalPage);
        handleCallback(1);
        break;
      case 'prev':
        if (activePage <= startShowPage) {
          setStartShowPage(startShowPage - 1);
          setEndShowPage(endShowPage - 1);
        }
        handleCallback(activePage - 1);
        break;
      case 'beforeSection':
        setStartShowPage(startShowPage - intervalPage - 1);
        setEndShowPage(endShowPage - intervalPage - 1);
        handleCallback(activePage - intervalPage - 1);
        break;
      case 'nextSection':
        setStartShowPage(startShowPage + intervalPage + 1);
        setEndShowPage(endShowPage + intervalPage + 1);
        handleCallback(activePage + intervalPage + 1);
        break;
      case 'next':
        if (activePage >= endShowPage) {
          setStartShowPage(startShowPage + 1);
          setEndShowPage(endShowPage + 1);
        }
        handleCallback(activePage + 1);
        break;
      case 'last':
        setStartShowPage(totalPage - intervalPage);
        setEndShowPage(totalPage);
        handleCallback(totalPage);
        break;
      default:
        handleCallback(page);
        break;
    }
  };

  const RenderItemPagination = ({ totalRenderPage, handleStartShowPage, handleEndShowPage }) => {
    const element = [];

    if (handleStartShowPage !== 1) {
      element.push(
        <PaginationItemNavigation
          className={PaginationItemNavigationClass}
          key="beforeSection"
          onClick={() => handleChangePagination('beforeSection')}
        >
          ...
        </PaginationItemNavigation>,
      );
    }

    for (let index = 1; index <= totalRenderPage; index++) {
      if (index >= handleStartShowPage && index <= handleEndShowPage) {
        element.push(
          <PaginationItem
            className={`${PaginationItemClass} ${activePage === index ? 'active' : ''} `}
            key={index}
            onClick={() => handleChangePagination(index)}
          >
            {index}
          </PaginationItem>,
        );
      }
    }

    if (handleEndShowPage < totalRenderPage) {
      element.push(
        <PaginationItemNavigation
          className={PaginationItemNavigationClass}
          key="nextSection"
          onClick={() => handleChangePagination('nextSection')}
        >
          ...
        </PaginationItemNavigation>,
      );
    }

    return element;
  };

  return (
    <>
      <PaginationWrapper className={PaginationWrapperClass}>
        <PaginationItemNavigation
          className={`${PaginationItemNavigationClass} ${noGuide || totalPage === 0 ? 'tw-hidden' : ''}`}
          key="first"
          onClick={() => handleChangePagination('first')}
          disabled={activePage === 1}
        >
          First
        </PaginationItemNavigation>
        <PaginationItemNavigation
          className={`${PaginationItemNavigationClass} ${noGuide || totalPage === 0 ? 'tw-hidden' : ''}`}
          key="prev"
          onClick={() => handleChangePagination('prev')}
          disabled={activePage === 1}
        >
          Prev
        </PaginationItemNavigation>

        <RenderItemPagination
          totalRenderPage={totalPage}
          handleStartShowPage={startShowPage}
          handleEndShowPage={endShowPage}
        />

        <PaginationItemNavigation
          className={`${PaginationItemNavigationClass} ${noGuide || totalPage === 0 ? 'tw-hidden' : ''}`}
          key="next"
          onClick={() => handleChangePagination('next')}
          disabled={activePage === totalPage}
        >
          Next
        </PaginationItemNavigation>
        <PaginationItemNavigation
          className={`${PaginationItemNavigationClass} ${noGuide || totalPage === 0 ? 'tw-hidden' : ''}`}
          key="last"
          onClick={() => handleChangePagination('last')}
          disabled={activePage === totalPage}
        >
          Last
        </PaginationItemNavigation>
      </PaginationWrapper>
    </>
  );
};

TcPagination.propTypes = {
  paginate: PropTypes.object.isRequired,
  activePage: PropTypes.number.isRequired,
  intervalPage: PropTypes.number.isRequired,
  handleCallback: PropTypes.func.isRequired,
  noGuide: PropTypes.bool,
};

TcPagination.defaultProps = {
  noGuide: false,
};

export default TcPagination;
