import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TcAvatar, TcLabel } from 'common/components';
import { featureFlagValue } from 'common/constants/global';
import { UserService } from 'services';
import { AuthService, changeActiveMenu, changeSidebarType } from 'store/actions';
import { handleAsync, useAllFeatureFlags } from 'utils';
import { MENU } from './menu';

const WrapperMenu = styled.div`
  height: 100vh - 2rem;
`;

const Menu = styled.div`
  border-color: transparent;
  &:hover {
    border-color: #f6ad55;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #f6ad55;
      font-weight: 700;
    `}
`;

const MenuStyleClass = `
  tw-flex
  tw-items-center
  tw-pl-8
  tw-mb-4
  tw-border-r-2
  tw-cursor-pointer
`;

const CustomDiv = styled.div`
  position: absolute;
  bottom: 0;
`;
const CustomDivStyleClass = `
  tw-flex
  tw-pl-8
  tw-mb-4
  tw-border-r-2
`;

const BottomMenu = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 280px;
  flex-direction: column;
`;

const BottomMenuStyleClass = `
  tw-pl-8
  tw-pt-6
  tw-border-t-2
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const LastMenu = styled.div`
  border-color: transparent;

  &:hover {
    border-color: #f6ad55;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #f6ad55;
      font-weight: 700;
    `}
`;

const LastMenuStyleClass = `
  tw-flex
  tw-mt-6
  tw-mb-4
  tw-ml-4
  tw-border-r-2
  tw-cursor-pointer
`;

const MenuTitleStyleClass = `
  tw-inline-block
  tw-pl-3
`;

const MainSidebar = (props) => {
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [logoutMenu, setLogoutMenu] = useState([]);

  const allFlags = useAllFeatureFlags();

  const { logout, profileUpdated, changeSidebarType } = props;

  useEffect(() => {
    let SELECTED_MENU = MENU.filter((item) => !item.link.includes('logout'));
    setLogoutMenu(MENU.filter((item) => item.link.includes('logout')));
    SELECTED_MENU = SELECTED_MENU.filter((item) => {
      if (item.featureFlagGuard) {
        return !allFlags[item.featureFlagGuard] || allFlags[item.featureFlagGuard] === featureFlagValue.off;
      }
      return true;
    });

    setSelectedMenu(SELECTED_MENU);
  }, [allFlags]);

  const handleLogout = () => {
    logout();
  };

  const goTo = (selectedMenu) => {
    if (selectedMenu.link.includes('logout')) {
      changeSidebarType('');
      return handleLogout();
    }
    changeActiveMenu(selectedMenu.link);
    window.directTo.push(`${selectedMenu.link}`);
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const [response] = await handleAsync(UserService.getProfile());
      if (response) setUserDetails(response);
    };
    getUserProfile();
  }, [profileUpdated]);

  return (
    <WrapperMenu>
      {selectedMenu.map((menu, menuKey) => {
        const isActiveMenu = location.pathname.includes(menu.link);
        return (
          <Menu key={menuKey} className={MenuStyleClass} isActive={isActiveMenu} onClick={() => goTo(menu)}>
            <img alt="logo" src={isActiveMenu ? menu.iconActive : menu.icon} />
            <span className={MenuTitleStyleClass}>{menu.title}</span>
          </Menu>
        );
      })}
      <CustomDiv className={CustomDivStyleClass}>
        <hr />
      </CustomDiv>
      <BottomMenu className={BottomMenuStyleClass}>
        <Details onClick={() => window.directTo.push('user-detail')}>
          <TcAvatar className="tw-cursor-pointer" size={60}>
            <img alt="User Avatar" src={userDetails.avatar} />
          </TcAvatar>

          <Text className="tw-ml-6 tw-cursor-pointer">
            <TcLabel title={userDetails.name ? userDetails.name : 'Name'} className="tw-text-lg" noMargin />
            <TcLabel
              title={userDetails.company ? userDetails.company : '-'}
              color="var(--color-gray-800)"
              className="tw-text-sm"
              noMargin
            />
          </Text>
        </Details>

        {logoutMenu.map((menu, menuKey) => (
          <LastMenu key={menuKey} className={LastMenuStyleClass} isActive={false} onClick={() => goTo(menu)}>
            <img alt="logo" src={menu.icon} />
            <span className={MenuTitleStyleClass}>{menu.title}</span>
          </LastMenu>
        ))}
      </BottomMenu>
    </WrapperMenu>
  );
};

MainSidebar.propTypes = {
  logout: PropTypes.func,
  profileUpdated: PropTypes.bool,
  changeSidebarType: PropTypes.func,
};

export default connect(null, {
  logout: AuthService.logout,
  changeSidebarType,
})(MainSidebar);
