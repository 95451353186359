import PropTypes from 'prop-types';
import React from 'react';

const TcMessageBoxClass = ({ type }) => `
  tw-text-xs
  tw-py-4
  tw-px-6
  tw-border
  tw-rounded
  ${typeSwitcher(type)}
`;

const typeSwitcher = (type) => {
  switch (type) {
    case 'error':
      return `
        tw-bg-red-100
        tw-border-red-300
        tw-text-red-300
      `;
    case 'success':
      return `
        tw-bg-green-100
        tw-border-green-300
        tw-text-green-300
      `;
    default:
      return `
        tw-bg-gray-500
        tw-border-gray-800
        tw-text-gray-800
      `;
  }
};

const TcMessageBox = (props) => {
  const { children, className, type, show } = props;

  const RenderTcMessageBox = () => {
    if (!show) return null;

    return <div className={TcMessageBoxClass({ type }) + className}>{children}</div>;
  };

  return <RenderTcMessageBox />;
};

TcMessageBox.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  show: PropTypes.bool,
  className: PropTypes.string,
};

TcMessageBox.defaultProps = {
  type: 'default',
  show: false,
  className: '',
};

export default TcMessageBox;
