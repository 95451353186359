import {
  CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD,
  CLEAR_PAYLOAD,
  ADD_BOUNDING_BOX,
  EDIT_BOUNDING_BOX,
  DELETE_BOUNDING_BOX,
  DELETE_LAST_BOUNDING_BOX,
} from './types';

export const changePreTaskScreeningCreatePayload = (payload) => {
  return {
    type: CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD,
    payload,
  };
};

export const clearPreTaskScreeningPayload = () => {
  return {
    type: CLEAR_PAYLOAD,
  };
};

export const addBoundingBox = (payload) => {
  return {
    type: ADD_BOUNDING_BOX,
    payload,
  };
};

export const editBoundingBox = (payload) => {
  return {
    type: EDIT_BOUNDING_BOX,
    payload,
  };
};

export const deleteBoundingBox = (payload) => {
  return {
    type: DELETE_BOUNDING_BOX,
    payload,
  };
};
export const deleteLastBoundingBox = (payload) => {
  return {
    type: DELETE_LAST_BOUNDING_BOX,
    payload,
  };
};
