import PropTypes from 'prop-types';
import React from 'react';
import { TcAlertDialog, TcLabel } from 'common/components';
import { audioStep3 } from 'common/constants/global';

const DeleteAudioDialog = (props) => {
  const { displayDialog, setDisplayDialog, file, handleDeleteFile } = props;

  const AudioFileWarning = () => {
    if (file?.fileType !== audioStep3) return null;
    return (
      <TcLabel
        title="Warning! By deleting the audio file it will delete the .srt file as well"
        color="var(--color-red-300)"
      />
    );
  };

  const handleCancel = () => {
    setDisplayDialog(false);
  };
  return (
    <TcAlertDialog
      openDialog={displayDialog}
      onCloseDialog={handleCancel}
      dialogTitle={`Remove ${file?.fileType} File`}
      firstOption={'Cancel'}
      secondOption={'Yes, Delete'}
      onFirstOptionClick={handleCancel}
      onSecondOptionClick={handleDeleteFile}
      fullWidth={true}
      setOpenDialog={setDisplayDialog}
    >
      <AudioFileWarning />
      <div className="tw-break-all">
        {`Are you sure you want to delete this file: ${file?.filename} from ${file?.fileType} list files?`}
      </div>
    </TcAlertDialog>
  );
};

DeleteAudioDialog.propTypes = {
  displayDialog: PropTypes.bool,
  setDisplayDialog: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  file: PropTypes.object,
};

export default DeleteAudioDialog;
