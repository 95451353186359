import PropTypes from 'prop-types';
import React, { memo, useState, useCallback } from 'react';
import { searchIcon } from 'assets';
import TcInput from 'common/components/inputs/FormInput/WrappedFormInput';

const SearchBar = ({ handleSearch, initialValue, ...other }) => {
  const [value, setValue] = useState(initialValue);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(value);
    }
  };

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <TcInput
      full
      name="search"
      type="text"
      placeholder="Search"
      value={value}
      icon={{
        src: searchIcon,
        alt: 'search icon',
      }}
      onIconClick={() => handleSearch(value)}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      renderIcon
      {...other}
    />
  );
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func,
  initialValue: PropTypes.string,
};

export default memo(SearchBar);
