const CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD = 'CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD';
const CLEAR_PAYLOAD = 'CLEAR_PAYLOAD';
const ADD_BOUNDING_BOX = 'ADD_BOUNDING_BOX';
const EDIT_BOUNDING_BOX = 'EDIT_BOUNDING_BOX';
const DELETE_BOUNDING_BOX = 'DELETE_BOUNDING_BOX';
const DELETE_LAST_BOUNDING_BOX = 'DELETE_LAST_BOUNDING_BOX';

export {
  CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD,
  CLEAR_PAYLOAD,
  ADD_BOUNDING_BOX,
  EDIT_BOUNDING_BOX,
  DELETE_BOUNDING_BOX,
  DELETE_LAST_BOUNDING_BOX,
};
