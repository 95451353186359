import PropTypes from 'prop-types';
import React from 'react';

const TcGridSClass = `
  tw-overflow-auto
  tw-w-full
`;

const TcGrid = (props) => {
  const { children, className } = props;

  return <div className={TcGridSClass + className}>{children}</div>;
};

TcGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TcGrid.defaultProps = {
  className: '',
};

export default TcGrid;
