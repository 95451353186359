import { CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE } from 'store/actions';
import { INITIAL_STATE } from './defaultSubJobsTaggerListPaginationState';

const subJobsTaggerListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    default:
      return state;
  }
};

export default subJobsTaggerListPaginationReducer;
