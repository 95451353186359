import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class UserService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.USER_LIST_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getTaskList(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.USER_TASK_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getAllowedList(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.USER_ALLOWED_LIST_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDetail(parameters) {
    const { byUrl, payload } = parameters;

    try {
      if (byUrl) {
        const { data } = await this.HTTP.get(payload.url);
        return data;
      } else {
        const queryParameters = buildQueryParams(payload);
        const url = `${endpoints.USER_DETAIL_ADMIN}?${queryParameters}`;

        const { data } = await this.HTTP.get(url);
        return data;
      }
    } catch (error) {
      throw error.response.data;
    }
  }

  async getTaskDetail(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.USER_TASK_DETAIL_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setDetail(userId, payload) {
    try {
      const queryParameters = buildQueryParams(userId);
      const url = `${endpoints.SET_USER_DETAIL_ADMIN}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getProfile() {
    try {
      const url = `${endpoints.USER_PROFILE}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setProfile(payload) {
    try {
      const url = `${endpoints.USER_PROFILE}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setProfilePicture(payload) {
    try {
      const url = `${endpoints.USER_AVATAR}`;

      const formData = new FormData();
      formData.append('image', payload.image);

      const { data } = await this.HTTP.put(url, formData);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getWalletHistory(parameters) {
    const { payload } = parameters;
    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.USER_WALLET_HISTORY}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setUserReferral(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.SET_USER_REFERRAL;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserNationalityList() {
    try {
      const url = `${endpoints.USER_NATIONALITIES}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
