import { referralAPI } from 'configs';
import { handleAsync } from 'utils';

const getReferralRewards = async (parameters) => {
  const [res, error] = await handleAsync(referralAPI.getReferralRewards(parameters));
  if (error) throw error;
  return res;
};

const updateReferralRewards = async (parameters, payload) => {
  const [res, error] = await handleAsync(referralAPI.updateReferralRewards(parameters, payload));
  if (error) throw error;
  return res;
};

const getReferralRecords = async (parameters) => {
  const [res, error] = await handleAsync(referralAPI.getReferralRecords(parameters));
  if (error) throw error;
  return res;
};

const ReferralService = {
  getReferralRewards,
  updateReferralRewards,
  getReferralRecords,
};

export { ReferralService };
