import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%);
    font-size: 12px;
  }
`;

const TcTooltip = ({ title, children }) => {
  return <StyledTooltip title={title}>{children}</StyledTooltip>;
};

TcTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default TcTooltip;
