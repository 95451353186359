import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const StyledTableCell = styled(TableCell)({
  color: '#B8B8B8',
});

const styles = (theme) => ({
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      background: '#FEEDC7',
    },
    '&.MuiTableRow-root td:first-child': {
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
  },

  table: {
    '&.MuiTable-stickyHeader': {
      paddingLeft: 0,
    },
  },
});

const TcTableNoBorder = (props) => {
  const { classes, headers, rows, rowHoverEffect, onRowClick, selectedIndex, setSelectedIndex } = props;

  const isEmptyRow = (row) => {
    return Object.keys(row).length === 0 && row.constructor === Object;
  };

  const RenderHeaders = () => {
    const result = [];

    for (const header of headers) {
      if (isEmptyRow(header)) continue;
      result.push(
        <StyledTableCell key={header.key} style={{ width: `${header.width}%` }} align={header.align ?? 'left'}>
          {header.label}
        </StyledTableCell>,
      );
    }

    return result;
  };

  const RenderRows = ({ classes }) => {
    const result = [];
    for (const [index, row] of rows.entries()) {
      if (isEmptyRow(row)) continue;
      if (onRowClick !== undefined) {
        // to account for when onRowClick is not specified
        result.push(
          <TableRow
            key={index}
            hover={rowHoverEffect}
            onClick={() => {
              setSelectedIndex(index);
              onRowClick(index);
            }}
            selected={selectedIndex === index}
            classes={{ selected: classes.selected }}
            className={classes.tableRow}
          >
            <RenderCells row={row} />
          </TableRow>,
        );
      } else {
        result.push(
          <TableRow key={index} hover={rowHoverEffect}>
            <RenderCells row={row} />
          </TableRow>,
        );
      }
    }

    return result;
  };

  const RenderCells = ({ row }) => {
    const result = [];

    for (const [index, cell] of row.entries()) {
      if (isEmptyRow(cell)) continue;
      result.push(
        <TableCell
          style={{ borderBottom: 'none', overflowWrap: 'break-word', maxWidth: cell.width ?? 'auto' }}
          key={index}
          align={cell.align ?? 'left'}
        >
          {cell.label}
        </TableCell>,
      );
    }

    return result;
  };

  return (
    <div style={{ outline: 'none' }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <RenderHeaders />
          </TableRow>
        </TableHead>

        <TableBody>
          <RenderRows classes={classes} />
        </TableBody>
      </Table>
    </div>
  );
};

TcTableNoBorder.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowHoverEffect: PropTypes.bool,
  onRowClick: PropTypes.func,
};

TcTableNoBorder.defaultProps = {
  rowHoverEffect: false,
};

export default withStyles(styles)(TcTableNoBorder);
