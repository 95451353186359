import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState, useRef } from 'react';

const TcPopover = ({ trigger, children }) => {
  const triggerReference = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClickTrigger = useCallback(() => {
    setOpen((currentOpen) => !currentOpen);
  }, [setOpen]);

  const handleClosePopover = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <div ref={triggerReference} onClick={handleClickTrigger}>
        {trigger}
      </div>
      <Popover
        open={open}
        anchorEl={triggerReference.current}
        onClose={handleClosePopover}
        onClick={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

TcPopover.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node,
};

export default memo(TcPopover);
