import { logAPI } from 'configs';
import { handleAsync } from 'utils';

const getList = async (parameters) => {
  const [res, error] = await handleAsync(logAPI.getList(parameters));
  if (error) throw error;
  return res;
};

const createLog = async (parameters, body) => {
  const [res, error] = await handleAsync(logAPI.createLog(parameters, body));
  if (error) throw error;
  return res;
};

const updateLog = async (parameters, body) => {
  const [res, error] = await handleAsync(logAPI.updateLog(parameters, body));
  if (error) throw error;
  return res;
};

const archiveLog = async (parameters) => {
  const [res, error] = await handleAsync(logAPI.archiveLog(parameters));
  if (error) throw error;
  return res;
};

const uploadLogImage = async (parameters, body, config) => {
  const [res, error] = await handleAsync(logAPI.uploadLogImage(parameters, body, config));
  if (error) throw error;
  return res;
};

const getQueryOptions = async (parameters) => {
  const [res, error] = await handleAsync(logAPI.getQueryOptions(parameters));
  if (error) throw error;
  return res;
};

const getLogDetailAdmin = async (parameters) => {
  const [res, error] = await handleAsync(logAPI.getLogDetailAdmin(parameters));
  if (error) throw error;
  return res;
};

const getLogDetailClient = async (parameters) => {
  const [res, error] = await handleAsync(logAPI.getLogDetailClient(parameters));
  if (error) throw error;
  return res;
};

const LogService = {
  getList,
  createLog,
  updateLog,
  archiveLog,
  uploadLogImage,
  getQueryOptions,
  getLogDetailAdmin,
  getLogDetailClient,
};

export { LogService };
