export const PRE_TASK_SCREENING = '/v1/admin/pre-task-screenings';
export const PRE_TASK_SCREENING_FILES = '/v1/admin/pre-task-screenings/files';
export const PRE_TASK_SCREENING_QUERY_OPTIONS = '/v1/admin/pre-task-screenings/query-options';
export const PRE_TASK_SCREENING_DETAILS = '/v1/admin/pre-task-screenings';
export const PRE_TASK_SCREENING_ITEMS = '/v1/admin/pre-task-screenings/items';
export const PRE_TASK_SCREENING_COPY_ITEMS = '/v1/admin/pre-task-screenings/copy-items';
export const PRE_TASK_SCREENING_DISABLE = '/v1/admin/pre-task-screenings/disable';
export const PRE_TASK_SCREENING_BOUNDING_BOX_CONFIG =
  '/v1/admin/pre-task-screenings/items/image-bounding-box/set-configs';
export const PRE_TASK_SCREENING_BOUNDING_POLYGON_CONFIG =
  '/v1/admin/pre-task-screenings/items/image-bounding-polygon/set-configs';
export const PRE_TASK_SCREENING_AUDIO_TRANSCRIPTION_CLEANSING_CONFIG =
  '/v1/admin/pre-task-screenings/items/audio-transcription-cleansing/set-configs';
