import { jobAPI } from 'configs';
import { handleAsync } from 'utils';

const getList = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getList(parameters));
  if (error) throw error;
  return res;
};

const getDetail = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getDetail(parameters));
  if (error) throw error;
  return res;
};

const getQueryOptions = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getQueryOptions(parameters));
  if (error) throw error;
  return res;
};

const getDownloadFormatOptions = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getDownloadFormatOptions(parameters));
  if (error) throw error;
  return res;
};

const getDownloadDataFormatOptions = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getDownloadDataFormatOptions(parameters));
  if (error) throw error;
  return res;
};

const getListTagger = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getListTagger(parameters));
  if (error) throw error;
  return res;
};

const setTagger = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.setTagger(parameters));
  if (error) throw error;
  return res;
};

const download = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.download(parameters));
  if (error) throw error;
  return res;
};

const downloadWithUsername = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.downloadWithUsername(parameters));
  if (error) throw error;
  return res;
};

const downloadUrl = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.downloadUrl(parameters));
  if (error) throw error;
  return res;
};

const prepareDownload = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.prepareDownload(parameters));
  if (error) throw error;
  return res;
};

const prepareDownloadState = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.prepareDownloadState(parameters));
  if (error) throw error;
  return res;
};

const getListType = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getListType(parameters));
  if (error) throw error;
  return res;
};

const create = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.create(parameters));
  if (error) throw error;
  return res;
};

const createQC = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.createQC(parameters));
  if (error) throw error;
  return res;
};

const getSampleImages = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getSampleImages(parameters));
  if (error) throw error;
  return res;
};

const deleteSampleImages = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.deleteSampleImages(parameters));
  if (error) throw error;
  return res;
};

const updateSampleImageOrder = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.updateSampleImageOrder(parameters));
  if (error) throw error;
  return res;
};

const updateDetails = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.updateDetails(parameters));
  if (error) throw error;
  return res;
};

const setContentFlag = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.setContentFlag(parameters));
  if (error) throw error;
  return res;
};

const copyItems = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.copyItems(parameters));
  if (error) throw error;
  return res;
};

const submitJobBoard = async (parameters, payload) => {
  const [res, error] = await handleAsync(jobAPI.jobBoardSubmit(parameters, payload));
  if (error) throw error;
  return res;
};

const getJobBoardDetails = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getJobBoardDetails(parameters));
  if (error) throw error;
  return res;
};

const addJobBoardItems = async (payload) => {
  const [res, error] = await handleAsync(jobAPI.addJobBoardItems(payload));
  if (error) throw error;
  return res;
};

const removeJobBoardItems = async (payload) => {
  const [res, error] = await handleAsync(jobAPI.removeJobBoardItems(payload));
  if (error) throw error;
  return res;
};

const updateJobBoardParameters = async (parameters, payload) => {
  const [res, error] = await handleAsync(jobAPI.updateJobBoardParameters(parameters, payload));
  if (error) throw error;
  return res;
};

const setJobItemsAdminReview = async (parameters, payload) => {
  const [res, error] = await handleAsync(jobAPI.setJobItemsAdminReview(parameters, payload));
  if (error) throw error;
  return res;
};

const getJobTaskItems = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getJobTaskItems(parameters));
  if (error) throw error;
  return res;
};

const getJobFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getJobFiles(parameters));
  if (error) throw error;
  return res;
};

const deleteJobFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.deleteJobFiles(parameters));
  if (error) throw error;
  return res;
};

const processAudioFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.processAudioFiles(parameters));
  if (error) throw error;
  return res;
};

const processVideoFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.processVideoFiles(parameters));
  if (error) throw error;
  return res;
};

const processInputAgnosticFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.processInputAgnosticFiles(parameters));
  if (error) throw error;
  return res;
};

const getInputAgnosticFilesProcessStatus = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getInputAgnosticFilesProcessStatus(parameters));
  if (error) throw error;
  return res;
};

const processInputAgnosticEmptyFiles = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.createEmptyFiles(parameters));
  if (error) throw error;
  return res;
};

const getRejectionReasons = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getRejectionReasons(parameters));
  if (error) throw error;
  return res;
};

const setRejectionReasons = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.setRejectionReasons(parameters));
  if (error) throw error;
  return res;
};

const disableRejectionReasons = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.disableRejectionReasons(parameters));
  if (error) throw error;
  return res;
};

const archiveJob = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.archiveJob(parameters));
  if (error) throw error;
  return res;
};

const unarchiveJob = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.unarchiveJob(parameters));
  if (error) throw error;
  return res;
};

const getCurrentTagged = async (parameters) => {
  const [res, error] = await handleAsync(jobAPI.getCurrentTagged(parameters));
  if (error) throw error;
  return res;
};

const JobService = {
  getList,
  getDetail,
  getQueryOptions,
  getDownloadFormatOptions,
  getDownloadDataFormatOptions,
  getListTagger,
  getJobTaskItems,
  setJobItemsAdminReview,
  setTagger,
  download,
  downloadWithUsername,
  downloadUrl,
  prepareDownload,
  prepareDownloadState,
  getListType,
  create,
  createQC,
  getSampleImages,
  deleteSampleImages,
  updateSampleImageOrder,
  setContentFlag,
  copyItems,
  submitJobBoard,
  getJobBoardDetails,
  addJobBoardItems,
  removeJobBoardItems,
  updateJobBoardParameters,
  getRejectionReasons,
  setRejectionReasons,
  disableRejectionReasons,
  archiveJob,
  unarchiveJob,
  updateDetails,
  getJobFiles,
  deleteJobFiles,
  processAudioFiles,
  processVideoFiles,
  processInputAgnosticFiles,
  getInputAgnosticFilesProcessStatus,
  processInputAgnosticEmptyFiles,
  getCurrentTagged,
};

export { JobService };
