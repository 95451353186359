import { CHANGE_JOB_DETAIL_COLLAPSE } from '../../actions';

const INITIAL_STATE = {
  jobDetailCollapsed: true,
};

const collapsibleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_JOB_DETAIL_COLLAPSE:
      return { ...state, jobDetailCollapsed: action.payload };
    default:
      return state;
  }
};

export default collapsibleReducer;
