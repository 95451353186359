import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CardImageContainer = styled.div`
  padding: 0.75rem;
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background: ${({ active }) => (active ? '#CB7D0026' : '#ffffff')};
  /* border: 1px solid #c2b8a4; */
  border-radius: 6px;
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  & img {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
`;

const AudioIconContainer = styled.div`
  /* width: 50px;
  height: 50px; */
  & img {
    width: 100%;
    object-fit: cover;
    /* border-radius: 6px; */
  }
`;

const CardFileName = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #6c624f;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const ConfigAndStatusWrapper = styled.div`
  width: 150px;
`;

const ToConfigureStatus = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #cb0000;
`;

const ConfiguredStatus = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #16a43e;
`;

const TcCardImage = (props) => {
  const { imgSource, filename, active, className, isConfigured, jobMethod } = props;
  const configText = isConfigured ? (
    <ConfiguredStatus>Configured</ConfiguredStatus>
  ) : (
    <ToConfigureStatus>To Configure</ToConfigureStatus>
  );
  return (
    <CardImageContainer active={active} className={className} {...props}>
      {jobMethod == 'audio_transcription_cleansing' ? (
        <AudioIconContainer>
          <img alt="card-image" className="tw-h-full" src={imgSource} />
        </AudioIconContainer>
      ) : (
        <ImageContainer>
          <img alt="card-image" className="tw-h-full" src={imgSource} />
        </ImageContainer>
      )}
      <ConfigAndStatusWrapper>
        <CardFileName>{filename}</CardFileName>
        {/* <TcLabel title={title} /> */}
        {/* <div>Image Title</div> */}
        {configText}
      </ConfigAndStatusWrapper>
    </CardImageContainer>
  );
};

TcCardImage.propTypes = {
  imgSource: PropTypes.string,
  filename: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  isConfigured: PropTypes.bool,
  jobMethod: PropTypes.string,
};

TcCardImage.defaultTypes = {
  active: false,
};

export default TcCardImage;
