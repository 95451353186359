import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { QcTaggingStatus } from 'pages/Jobs/TaggingReview/constants';

const TcTaggingStatusLabelStyled = styled.div`
  width: 120px;
`;

const TcTaggingStatusLabelClass = `
  tw-text-center
  tw-capitalize
  tw-px-4
  tw-py-1
  tw-rounded-lg
  tw-border
`;

const TcTaggingRejectedClass = `
  tw-bg-red-100
  tw-text-red-500
  tw-border-red-500
`;

const TcTaggingApprovedClass = `
  tw-bg-green-100
  tw-text-green-700
  tw-border-green-700
`;

const TcTaggingStatusLabel = (props) => {
  const { type, className } = props;

  const TypeClass = type === QcTaggingStatus.AcceptTagging ? TcTaggingApprovedClass : TcTaggingRejectedClass;

  return (
    <TcTaggingStatusLabelStyled className={TcTaggingStatusLabelClass + TypeClass + className} type={type}>
      {type === QcTaggingStatus.AcceptTagging ? 'ACCEPTED' : 'REJECTED'}
    </TcTaggingStatusLabelStyled>
  );
};

TcTaggingStatusLabel.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TcTaggingStatusLabel.defaultProps = {
  className: '',
};

export default TcTaggingStatusLabel;
