import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class InviteCodesService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getInvitations(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.INVITE_CODES_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteInvitation(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.INVITE_CODES_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.delete(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deactivateInvitation(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.INVITE_CODE_DEACTIVATE_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.post(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createTaggerReferralInvitation(parameters) {
    const { payloadBody } = parameters;

    try {
      const url = `${endpoints.INVITE_CODE_TAGGER_REFERRAL_CREATE_ADMIN}`;
      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }

  async createNonTaggerReferralInvitation(parameters) {
    const { payloadBody } = parameters;

    try {
      const url = `${endpoints.INVITE_CODE_NON_TAGGER_REFERRAL_CREATE_ADMIN}`;
      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      console.log(error);
      throw error?.response?.data;
    }
  }

  async checkCodeAvailable(parameters) {
    const { payloadBody } = parameters;

    try {
      const url = `${endpoints.INVITE_CODE_CHECK_CODE_ADMIN}`;
      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      console.log(error);
      throw error?.response?.data;
    }
  }
}
