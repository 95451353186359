import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import { TcInput, TcLabel } from 'common/components';

const TcFormFieldClass = `
  tw-mb-8
`;

const TcFormFieldErrorClass = `
  tw-mt-1
  tw-text-xs
  tw-text-red-300
  tw-capitalize
`;

const TcFormField = memo(
  forwardRef((properties, reference) => {
    const {
      defaultValue,
      title,
      labelColor,
      error,
      icon,
      renderIcon,
      name,
      type,
      placeholder,
      autoComplete,
      full,
      forwardRef,
      onChange,
    } = properties;

    const RenderTitle = () => {
      if (!title) return null;

      return <TcLabel title={title} error={error} color={labelColor} />;
    };

    const RenderError = () => {
      if (!error) return null;

      return <div className={TcFormFieldErrorClass}>{error}</div>;
    };

    return (
      <div className={TcFormFieldClass}>
        <RenderTitle />
        <TcInput
          ref={reference || forwardRef}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          full={full}
          error={error}
          icon={icon}
          renderIcon={renderIcon}
          onChange={onChange}
          defaultValue={defaultValue}
        />
        <RenderError />
      </div>
    );
  }),
);

TcFormField.propTypes = {
  title: PropTypes.string,
  labelColor: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.object,
  renderIcon: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  full: PropTypes.bool,
  forwardRef: PropTypes.any,
};

TcFormField.defaultProps = {
  value: '',
  title: '',
  labelColor: 'var(--color-black)',
  error: '',
  icon: {},
  renderIcon: false,
  placeholder: '',
  autoComplete: '',
  full: false,
  forwardRef: '',
};

export default TcFormField;
