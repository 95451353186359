import { allReferrals, pendingReferrals, completedReferrals } from 'common/constants/global';

const defaultReferralRecordListPageObject = {
  [allReferrals]: 1,
  [pendingReferrals]: 1,
  [completedReferrals]: 1,
};
const defaultReferralRecordListPerPage = '10';
const defaultReferralRecordListTab = allReferrals;
const defaultReferralRecordListSearch = '';
const defaultReferralRecordListIsCampaignsOnly = false;

export const INITIAL_STATE = {
  pageObject: defaultReferralRecordListPageObject,
  perPage: defaultReferralRecordListPerPage,
  tab: defaultReferralRecordListTab,
  search: defaultReferralRecordListSearch,
  isCampaignsOnly: defaultReferralRecordListIsCampaignsOnly,
};
