import { Auth, Forgot } from 'pages';

const PublicRoutes = [
  {
    name: '',
    exact: true,
    path: '/',
  },
  {
    name: 'Auth',
    component: Auth,
    exact: true,
    path: '/auth',
  },
  {
    name: 'Forgot',
    component: Forgot,
    exact: true,
    path: '/forgot',
  },
];

export default PublicRoutes;
