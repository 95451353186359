import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const TcSkeletonBarStyled = styled.div.attrs({
  className: 'tw-relative tw-overflow-hidden tw-bg-gray-300 tw-rounded',
})`
  width: ${({ barWidth }) => barWidth};
  height: ${({ barHeight }) => barHeight};
  ${({ barMode, barWidth }) => {
    switch (barMode) {
      case 'circle':
        return css`
          border-radius: 50%;
          height: ${barWidth};
        `;
      case 'sharp':
        return css`
          border-radius: 0px;
        `;
    }
  }};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
    animation: shimmer 2s infinite;
  }
`;

const TcSkeletonBarLoading = ({ active, children, width, height, className, mode }) => {
  return (
    <>
      {active ? (
        // Naming with merely width or height makes it rendered to the DOM since they
        // are valid element attributes
        <TcSkeletonBarStyled className={className} barWidth={width} barHeight={height} barMode={mode} />
      ) : (
        children
      )}
    </>
  );
};

TcSkeletonBarLoading.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  mode: PropTypes.string,
};

TcSkeletonBarLoading.defaultProps = {
  active: true,
  width: '100%',
  height: '2rem',
  className: '',
  mode: '',
};

export default TcSkeletonBarLoading;
