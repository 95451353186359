import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TcLayout, TcContainer, TcSidebar } from 'common/components';
import { changeSidebarType } from 'store/actions';

const TcMainTemplate = (props) => {
  const { auth, children, bgColor, profileUpdated, changeSidebarType, showSidebar = false } = props;
  const { pathname } = useLocation();
  const isPublic = pathname === '/auth' ? true : false;

  useEffect(() => {
    let subscribeChangeSidebar = true;

    if (subscribeChangeSidebar) {
      if (pathname.search('qc-job-create') > -1) {
        changeSidebarType('QC_JOB_CREATE');
      } else if (pathname.search('job-create') > -1) {
        changeSidebarType('JOB_CREATE');
      } else {
        if (auth.user.type === 'admin') changeSidebarType('GENERAL_ADMIN');
        else changeSidebarType('GENERAL');
      }
    }

    return () => {
      subscribeChangeSidebar = false;
    };
  }, [auth, pathname]);

  const RenderSideBar = () => {
    return !isPublic ? <TcSidebar profileUpdated={profileUpdated} /> : null;
  };

  return (
    <TcLayout bgColor={bgColor}>
      {showSidebar && <RenderSideBar />}
      <TcContainer isPublic={isPublic || !showSidebar}>{children}</TcContainer>
    </TcLayout>
  );
};

const mapStateToProperties = (state) => {
  return {
    auth: state.auth,
  };
};

TcMainTemplate.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  profileUpdated: PropTypes.bool,
  changeSidebarType: PropTypes.func,
  showSidebar: PropTypes.bool,
};

export default connect(mapStateToProperties, { changeSidebarType })(TcMainTemplate);
