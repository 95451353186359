import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class UserTaskService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getUserTaskList(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.USER_TASK_LIST;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserTaskItems(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.USER_TASK_ITEMS}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async unassignTaggers(parameter, payload) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.USER_TASK_UNASSIGN;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async unassignAllTaggers(parameter) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.USER_TASK_UNASSIGN_EXPIRED;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async removeJobBoardTask(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.USER_TASK_JOB_BOARD_REMOVE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
