import { CHANGE_WITHOUT_TASK_COMPLETION } from 'store/actions/WalletHistory/types';

const INITIAL_STATE = {
  withoutTaskCompletion: true,
};

const walletHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_WITHOUT_TASK_COMPLETION:
      return { ...state, withoutTaskCompletion: action.payload };
    default:
      return state;
  }
};

export default walletHistoryReducer;
