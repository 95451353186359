import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledWarning = styled.div`
  background: rgba(203, 125, 0, 0.15);
  border-left: 5px solid var(--color-orange-700);
  color: var(--color-orange-700);
  padding: 1rem;

  & div {
    color: var(--color-orange-700);
  }
`;

const TcWarning = (props) => {
  const { message } = props;

  return (
    <StyledWarning>
      <div className="tw-mb-2">Warning!</div>
      {message}
    </StyledWarning>
  );
};

TcWarning.propTypes = {
  message: PropTypes.string,
};

export default TcWarning;
