import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router';

const PublicRoute = (props) => {
  const { isLogin, user } = props;
  const { pathname } = useLocation();
  if (pathname === '/') {
    return <Redirect to="/auth" />;
  } else if (isLogin) {
    const redirectAddress = user.type === 'admin' ? '/admin/job-list' : '/client/job-list';
    return <Redirect to={redirectAddress} />;
  } else {
    return <Route {...props} />;
  }
};

PublicRoute.propTypes = {
  isLogin: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProperties = (state) => {
  return {
    isLogin: state.auth.isLogin,
    user: state.auth.user,
  };
};

export default connect(mapStateToProperties)(PublicRoute);
