const initialState = {
  polygon: [],
  polygonActive: false,
  dragablePoints: false,
  dragableImage: false,
  polygonCount: 0,
  isFinished: false,
  originalPolygon: [],
  selectedPolygon: false,
  polygonThreshold: [],
  threshold: 0,
  boundingBoxActive: false,
};
export default initialState;
