import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class PreTaskScreeningAPI {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async create(payload) {
    const { body } = payload;
    try {
      const url = endpoints.PRE_TASK_SCREENING;
      const { data } = await this.HTTP.post(url, body);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async disable(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_DISABLE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadFiles(payload) {
    const { parameters, body, config } = payload;

    try {
      const queryParameters = parameters ? buildQueryParams(parameters) : '';
      const endpoint = endpoints.PRE_TASK_SCREENING_FILES;
      const url = `${endpoint}?${queryParameters}`;
      const formData = new FormData();
      for (const file of body) {
        formData.append('files[]', file);
      }

      const { data } = await this.HTTP.put(url, formData, config);

      return data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    }
  }

  async getQueryOptions() {
    try {
      const url = endpoints.PRE_TASK_SCREENING_QUERY_OPTIONS;
      const { data } = await this.HTTP.get(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDetails(payload) {
    const { parameters } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_DETAILS;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getItems(payload) {
    const { parameters } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_ITEMS;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async editPreTask(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, body);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteItems(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_ITEMS;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.delete(url, body);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setBoundingBoxTags(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_BOUNDING_BOX_CONFIG;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setBoundingPolygonTags(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_BOUNDING_POLYGON_CONFIG;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setAudioTranscriptionCleansing(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_AUDIO_TRANSCRIPTION_CLEANSING_CONFIG;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async copy(payload) {
    const { parameters, body } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.PRE_TASK_SCREENING_COPY_ITEMS;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
