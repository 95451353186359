import { allJobs, completed, ongoing, partial, pendingResolve, archived } from 'common/constants/global';

const defaultJobListPageObject = {
  [allJobs]: 1,
  [ongoing]: 1,
  [completed]: 1,
  [partial]: 1,
  [archived]: 1,
  [pendingResolve]: 1,
};
const defaultJobListTab = allJobs;
const defaultJobListPerPage = '10';
const defaultJobListFilter = [];
const defaultJobListSearch = {
  filter_type: 'job_detail_name',
  filter_value: '',
};

export const INITIAL_STATE = {
  pageObject: defaultJobListPageObject,
  tab: defaultJobListTab,
  perPage: defaultJobListPerPage,
  filter: defaultJobListFilter,
  search: defaultJobListSearch,
};
