export const PENDING_TAGGERS = '/v1/admin/coins/preview';
export const RESOLVED_TAGGERS = '/v1/admin/coins/resolved';
export const APPROVE = '/v1/admin/coins/approve';
export const REJECT = '/v1/admin/coins/reject';
export const CALCULATE = '/v1/admin/coins/preview/tier';
export const QUERY_OPTIONS = '/v1/users/coins/query-options';
export const INCREASE = '/v1/users/coins/increase';
export const INCREASE_ALL = '/v1/users/coins/increase/all';
export const DECREASE = '/v1/users/coins/decrease';
export const DECREASE_ALL = '/v1/users/coins/decrease/all';
