import { userAPI } from 'configs';
import { handleAsync } from 'utils';

const getList = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getList(parameters));
  if (error) throw error;
  return res;
};

const getTaskList = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getTaskList(parameters));
  if (error) throw error;
  return res;
};

const getAllowedList = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getAllowedList(parameters));
  if (error) throw error;
  return res;
};

const getDetail = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getDetail(parameters));
  if (error) throw error;
  return res;
};

const getTaskDetail = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getTaskDetail(parameters));
  if (error) throw error;
  return res;
};

const setDetail = async (userId, payload) => {
  const [res, error] = await handleAsync(userAPI.setDetail(userId, payload));
  if (error) throw error;
  return res;
};

const getProfile = async () => {
  const [res, error] = await handleAsync(userAPI.getProfile());
  if (error) throw error;
  return res;
};

const setProfile = async (payload) => {
  const [res, error] = await handleAsync(userAPI.setProfile(payload));
  if (error) throw error;
  return res;
};

const setProfilePicture = async (payload) => {
  const [res, error] = await handleAsync(userAPI.setProfilePicture(payload));
  if (error) throw error;
  return res;
};
const getUserNationalityList = async () => {
  const [res, error] = await handleAsync(userAPI.getUserNationalityList());
  if (error) throw error;
  return res;
};

const getWalletHistory = async (parameters) => {
  const [res, error] = await handleAsync(userAPI.getWalletHistory(parameters));
  if (error) throw error;
  return res;
};

const setUserReferral = async (payload) => {
  const [res, error] = await handleAsync(userAPI.setUserReferral(payload));
  if (error) throw error;
  return res;
};

const UserService = {
  getList,
  getTaskList,
  getAllowedList,
  getDetail,
  getTaskDetail,
  setDetail,
  getProfile,
  setProfile,
  setProfilePicture,
  getUserNationalityList,
  getWalletHistory,
  setUserReferral,
};

export { UserService };
