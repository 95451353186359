import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const MAX_SPAN = 24;

const TcRowStyled = styled.div`
  ${({ span = MAX_SPAN } = {}) => {
    const calculatedSpan = MAX_SPAN / span;

    return css`
      width: calc(100% / ${calculatedSpan});
    `;
  }}
`;

const TcRowClass = `
  tw-float-left
`;

const TcRow = (props) => {
  const { span, children, className } = props;

  return (
    <TcRowStyled className={TcRowClass + className} span={span}>
      {children}
    </TcRowStyled>
  );
};

TcRow.propTypes = {
  span: PropTypes.number.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

TcRow.defaultProps = {
  children: '',
  className: '',
};

export default TcRow;
