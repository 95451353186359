import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcLinkStyled = styled.a`
  color: ${(props) => props.color};
`;

const TcLinkClass = ({ bold, disabled }) => `
  ${disabled ? '' : 'tw-cursor-pointer'}
  tw-mb-2
  ${bold ? 'tw-font-bold' : ''}
`;

const TcLink = (props) => {
  const { title, location, parameter, color, bold, className, disabled, newTab } = props;

  const goTo = ({ event, location, parameter }) => {
    if (!disabled) {
      event.stopPropagation();
      if (newTab) {
        window.open(location, '_blank');
      } else {
        window.directTo.push({
          pathname: location,
          state: parameter,
        });
      }
    }
  };

  return (
    <TcLinkStyled
      className={TcLinkClass({ bold, disabled }) + className}
      onClick={(event) => goTo({ event, location, parameter })}
      color={color}
    >
      {title}
    </TcLinkStyled>
  );
};

TcLink.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  parameter: PropTypes.object,
  color: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  newTab: PropTypes.bool,
};

TcLink.defaultProps = {
  location: '#',
  parameter: {},
  color: 'var(--color-blue-200)',
  bold: false,
  className: '',
  disabled: false,
};

export default TcLink;
