import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { TcButton, TcGrid, TcLabel } from 'common/components';

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0 1rem 0;
`;

const TcAlertDialogAlt = (props) => {
  const {
    childrenLeft,
    childrenRight,
    openDialog,
    onCloseDialog,
    dialogTitle,
    firstOption,
    secondOption,
    thirdOption,
    fourthOption,
    onFirstOptionClick,
    onSecondOptionClick,
    onThirdOptionClick,
    onFourthOptionClick,
    isSubmitting,
    fullWidth,
    footer,
    maxWidth = 'lg',
    height = '60vh',
    headerComponentList,
    renderContentHeader: ContentHeader,
    renderHeaderButtons: ActionButton,
  } = props;

  const RenderActions = ({ footer }) => {
    return (
      <DialogActions>
        <span className={` ${!footer ? 'tw-mr-auto' : ''} tw-p-4`}>{footer}</span>

        {firstOption ? (
          <TcButton
            onClick={onFirstOptionClick}
            type="plain"
            label={firstOption}
            className="tw-flex tw-text-orange-400 tw-border-2 tw-border-orange-400 tw-rounded-lg tw-w-200 tw-m-3"
          />
        ) : null}
        {secondOption ? (
          <TcButton
            onClick={onSecondOptionClick}
            loading={isSubmitting}
            type="submit"
            label={secondOption}
            className="tw-rounded-lg"
          />
        ) : null}
        {thirdOption ? (
          <TcButton
            onClick={onThirdOptionClick}
            loading={isSubmitting}
            type="submit"
            label={thirdOption}
            className="tw-rounded-lg"
          />
        ) : null}
        {fourthOption ? (
          <TcButton
            onClick={onFourthOptionClick}
            loading={isSubmitting}
            type="submit"
            label={fourthOption}
            className="tw-rounded-lg"
          />
        ) : null}
      </DialogActions>
    );
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog} fullWidth={fullWidth} maxWidth={maxWidth} scroll="body">
      <DialogTitle style={{ paddingBottom: 0 }}>
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2">
          <TcGrid>
            <TcLabel
              className="tw-text-2xl tw-font-extrabold"
              color="var(--color-brown-500)"
              title={dialogTitle}
              noMargin
            />
            {headerComponentList && (
              <SubHeaderContainer>
                {headerComponentList.map((item, index) => (
                  <div key={index} className="tw-flex tw-flex-row tw-pr-10 tw-items-center">
                    <TcLabel
                      className={`${item.titleClassName} tw-pr-2 tw-font-thin tw-text-lg`}
                      color={item.titleColor || 'var(--color-brown-300)'}
                      title={item.title}
                      noMargin
                    />
                    <TcLabel
                      className={`${item.className} tw-text-lg`}
                      color={item.color || 'var(--color-brown-500)'}
                      title={item.content}
                      noMargin
                      capitalized={false}
                    />
                  </div>
                ))}
              </SubHeaderContainer>
            )}
          </TcGrid>
          {ActionButton && <ActionButton />}
        </div>
      </DialogTitle>
      {ContentHeader && (
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ContentHeader />
        </DialogContent>
      )}
      {childrenLeft ? (
        <div className="tw-grid tw-grid-cols-3">
          <DialogContent style={{ height: height, overflowX: 'auto', overflowY: 'auto' }} className="tw-border-r">
            {childrenLeft()}
          </DialogContent>
          <DialogContent
            style={{ height: `${height}`, overflowX: 'auto', overflowY: 'auto' }}
            className="tw-col-span-2"
          >
            {childrenRight()}
          </DialogContent>
        </div>
      ) : (
        <DialogContent style={{ height: `${height}`, overflowX: 'auto', overflowY: 'auto' }}>
          {childrenRight()}
        </DialogContent>
      )}
      <RenderActions footer={footer} />
    </Dialog>
  );
};

TcAlertDialogAlt.propTypes = {
  childrenLeft: PropTypes.any,
  childrenRight: PropTypes.any,
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  firstOption: PropTypes.string,
  secondOption: PropTypes.string,
  thirdOption: PropTypes.string,
  fourthOption: PropTypes.string,
  onFirstOptionClick: PropTypes.func,
  onSecondOptionClick: PropTypes.func,
  onThirdOptionClick: PropTypes.func,
  onFourthOptionClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  footer: PropTypes.object,
  isSubmitting: PropTypes.bool,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  renderContentHeader: PropTypes.any,
  renderHeaderButtons: PropTypes.any,
  headerComponentList: PropTypes.array,
};

export default TcAlertDialogAlt;
