import * as endpoints from './endpoints';

export default class AuthService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async login(payload) {
    try {
      const { data } = await this.HTTP.post(endpoints.LOGIN, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async register(payload) {
    try {
      const { data } = await this.HTTP.post(endpoints.REGISTER, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
