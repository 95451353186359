import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { TcIcon } from 'common/components';
import FormInput from './FormInput';

const TcInputWrapperStyled = styled.div`
  position: relative;
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcInputWrapperClass = `
  tw-inline-block
  tw-relative
`;

const StyledIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const TcInput = (props) => {
  const { full, icon, renderIcon, onIconClick, onKeyDown } = props;

  const RenderIcon = () => {
    if (!renderIcon) return null;

    return (
      <StyledIconContainer>
        <TcIcon onClick={onIconClick} src={icon?.src} alt={icon?.alt} />
      </StyledIconContainer>
    );
  };

  return (
    <TcInputWrapperStyled className={TcInputWrapperClass} full={full} onKeyDown={onKeyDown}>
      <FormInput {...props} />
      <RenderIcon />
    </TcInputWrapperStyled>
  );
};

TcInput.propTypes = {
  full: PropTypes.bool,
  icon: PropTypes.object,
  renderIcon: PropTypes.any,
  textArea: PropTypes.bool,
  onIconClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default TcInput;
