import PropTypes from 'prop-types';
import React, { forwardRef, memo, Fragment, Children } from 'react';
import styled, { css } from 'styled-components';
import { TcIcon, TcLoadingCircle, TcTooltip } from 'common/components';

const TcButtonWrapperStyled = styled.div`
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcButtonWrapperClass = `
  tw-inline-block
  tw-relative
`;

const handleTypeStyle = ({ designType }) => {
  switch (designType) {
    case 'plain':
      return css`
        background-color: var(--color-transparent);
      `;
    case 'contrast-icon-only':
      return css`
        background-color: var(--color-transparent);
      `;
    case 'contrast':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-orange-400);
        border-radius: 0.5rem;
      `;
    case 'text':
      return css`
        background-color: var(--color-transparent);
      `;
    case 'filled':
      return css`
        background-color: var(--color-gray-200);
        border-radius: 2rem;
        margin-right: 2.5rem;
      `;
    case 'info':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-blue-400);
        border-radius: 0.5rem;
      `;
    case 'error-filled':
      return css`
        background-color: var(--color-red-400);
        border-radius: 0.5rem;
      `;
    case 'approve':
      return css`
        background-color: var(--color-green-600);
      `;
    case 'reject':
      return css`
        background-color: var(--color-red-400);
      `;
    case 'error':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-red-400);
        border-radius: 0.5rem;
      `;
    case 'info-filled':
      return css`
        background-color: var(--color-blue-400);
        border-radius: 0.5rem;
      `;
    case 'enable':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-green-400);
        border-radius: 0.5rem;
      `;
    case 'green-outline':
      return css`
        color: var(--color-green-600);
        background-color: var(--color-white);
        border: 1px solid var(--color-green-600);
      `;
    case 'red-outline':
      return css`
        color: var(--color-red-400);
        background-color: var(--color-white);
        border: 1px solid var(--color-red-400);
      `;
    default:
      return css`
        background-color: var(--color-orange-400);
      `;
  }
};

const TcButtonStyled = styled.button`
  ${handleTypeStyle}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcButtonSuffixStyled = styled.span`
  height: 25px;
  width: 25px;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  border-radius: 50%;
  left: -15px;
  top: -5px;
  position: absolute;
  transform: scale(0.7);
`;

const IconCard = styled.img.attrs({
  height: 40,
  width: 40,
  className: 'hexagon',
})`
  &.hexagon {
    clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
  }
`;

const getTcButtonTextColorByType = (designType) => {
  switch (designType) {
    case 'plain':
      return 'tw-text-gray-1000';
    case 'contrast':
      return 'tw-text-orange-400';
    case 'text':
      return 'tw-text-orange-400';
    case 'filled':
      return 'tw-text-black';
    case 'info':
      return 'tw-text-blue-400';
    case 'error':
      return 'tw-text-red-400';
    case 'enable':
      return 'tw-text-green-400';
    default:
      return 'tw-text-white';
  }
};

const TcButtonClass = ({ designType, noPadding, wide, bold, noPaddingY, labelColor }) => `
  tw-flex
  tw-justify-center
  tw-items-center
  tw-capitalize
  tw-outline-none
  tw-rounded
  focus:tw-outline-none
  disabled:tw-opacity-50
  disabled:tw-cursor-not-allowed
  ${!noPaddingY ? 'tw-py-2' : ''}
  ${labelColor ? labelColor : getTcButtonTextColorByType(designType)}
  ${!noPadding ? 'tw-px-6' : ''}
  ${wide ? 'tw-w-48' : ''}
  ${bold ? 'tw-font-bold' : ''}
`;

const TcButton = memo(
  forwardRef((props, reference) => {
    const {
      label,
      labelColor,
      loading,
      loadingText,
      loadingIconSize,
      disabled,
      onClick,
      color,
      className,
      type,
      bold,
      full,
      noPadding,
      noPaddingY,
      wide,
      icon,
      iconWidth,
      renderIcon,
      suffix,
      customIcon,
      children,
      loadingColor,
      tooltip,
    } = props;

    const designType = props.designType ?? type;

    const RenderChild = () => {
      if (!loading) return label;

      return (
        <>
          <TcLoadingCircle size={loadingIconSize} className={loadingText && 'tw-mr-1'} color={loadingColor} />
          {loadingText}
        </>
      );
    };

    const RenderIcon = () => {
      if (!renderIcon) return null;
      const classBydesign = designType === 'text' ? 'tw-ml-3 tw-float-right' : 'tw-mr-4 tw-float-left';
      return customIcon ? (
        <IconCard src={icon?.src} alt={''} className="tw-mx-auto tw-mb-2" />
      ) : (
        <TcIcon
          src={icon?.src}
          alt={icon?.alt}
          className={icon.className ? icon.className : classBydesign}
          width={iconWidth}
          active={designType === 'contrast' || designType === 'contrast-icon-only'}
        />
      );
    };

    const RenderSuffix = () => {
      if (!suffix) return null;

      return <TcButtonSuffixStyled>{suffix}</TcButtonSuffixStyled>;
    };

    const RenderButtonWithTooltip = () => {
      return (
        <TcTooltip title={tooltip}>
          <TcButtonWrapperStyled className={TcButtonWrapperClass} full={full}>
            <TcButtonStyled
              ref={reference}
              onClick={onClick}
              className={TcButtonClass({ designType, noPadding, wide, bold, noPaddingY, labelColor }) + className}
              designType={designType}
              type={type}
              full={full}
              color={color}
              disabled={disabled}
            >
              <RenderSuffix />
              <RenderIcon />
              <RenderChild />
              {children}
            </TcButtonStyled>
          </TcButtonWrapperStyled>
        </TcTooltip>
      );
    };

    if (tooltip) return <RenderButtonWithTooltip />;

    return (
      <TcButtonWrapperStyled className={TcButtonWrapperClass} full={full}>
        <TcButtonStyled
          ref={reference}
          onClick={onClick}
          className={TcButtonClass({ designType, noPadding, wide, bold, noPaddingY, labelColor }) + className}
          designType={designType}
          type={type}
          full={full}
          color={color}
          disabled={disabled}
        >
          <RenderSuffix />
          <RenderIcon />
          <RenderChild />
          {children}
        </TcButtonStyled>
      </TcButtonWrapperStyled>
    );
  }),
);

TcButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingIconSize: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  designType: PropTypes.string,
  bold: PropTypes.bool,
  full: PropTypes.bool,
  noPadding: PropTypes.bool,
  wide: PropTypes.bool,
  icon: PropTypes.object,
  iconWidth: PropTypes.string,
  renderIcon: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customIcon: PropTypes.bool,
  noPaddingY: PropTypes.bool,
  labelColor: PropTypes.string,
  loadingColor: PropTypes.string,
  tooltip: PropTypes.string,
};

TcButton.defaultProps = {
  label: '',
  loading: false,
  loadingText: '',
  loadingIconSize: 30,
  labelColor: '',
  disabled: false,
  color: '#E48B01',
  className: '',
  type: 'default',
  bold: false,
  full: false,
  noPadding: false,
  wide: false,
  icon: {},
  iconWidth: '18px',
  renderIcon: false,
  suffix: '',
  customIcon: false,
  noPaddingY: false,
};

export default TcButton;
