import {
  CHANGE_REFERRAL_RECORD_LIST_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_PER_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_TAB,
  CHANGE_REFERRAL_RECORD_LIST_SEARCH,
  CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY,
} from 'store/actions';
import { INITIAL_STATE } from './defaultReferralRecordsListPaginationState';

const referralRecordListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_REFERRAL_RECORD_LIST_PAGE:
      return { ...state, pageObject: action.payload };
    case CHANGE_REFERRAL_RECORD_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_REFERRAL_RECORD_LIST_TAB:
      return { ...state, tab: action.payload };
    case CHANGE_REFERRAL_RECORD_LIST_SEARCH:
      return { ...state, search: action.payload };
    case CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY:
      return { ...state, isCampaignsOnly: action.payload };
    default:
      return state;
  }
};

export default referralRecordListPaginationReducer;
