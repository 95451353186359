import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const TcLabelStyled = styled.div`
  color: ${(props) => props.color};

  ${(props) =>
    props.isChop &&
    `width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  font-size: 12px;
  font-weight: 350;`}

  ${({ error }) =>
    error &&
    css`
      color: var(--color-red-300);
    `}
`;

const TcLabelClass = ({ noMargin, capitalized, isChop }) => `
  ${capitalized ? 'tw-capitalize' : ''}
  ${!noMargin ? 'tw-mb-2' : ''}
  ${isChop && 'tw-border-2 tw-border-gray-400 tw-rounded-full tw-bg-gray-300 tw-justify-center tw-align-center'}

`;

const truncateText = (text, threshold) => {
  return text.length > threshold ? text.slice(0, threshold) + '...' : text;
};

const TcLabel = memo((props) => {
  const { title, color, error, type, className, noMargin, onClick, capitalized, isChop, truncate, truncateThreshold } =
    props;

  return (
    <TcLabelStyled
      className={TcLabelClass({ noMargin, capitalized, isChop }) + className}
      color={color}
      isChop={isChop}
      error={error}
      type={type}
      onClick={onClick}
    >
      {truncate ? truncateText(title, truncateThreshold) : title}
    </TcLabelStyled>
  );
});

TcLabel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  onClick: PropTypes.func,
  capitalized: PropTypes.bool,
  isChop: PropTypes.bool,
  truncate: PropTypes.bool,
  truncateThreshold: PropTypes.number,
};

TcLabel.defaultProps = {
  color: 'var(--color-brown-500)',
  error: '',
  type: '',
  className: '',
  noMargin: false,
  capitalized: true,
  isChop: false,
  truncate: false,
  truncateThreshold: 10,
};

export default TcLabel;
