import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const ThumbnailImageContainer = styled.div.attrs(({ className }) => ({
  className,
}))`
  ${({ size }) => {
    return css`
      min-width: ${size};
      max-width: ${size};
      height: ${size};
    `;
  }};
`;

const ThumbnailImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const TcThumbnail = (props) => {
  const { className, src, size } = props;

  return (
    <ThumbnailImageContainer size={size} className={className}>
      <ThumbnailImage src={src} />
    </ThumbnailImageContainer>
  );
};

TcThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  // Used for width and height, since the container is intended to be squared
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TcThumbnail.defaultProps = {
  className: '',
};

export default TcThumbnail;
