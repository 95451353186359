import { ADD_TOAST, REMOVE_TOAST } from 'store/actions';
import createToast from 'utils/toast/createToast';

export const addToast = (config) => {
  return {
    type: ADD_TOAST,
    payload: createToast(config),
  };
};

export const removeToast = (id) => {
  return {
    type: REMOVE_TOAST,
    payload: id,
  };
};
