import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class LeaderboardService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(payload) {
    const { parameters } = payload;

    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.LEADERBOARD;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
