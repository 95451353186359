import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class AdminQcService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getAdminReviewItems(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);

      const url = `${endpoints.GET_ADMIN_REVIEW_ITEMS}?${queryParameters}`;

      const { data, status } = await this.HTTP.get(url);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async setAdminReviewItemStatus(parameters) {
    try {
      const { payload, payloadBody } = parameters;

      const queryParameters = buildQueryParams(payload);

      const url = `${endpoints.SET_ADMIN_REVIEW_ITEM_STATUS}?${queryParameters}`;

      const { data, status } = await this.HTTP.post(url, payloadBody);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async processAdminQcItems(parameters) {
    try {
      const { payload } = parameters;

      const queryParameters = buildQueryParams(payload);

      const url = `${endpoints.PROCESS_ADMIN_QC_ITEMS}?${queryParameters}`;

      const { data, status } = await this.HTTP.post(url, payload);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async getAdminQcItemsReviewStats(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);

      const url = `${endpoints.GET_ADMIN_QC_REVIEW_ITEM_STATS}?${queryParameters}`;

      const { data, status } = await this.HTTP.get(url);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }
}
