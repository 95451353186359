import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

const FillerStyled = styled.div`
  height: 25px;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ progress, error }) => {
    if (error) return 'var(--color-red-400)';
    else if (progress === 100) return 'var(--color-green-100)';
    else return 'var(--color-orange-200)';
  }};
  transition: ${({ progress }) => (progress ? 'width 1s ease-out 0s, background 0.15s ease 1s' : 'none')};
`;

const FillerClass = `
  tw-rounded
`;

const TcProgressBarStyled = styled.div`
  height: 25px;
  background: #eeeeee;
`;

const TcProgressBarClass = `
  tw-my-4
  tw-relative
  tw-w-full
  tw-rounded
`;

const TcProgressBar = (props) => {
  const { progress, error } = props;

  return (
    <TcProgressBarStyled className={TcProgressBarClass}>
      <FillerStyled progress={progress} error={error} className={FillerClass} />
    </TcProgressBarStyled>
  );
};

TcProgressBar.propTypes = {
  progress: PropTypes.number,
  error: PropTypes.bool,
};

export default memo(TcProgressBar);
