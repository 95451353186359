import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const TcInputStyled = styled.input`
  width: 100%;
  background-color: ${(props) =>
    props.readOnly
      ? `var(--color-gray-200)
`
      : `
`};
  border-color: ${(props) => (props.borderColor ? props.borderColor : '#EDEDED')};

  ${({ error }) =>
    error &&
    css`
      color: var(--color-red-300);
      border-color: var(--color-red-300);

      ::placeholder {
        color: var(--color-red-300);
      }
    `}
`;

const TcTextAreaStyled = styled.textarea`
  width: 100%;
  background-color: ${(props) =>
    props.readOnly
      ? `var(--color-gray-200)
`
      : `
`};

  ${({ error }) =>
    error &&
    css`
      color: var(--color-red-300);
      border-color: var(--color-red-300);

      ::placeholder {
        color: var(--color-red-300);
      }
    `}
`;

const TcInputClass = `
tw-rounded-lg
tw-py-2
tw-px-4
tw-border
tw-outline-none
tw-text-black
tw-placeholder-gray-600
`;

const TcFormFieldErrorClass = `
  tw-absolute
  tw-mt-1
  tw-text-xs
  tw-text-red-300
`;

const FormInput = (props) => {
  const {
    name,
    type,
    value,
    onChange,
    onBlur,
    min,
    max,
    placeholder,
    autoComplete,
    error,
    className,
    disabled,
    textarea,
    rows,
    readOnly,
    borderColor,
  } = props;

  const RenderError = () => {
    if (!error) return null;
    return <div className={TcFormFieldErrorClass}>{error}</div>;
  };

  return (
    <div>
      {!textarea ? (
        <TcInputStyled
          min={min}
          max={max}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          error={error}
          disabled={disabled}
          value={value}
          className={TcInputClass + className}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          borderColor={borderColor}
        />
      ) : (
        <TcTextAreaStyled
          rows={rows}
          min={min}
          max={max}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          error={error}
          disabled={disabled}
          value={value}
          className={TcInputClass + className}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          borderColor={borderColor}
        />
      )}
      <RenderError />
    </div>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  textarea: PropTypes.bool,
  rows: PropTypes.number,
  readOnly: PropTypes.bool,
  borderColor: PropTypes.string,
};

FormInput.defaultProps = {
  value: '',
  placeholder: '',
  autoComplete: '',
  full: false,
  error: '',
  icon: {},
  renderIcon: false,
  className: '',
  disabled: false,
  min: 0,
  type: 'text',
};

export default FormInput;
