const FIRST_STEP = 'FIRST_STEP';
const PREV_STEP = 'PREV_STEP';
const NEXT_STEP = 'NEXT_STEP';
const FINISH_STEP = 'FINISH_STEP';
const GOTO_STEP = 'GOTO_STEP';
const SET_DATA = 'SET_DATA';

const STEP_ONE = 'STEP_ONE';
const STEP_TWO = 'STEP_TWO';
const STEP_THREE = 'STEP_THREE';
const STEP_FOUR = 'STEP_FOUR';
const STEP_FIVE = 'STEP_FIVE';

export const stepMapping = {
  1: STEP_ONE,
  2: STEP_TWO,
  3: STEP_THREE,
  4: STEP_FOUR,
  5: STEP_FIVE,
};

export {
  FIRST_STEP,
  PREV_STEP,
  NEXT_STEP,
  FINISH_STEP,
  GOTO_STEP,
  SET_DATA,
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
  STEP_FIVE,
};
