import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Group, Rect, Transformer } from 'react-konva';
import RenderBoundingBoxLabel from './RenderBoundingBoxLabel';

const BoundingBox = ({ boxProps, isSelected, onSelect, onChange, draggable, resize, label, zoomValue }) => {
  const shapeReference = useRef(null);
  const trReference = useRef(null);

  const [outerProps, setOuterProps] = useState({});

  useEffect(() => {
    setOuterProps(calculateOuterProps(boxProps));
    // For some reason, need to manually set the dimensions.
    // Otherwise, when the box is clamped to the image dimension,
    // the Rect sometimes does not update with the new value.
    shapeReference.current.x(boxProps.x);
    shapeReference.current.y(boxProps.y);
    shapeReference.current.width(boxProps.width);
    shapeReference.current.height(boxProps.height);
  }, [boxProps, shapeReference]);

  useEffect(() => {
    if (isSelected) {
      // need to attach transformer manually
      trReference.current.nodes([shapeReference.current]);
      trReference.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const calculateOuterProps = (boxProps) => {
    const toleranceRange = boxProps.toleranceRange || 0;
    const doubleToleranceRange = toleranceRange * 2;
    return {
      ...boxProps,
      x: boxProps.x - toleranceRange,
      y: boxProps.y - toleranceRange,
      width: boxProps.width + doubleToleranceRange,
      height: boxProps.height + doubleToleranceRange,
    };
  };

  const handleMouseEnter = useCallback(
    (event) => {
      const stage = event.target.getStage().container();

      if (draggable) {
        stage.style.cursor = 'pointer';
        shapeReference.current.to({
          opacity: 0.75,
          duration: 0.05,
        });
      }
    },
    [boxProps, shapeReference, draggable],
  );

  const handleMouseLeave = useCallback(
    (event) => {
      const stage = event.target.getStage().container();
      stage.style.cursor = 'inherit';
      shapeReference.current.to({
        opacity: 1,
        duration: 0.05,
      });
    },
    [boxProps, shapeReference],
  );

  const handleDragMove = (event) => {
    setOuterProps({
      ...outerProps,
      x: event.target.x() - boxProps.toleranceRange,
      y: event.target.y() - boxProps.toleranceRange,
    });
  };

  const handleDragEnd = (event) => {
    onChange({
      ...boxProps,
      x: event.target.x(),
      y: event.target.y(),
    });
  };

  const handleTransformEnd = () => {
    const node = shapeReference.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...boxProps,
      x: node.x(),
      y: node.y(),
      // set minimal value
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(5, node.height() * scaleY),
    });
  };

  return (
    <>
      {boxProps.toleranceRange ? <Rect {...outerProps} stroke="#F29500" dash={[10, 10]} /> : null}
      <Group>
        {label && (
          <RenderBoundingBoxLabel
            boundingBoxStrokeColor={boxProps.stroke}
            boundingBoxWidth={boxProps.width}
            boundingBoxXPosition={boxProps.x}
            boundingBoxYPosition={boxProps.y}
            label={label}
            zoomValue={zoomValue}
          />
        )}
        <Rect
          onClick={(event) => {
            draggable && onSelect(event);
          }}
          onTap={(event) => {
            draggable && onSelect(event);
          }}
          ref={shapeReference}
          draggable={draggable}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onDragStart={onSelect}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onTransformEnd={handleTransformEnd}
          {...boxProps}
        />
        {isSelected && (
          <Transformer
            ref={trReference}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              newBox.width < 5 || newBox.height < 5 ? oldBox : newBox;
            }}
            rotateEnabled={false}
            borderEnabled={false}
            anchorFill=""
            anchorStroke="white"
            anchorStrokeWidth={2}
            ignoreStroke={true}
            keepRatio={false}
            resizeEnabled={resize}
          />
        )}
      </Group>
    </>
  );
};

BoundingBox.propTypes = {
  boxProps: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  draggable: PropTypes.bool,
  resize: PropTypes.bool,
  label: PropTypes.string,
  zoomValue: PropTypes.number,
};

export default memo(BoundingBox);
