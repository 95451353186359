import { combineReducers } from 'redux';
import authReducer from './Auth/authReducer';
import collapsibleReducer from './Collapsible/collapsibleReducer';
import createJobReducer from './CreateJob/jobsReducer';
import createPreTaskReducer from './CreatePreTaskScreening/preTaskReducer';
import paginationReducers from './Pagination/paginationReducers';
import sidebarReducer from './Sidebar/sidebarReducer';
import toastReducer from './Toast/toastReducer';
import walletHistoryReducer from './WalletHistory/walletHistoryReducer';

export default combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  jobCreateStep: createJobReducer,
  toasts: toastReducer,
  pagination: paginationReducers,
  collapsible: collapsibleReducer,
  createPreTask: createPreTaskReducer,
  walletHistory: walletHistoryReducer,
});
