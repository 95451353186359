import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { deleteIcon } from 'assets';
import { TcDropzoneJob, TcLabel, TcWarning, TcTable, TcSection, TcButton, TcGrid } from 'common/components';
import { audioStep3, srtStep3 } from 'common/constants/global';
import { JobService } from 'services';
import { handleAsync } from 'utils';
import { _ } from 'utils/libraries';
import DeleteAudioDialog from '../../../pages/Jobs/JobCreate/StepThree/components/Dialogs/DeleteAudioDialog';

const TcAudioTranscriptionDropzone = (props) => {
  const { jobId, setUploadFailed, uploading, onUploading, addToastMessage, setReadyToProcess } = props;
  const [uploadedAudioSoundtracks, setUploadedAudioSoundtracks] = useState([]);
  const [uploadedAudioTranscriptions, setUploadedAudioTranscriptions] = useState([]);
  const [displayDeleteFileDialog, setDisplayDeleteFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const debounceGetUploadedAudio = useCallback(
    _.debounce(
      () => {
        getUploadedAudio();
      },
      200,
      { leading: true },
    ),
    [],
  );

  useEffect(() => {
    let subscribeAudioSoundtracks = true;
    if (subscribeAudioSoundtracks && !uploading) debounceGetUploadedAudio();
    return () => {
      subscribeAudioSoundtracks = false;
    };
  }, [jobId, uploading]);

  useEffect(() => {
    setReadyToProcess(uploadedAudioSoundtracks.length === uploadedAudioTranscriptions.length);
  }, [uploadedAudioSoundtracks, uploadedAudioTranscriptions]);

  const deleteAudioSoundtrackFile = (fileId) => {
    setUploadedAudioSoundtracks(uploadedAudioSoundtracks.filter((file) => file.id !== fileId));
    deleteAudioTranscriptionFile(fileId);
  };

  const deleteAudioTranscriptionFile = (fileId) => {
    setUploadedAudioTranscriptions(uploadedAudioTranscriptions.filter((file) => file.id !== fileId));
  };

  const getUploadedAudio = async () => {
    await getUploadedAudioSoundtracks();
    await getUploadedAudioTranscriptions();
  };

  const handleDeleteFile = async (event) => {
    event.preventDefault();
    try {
      const parameters = {
        payload: {
          job_id: jobId,
        },
        payloadBody: [selectedFile?.id],
        typeJob: 'audio_transcription_cleansing',
        fileType: selectedFile?.fileType,
      };
      const [, error] = await handleAsync(JobService.deleteJobFiles(parameters));
      if (error) throw error;
      if (selectedFile?.fileType === audioStep3) {
        deleteAudioSoundtrackFile(selectedFile?.id);
      } else {
        deleteAudioTranscriptionFile(selectedFile?.id);
      }
      addToastMessage('File deleted', 'success', 3000);
    } catch (error) {
      addToastMessage(JSON.stringify(error), 'error', 3000);
    }
    setSelectedFile(null);
    setDisplayDeleteFileDialog(false);
  };

  const handleDeleteClicked = async (event, fileId, fileType) => {
    event.preventDefault();
    const fileList = fileType === audioStep3 ? uploadedAudioSoundtracks : uploadedAudioTranscriptions;
    setSelectedFile({ ...fileList.find((file) => file.id === fileId), fileType });
    setDisplayDeleteFileDialog(true);
  };

  const RenderTableBody = (uploadedFiles, fileType) => {
    const RenderActionButton = ({ file }) => {
      return (
        <TcButton
          type="contrast"
          bold
          label="Delete File"
          icon={{
            src: deleteIcon,
            alt: 'delete file',
          }}
          renderIcon
          onClick={(event) => handleDeleteClicked(event, file.id, fileType)}
          className="tw--mt-1"
        />
      );
    };

    const element = uploadedFiles.map((file, index) => {
      return [
        {
          label: index + 1,
          align: 'left',
        },
        {
          label: file.filename,
          align: 'left',
        },
        {
          label: <RenderActionButton file={file} />,
        },
      ];
    });
    return element;
  };

  const RenderUploadedFilesTable = ({ uploadedFiles, fileType }) => {
    if (!uploadedFiles || uploadedFiles.length === 0) return null;
    return (
      <TcSection>
        <TcTable headers={[]} rows={RenderTableBody(uploadedFiles, fileType)} rowHoverEffect />
      </TcSection>
    );
  };

  const getUploadedAudioSoundtracks = async () => {
    const parameters = {
      payload: {
        job_id: jobId,
      },
      typeJob: 'audio_transcription_cleansing',
      fileType: audioStep3,
    };
    const [res] = await handleAsync(JobService.getJobFiles(parameters));
    setUploadedAudioSoundtracks(res);
  };

  const getUploadedAudioTranscriptions = async () => {
    const parameters = {
      payload: {
        job_id: jobId,
      },
      typeJob: 'audio_transcription_cleansing',
      fileType: srtStep3,
    };
    const [res] = await handleAsync(JobService.getJobFiles(parameters));
    setUploadedAudioTranscriptions(res);
  };

  return (
    <>
      <DeleteAudioDialog
        displayDialog={displayDeleteFileDialog && selectedFile}
        setDisplayDialog={setDisplayDeleteFileDialog}
        handleDeleteFile={handleDeleteFile}
        file={selectedFile}
      />
      <TcGrid className="tw-mb-8">
        <TcWarning message="Before uploading, make sure the audio file and .srt file have the same name" />
      </TcGrid>
      <div className="tw-flex">
        <div className="tw-w-1/2 tw-pr-6 tw-border-r-2 tw-border-gray-500">
          <TcLabel className="tw-text-lg" title="1. Please upload audio first before .srt file" />
          <TcDropzoneJob
            type={audioStep3}
            id={jobId}
            onUploading={onUploading}
            setUploadFailed={setUploadFailed}
            allowMultipleUpload
          />
          <RenderUploadedFilesTable uploadedFiles={uploadedAudioSoundtracks} fileType={audioStep3} />
        </div>
        <div className="tw-w-1/2 tw-pl-6">
          <TcLabel className="tw-text-lg" title="2. Please upload .srt file" />
          <TcDropzoneJob
            type={srtStep3}
            id={jobId}
            onUploading={onUploading}
            setUploadFailed={setUploadFailed}
            allowMultipleUpload
            disabled={uploadedAudioSoundtracks.length === uploadedAudioTranscriptions.length}
            failedNote={'Upload process failed, make sure .srt file has same name as audio file'}
          />
          <RenderUploadedFilesTable uploadedFiles={uploadedAudioTranscriptions} fileType={srtStep3} />
        </div>
      </div>
    </>
  );
};

TcAudioTranscriptionDropzone.propTypes = {
  jobId: PropTypes.string.isRequired,
  setUploadFailed: PropTypes.func.isRequired,
  onUploading: PropTypes.func.isRequired,
  addToastMessage: PropTypes.func.isRequired,
  setReadyToProcess: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

export default TcAudioTranscriptionDropzone;
