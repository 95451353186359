import { CHANGE_BADGES_LIST_PAGE, CHANGE_BADGES_LIST_PER_PAGE, CHANGE_BADGES_LIST_SEARCH } from 'store/actions';
import { INITIAL_STATE } from './defaultBadgesListPaginationState';

const badgesListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_BADGES_LIST_PAGE:
      return { ...state, page: action.payload };
    case CHANGE_BADGES_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_BADGES_LIST_SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export default badgesListPaginationReducer;
