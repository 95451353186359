import { AuthService, changeSidebarType } from 'store/actions';

export const loginAndUpdateSidebarType =
  (payload = {}) =>
  async (dispatch, getState) => {
    await dispatch(AuthService.login(payload));

    // updates sidebarType state based on auth user type
    const { auth } = getState();
    if (auth?.isLogin) {
      if (auth?.user?.type === 'admin') {
        dispatch(changeSidebarType('GENERAL_ADMIN'));
      } else {
        dispatch(changeSidebarType('GENERAL'));
      }
    }
  };
