import {
  CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD,
  ADD_BOUNDING_BOX,
  EDIT_BOUNDING_BOX,
  DELETE_BOUNDING_BOX,
  DELETE_LAST_BOUNDING_BOX,
  CLEAR_PAYLOAD,
} from '../../actions';

const INITIAL_STATE = {
  jobMethod: '',
  title: '',
  items: [],
};

const preTaskReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  const handleChangeItems = () => {
    const newItems = payload.items.map((item) => {
      const itemIndex = state.items.findIndex((stateItem) => stateItem.id === item.id);
      return {
        ...item,
        ...(itemIndex >= 0 && { boundingShapes: state.items[itemIndex].boundingShapes }),
      };
    });
    return newItems;
  };

  const handleAddBoundingBox = () => {
    const newItems = state.items.map((item) => {
      if (item.id === payload.itemId) {
        delete payload.itemId;
        return {
          ...item,
          boundingShapes: item.boundingShapes ? item.boundingShapes.concat(payload) : [payload],
        };
      }
      return item;
    });
    return newItems;
  };

  const handleEditBoundingBox = () => {
    const newItems = state.items.map((item) => {
      if (item.id === payload.itemId) {
        delete payload.itemId;
        return {
          ...item,
          boundingShapes: item.boundingShapes.map((box) => {
            if (box.id === payload.id) {
              return { ...box, ...payload };
            }
            return box;
          }),
        };
      }
      return item;
    });
    return newItems;
  };

  const handleDeleteBoundingBox = () => {
    const newItems = state.items.map((item) => {
      if (item.id === payload.itemId) {
        delete payload.itemId;
        return {
          ...item,
          boundingShapes: item.boundingShapes.filter((box) => box.id !== payload.id),
        };
      }
      return item;
    });
    return newItems;
  };

  const handleDeleteLastBoundingBox = () => {
    const itemIndex = state.items.findIndex((item) => item.id === payload.itemId);
    const newItems = [...state.items];
    newItems[itemIndex]?.boundingShapes.length && newItems[itemIndex]?.boundingShapes.pop();
    return newItems;
  };

  switch (type) {
    case CHANGE_PRE_TASK_SCREENING_CREATE_PAYLOAD:
      return { ...state, ...payload, items: handleChangeItems() };
    case ADD_BOUNDING_BOX:
      return {
        ...state,
        items: handleAddBoundingBox(),
      };
    case EDIT_BOUNDING_BOX:
      return {
        ...state,
        items: handleEditBoundingBox(),
      };
    case DELETE_BOUNDING_BOX:
      return {
        ...state,
        items: handleDeleteBoundingBox(),
      };
    case DELETE_LAST_BOUNDING_BOX:
      return {
        ...state,
        items: handleDeleteLastBoundingBox(),
      };
    case CLEAR_PAYLOAD:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default preTaskReducer;
