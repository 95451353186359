import { CHANGE_USER_TASK_LIST_PAGE, CHANGE_USER_TASK_LIST_TAB, CHANGE_USER_TASK_LIST_PER_PAGE } from 'store/actions';
import { INITIAL_STATE } from './defaultUserTaskListPaginationState';

const userTaskListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_USER_TASK_LIST_PAGE:
      return { ...state, pageObject: action.payload };
    case CHANGE_USER_TASK_LIST_TAB:
      return { ...state, tab: action.payload };
    case CHANGE_USER_TASK_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    default:
      return state;
  }
};

export default userTaskListPaginationReducer;
