import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { drag, errorIcon, audioIcon, deleteRedIcon } from 'assets';
import { TcIcon, FormField, TcButton } from 'common/components';

const CardImageContainer = styled.div`
  align-items: center;
  padding: 1rem;
  display: flex;
  gap: 0.75rem;
  width: 500px;
  border: 1px solid #c2b8a4;
  border-radius: 6px;
`;

const ImageContainer = styled.div`
  max-width: 100px;
  max-height: 100px;
  & img {
    width: 120px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
  }
`;

const AudioIconContainer = styled.div`
  /* max-width: 100px;
  max-height: 100px; */
  & img {
    /* width: 120px;
    height: 100px; */
    margin: 4px;
    object-fit: cover;
    border-radius: 6px;
  }
`;

const FileName = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #6c624f;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const TcCardImageUploaded = (props) => {
  const { imgSource, jobType, imgTitle, field, touched, errors, onDeleteFile, withText = true, hasDragIcon } = props;
  return (
    <CardImageContainer>
      {hasDragIcon ? <TcIcon src={drag} alt="drag" className="tw-ml-2 hover:tw-cursor-grab" /> : null}
      {jobType == 'image' ? (
        <ImageContainer>
          <img alt="card-image" src={imgSource} />
        </ImageContainer>
      ) : (
        <AudioIconContainer>
          <img alt="audio-icon" src={audioIcon} />
        </AudioIconContainer>
      )}
      {/* <img alt="audio-icon" src={audioIcon} /> */}
      <div className="tw-flex tw-justify-between tw-w-full">
        {withText ? (
          <FormField
            type="text"
            placeholder="Eg. Bound Bottle Cap"
            error={touched[field.name] && errors[field.name]}
            icon={{ src: errorIcon, alt: 'Error Icon' }}
            renderIcon={touched[field.name] && errors[field.name]}
            full
            {...field}
          />
        ) : (
          <FileName>{imgTitle}</FileName>
        )}
        <TcButton
          designType="error"
          type="button"
          bold
          label="Cancel File"
          icon={{
            src: deleteRedIcon,
            alt: 'cancel file',
          }}
          renderIcon
          onClick={onDeleteFile}
          className="tw--mt-1"
        />
      </div>
    </CardImageContainer>
  );
};

TcCardImageUploaded.propTypes = {
  imgSource: PropTypes.string,
  field: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  onDeleteFile: PropTypes.func,
  withText: PropTypes.bool,
  hasDragIcon: PropTypes.bool,
  imgTitle: PropTypes.string,
  jobType: PropTypes.string,
};

export default TcCardImageUploaded;
