export const LOG_LIST_ADMIN = '/v1/admin/log/all';
export const LOG_CREATE_ADMIN = '/v1/admin/log';
export const LOG_UPDATE_ADMIN = '/v1/admin/log';
export const LOG_ARCHIVE_ADMIN = '/v1/admin/log/archive';
export const LOG_IMAGE_UPLOAD = '/v1/admin/log/image';
export const LOG_GET_DETAIL_ADMIN = 'v1/admin/log';

export const LOG_LIST_USER = '/v1/log/all';
export const LOG_QUERY_OPTIONS = '/v1/log/query-options';
export const LOG_GET_DETAIL_CLIENT = 'v1/log';
