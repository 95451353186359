import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import styled, { css } from 'styled-components';
import { arrowLineDownWhiteIcon, arrowLineUpWhiteIcon } from 'assets';
import { TcIcon, TcLoadingCircle } from 'common/components';

const TcDropdownWrapperStyled = styled.div`
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcDropdownWrapperClass = `
  tw-inline-block
  tw-relative
`;

const TcMenuItemStyled = styled(MenuItem)`
  color: inherit;
`;

const TcMenuStyled = styled(Menu)`
  color: inherit;
`;

const handleTypeStyle = ({ designType }) => {
  switch (designType) {
    case 'plain':
      return css`
        background-color: var(--color-transparent);
      `;
    case 'contrast':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-orange-400);
        border-radius: 0.5rem;
      `;
    case 'text':
      return css`
        background-color: var(--color-transparent);
      `;
    case 'filled':
      return css`
        background-color: var(--color-gray-200);
        border-radius: 2rem;
        margin-right: 2.5rem;
      `;
    case 'info':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-blue-400);
        border-radius: 0.5rem;
      `;
    case 'error-filled':
      return css`
        background-color: var(--color-red-400);
        border-radius: 0.5rem;
      `;
    case 'approve':
      return css`
        background-color: var(--color-green-600);
      `;
    case 'reject':
      return css`
        background-color: var(--color-red-400);
      `;

    case 'error':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-red-400);
        border-radius: 0.5rem;
      `;
    case 'info-filled':
      return css`
        background-color: var(--color-blue-400);
        border-radius: 0.5rem;
      `;
    case 'enable':
      return css`
        background-color: var(--color-transparent);
        border: 1px solid var(--color-green-400);
        border-radius: 0.5rem;
      `;
    case 'green-outline':
      return css`
        color: var(--color-green-600);
        background-color: var(--color-white);
        border: 1px solid var(--color-green-600);
      `;
    case 'red-outline':
      return css`
        color: var(--color-red-400);
        background-color: var(--color-white);
        border: 1px solid var(--color-red-400);
      `;
    default:
      return css`
        background-color: var(--color-orange-400);
      `;
  }
};

const TcDropdownStyled = styled.button`
  ${handleTypeStyle}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const getTcDropdownTextColorByType = (designType) => {
  switch (designType) {
    case 'plain':
      return 'tw-text-gray-1000';
    case 'contrast':
      return 'tw-text-orange-400';
    case 'text':
      return 'tw-text-orange-400';
    case 'filled':
      return 'tw-text-black';
    case 'info':
      return 'tw-text-blue-400';
    case 'error':
      return 'tw-text-red-400';
    case 'enable':
      return 'tw-text-green-400';
    default:
      return 'tw-text-white';
  }
};

const TcDropdownClass = ({ designType, noPaddingX, wide, bold, noPaddingY, labelColor }) => `
  tw-flex
  tw-justify-center
  tw-items-center
  tw-capitalize
  tw-outline-none
  tw-rounded
  focus:tw-outline-none
  disabled:tw-opacity-50
  disabled:tw-cursor-not-allowed
  ${!noPaddingY ? 'tw-py-2' : ''}
  ${labelColor ? labelColor : getTcDropdownTextColorByType(designType)}
  ${!noPaddingX ? 'tw-px-6' : ''}
  ${wide ? 'tw-w-48' : ''}
  ${bold ? 'tw-font-bold' : ''}
`;

const TcDropdown = memo(
  forwardRef((props, reference) => {
    const {
      label,
      labelColor,
      loading,
      loadingText,
      loadingIconSize,
      disabled,
      color,
      className,
      type,
      bold,
      full,
      noPadding,
      noPaddingY,
      wide,
      iconWidth,
      loadingColor,
      items,
    } = props;

    const designType = props.designType ?? type;

    const RenderChild = () => {
      if (!loading) return label;

      return (
        <>
          <TcLoadingCircle size={loadingIconSize} className={loadingText && 'tw-mr-1'} color={loadingColor} />
          {loadingText}
        </>
      );
    };

    const [anchorElement, setAnchorElement] = React.useState();
    const open = Boolean(anchorElement);

    const handleClick = (event) => {
      setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorElement(null);
    };

    return (
      <TcDropdownWrapperStyled className={TcDropdownWrapperClass} full={full}>
        <TcDropdownStyled
          ref={reference}
          onClick={handleClick}
          className={TcDropdownClass({ designType, noPadding, wide, bold, noPaddingY, labelColor }) + className}
          designType={designType}
          type={type}
          full={full}
          color={color}
          disabled={disabled}
        >
          <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-gap-3">
            <RenderChild />
            <TcIcon
              src={open ? arrowLineUpWhiteIcon : arrowLineDownWhiteIcon}
              alt={open ? 'Close Dropdown' : 'Open Dropdown'}
              width={iconWidth}
              className="tw-white"
            />
          </div>
        </TcDropdownStyled>
        <TcMenuStyled
          id="dropdown-menu"
          anchorEl={anchorElement}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          disablePortal
          className="tw-mt-12"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {items.map((item, index) => (
            <TcMenuItemStyled
              key={`dropdown-menu-item-${index}`}
              onClick={() => {
                item.onClick();
              }}
            >
              {item.label}
            </TcMenuItemStyled>
          ))}
        </TcMenuStyled>
      </TcDropdownWrapperStyled>
    );
  }),
);

TcDropdown.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingIconSize: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  designType: PropTypes.string,
  bold: PropTypes.bool,
  full: PropTypes.bool,
  noPadding: PropTypes.bool,
  wide: PropTypes.bool,
  icon: PropTypes.object,
  iconWidth: PropTypes.string,
  renderIcon: PropTypes.bool,
  customIcon: PropTypes.bool,
  noPaddingY: PropTypes.bool,
  labelColor: PropTypes.string,
  loadingColor: PropTypes.string,
  tooltip: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

TcDropdown.defaultProps = {
  label: '',
  loading: false,
  loadingText: '',
  loadingIconSize: 30,
  labelColor: '',
  disabled: false,
  color: '#E48B01',
  className: '',
  type: 'default',
  bold: false,
  full: false,
  wide: false,
  icon: {},
  iconWidth: '18px',
  renderIcon: false,
  customIcon: false,
  noPaddingX: false,
  noPaddingY: false,
  items: [],
};

export default TcDropdown;
