const INVITE_CODES_ADMIN = 'v1/admin/invitations';
const INVITE_CODE_DEACTIVATE_ADMIN = 'v1/admin/invitations/deactivate';
const INVITE_CODE_TAGGER_REFERRAL_CREATE_ADMIN = 'v1/admin/invitations/tagger-referral';
const INVITE_CODE_NON_TAGGER_REFERRAL_CREATE_ADMIN = 'v1/admin/invitations/non-tagger-referral';
const INVITE_CODE_CHECK_CODE_ADMIN = '/v1/admin/invitations/check-code';

export {
  INVITE_CODES_ADMIN,
  INVITE_CODE_DEACTIVATE_ADMIN,
  INVITE_CODE_TAGGER_REFERRAL_CREATE_ADMIN,
  INVITE_CODE_NON_TAGGER_REFERRAL_CREATE_ADMIN,
  INVITE_CODE_CHECK_CODE_ADMIN,
};
