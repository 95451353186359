import { approved, rejected } from 'common/constants/global';
import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class CoinService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getTaggers(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint =
        parameters.status === approved || parameters.status === rejected
          ? endpoints.RESOLVED_TAGGERS
          : endpoints.PENDING_TAGGERS;

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async approve(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.APPROVE;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async reject(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.REJECT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async calculate(payload) {
    try {
      const url = endpoints.CALCULATE;

      const { data } = await this.HTTP.post(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getQueryOptions() {
    try {
      const url = endpoints.QUERY_OPTIONS;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async increase(payload) {
    try {
      const url = endpoints.INCREASE;

      const { data } = await this.HTTP.post(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async increaseAll(payload) {
    try {
      const url = endpoints.INCREASE_ALL;

      const { data } = await this.HTTP.post(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
  async decrease(payload) {
    try {
      const url = endpoints.DECREASE;

      const { data } = await this.HTTP.post(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async decreaseAll(payload) {
    try {
      const url = endpoints.DECREASE_ALL;

      const { data } = await this.HTTP.post(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
