import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { backIcon, errorIcon } from 'assets';
import {
  TcButton,
  TcSection,
  TcGrid,
  TcRow,
  TcCardImageUploaded,
  FormField,
  DropzonePreTaskScreening,
  TcHeader,
  TcLabel,
  FormLabel,
} from 'common/components';
import { PreTaskScreeningService } from 'services';
import { changePreTaskScreeningCreatePayload, addToast } from 'store/actions';
import { preventSubmitOnEnter, handleAsync } from 'utils';
import { useTranslation } from 'utils/libraries';

const PreTaskCreateContainer = styled.div.attrs({
  className: 'tw-h-full tw-w-full tw-m-auto tw-p-8',
})`
  max-width: 1280px;
`;

const CopiedFieldAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormFieldWrapper = styled.div`
  width: 100%;
  margin-right: 15px;
`;

const PreTaskCreate = ({ createPreTaskPayload, addToast, preTaskEdited }) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const formReference = useRef(null);
  const [isDroppingFile, setDroppingFile] = useState(false);
  const [preTaskDetail, setPreTaskDetail] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(
    () => createPreTaskPayload?.items?.map((item) => ({ ...item, id: item.id })) || [],
  );
  const isFromJobDetail = history?.location?.state?.fromJobDetail === true;
  const isFromPreTaskReview = history?.location?.state?.fromPreTaskReview === true;

  const addToastMessage = (statusCardMessage, statusCardType, closeTiming) => {
    addToast({
      message: statusCardMessage,
      styleType: statusCardType,
      closeTiming: closeTiming,
    });
  };

  const initialValues = {
    title: '',
    label: preTaskDetail?.labels || '',
    /**
     * conditionally assign label fields namely item.id from redux state
     */
    ...(createPreTaskPayload?.items &&
      Object.fromEntries(createPreTaskPayload?.items?.map((item) => [item.id, item.title, item.label]))),
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setUploadedFiles(createPreTaskPayload?.items?.map((item) => ({ ...item, id: item.id })));
  }, [createPreTaskPayload]);

  const handleUploadFinished = useCallback(
    (files) => {
      setUploadedFiles((currentUploadedFiles) => currentUploadedFiles.concat(files));
    },
    [setUploadedFiles],
  );

  const handleDeleteFile = async (event, fileId) => {
    const isLastItem = uploadedFiles?.length == 1;
    const deleteLastFile = () => {
      return isFromJobDetail ? isLastItem && handleBack() : null;
    };
    event.preventDefault();
    try {
      const payload = {
        parameters: {
          pre_task_screening_id: id,
        },
        body: [fileId],
      };
      const [, error] = await handleAsync(PreTaskScreeningService.deleteItemsPreTask(payload));
      if (error) throw error;
      const file = uploadedFiles.find((file) => file.id === fileId);
      const message = file?.filename ? `File "${file.filename}" deleted` : `File deleted`;
      addToastMessage(message, 'success', 3000);
      setUploadedFiles((currentUploadedFiles) => currentUploadedFiles.filter((file) => file.id !== fileId));
      deleteLastFile();
    } catch (error) {
      addToastMessage(JSON.stringify(error), 'error', 3000);
    }
  };

  const RenderUploadedImageCard = () => {
    return (
      <TcRow span={24} className="tw-pb-8 tw-w-full tw-flex tw-justify-between tw-gap-8 tw-flex-wrap">
        {uploadedFiles?.map((file) => (
          <div key={file.id} className="">
            <Field name={file.id} type="text">
              {({ field, form: { touched, errors } }) => (
                <TcCardImageUploaded
                  jobType={preTaskDetail?.type}
                  imgSource={file.location}
                  imgTitle={file.filename}
                  field={field}
                  touched={touched}
                  errors={errors}
                  withText={false}
                  onDeleteFile={(event) => handleDeleteFile(event, file.id)}
                />
              )}
            </Field>
          </div>
        ))}
      </TcRow>
    );
  };

  useEffect(() => {
    if (isDroppingFile && !preTaskEdited) formReference.current?.handleSubmit();
  }, [isDroppingFile]);

  const handleCreatePreTaskScreening = async (id, title, label, type) => {
    const payloadBody =
      type == 'image'
        ? {
            title: title ?? '',
            labels: typeof label === 'string' ? [label] : label,
          }
        : {
            title: title ?? '',
          };
    const payload = {
      parameters: {
        pre_task_screening_id: id,
        type: type,
      },
      body: payloadBody,
    };

    const [, error] = await handleAsync(PreTaskScreeningService.editPreTask(payload));
    if (error) throw error;
    const pathname = `/admin/pre-task-create-step-2/${id}`;
    history.push({
      pathname,
      id,
      state: isFromPreTaskReview ? { fromAddItem: true } : { fromStepOne: true },
    });
  };

  const getPreTaskDetails = async () => {
    const payload = {
      parameters: { pre_task_screening_id: id },
    };
    const [res, error] = await handleAsync(PreTaskScreeningService.getDetailPreTask(payload));
    if (error) addToastMessage(error.error?.message[0], error, 3000);
    return res;
  };

  const handleGetPreTaskData = async () => {
    const resDetails = await getPreTaskDetails();
    setPreTaskDetail(resDetails);
  };

  useEffect(() => {
    handleGetPreTaskData();
  }, []);

  const onClickCopy = async (copiedJobId) => {
    const payload = {
      parameters: {
        pre_task_screening_id: preTaskDetail?.id,
      },
      body: {
        sourceJobId: copiedJobId,
      },
    };
    const [res, error] = await handleAsync(PreTaskScreeningService.copyPreTaskItems(payload));
    if (error) {
      addToastMessage(error.error?.message, 'error', 3000);
    } else {
      addToastMessage('Items Copied Successfully', 'success', 3000);
      setUploadedFiles((currentUploadedFiles) => currentUploadedFiles.concat(res));
    }
  };

  return (
    <PreTaskCreateContainer>
      <TcHeader>
        <TcHeader.Title>
          {isFromPreTaskReview ? t(`Add More Item`) : t(`label.page.preTaskCreate.title`)}
        </TcHeader.Title>
      </TcHeader>
      <div>
        <TcButton
          label={t('label.button.back')}
          icon={{
            src: backIcon,
            alt: 'back icon',
          }}
          type="plain"
          noPadding
          renderIcon
          bold
          onClick={() => handleBack()}
        />
        <TcSection>
          <TcGrid>
            <Formik innerRef={formReference} initialValues={initialValues} enableReinitialize>
              {({ values }) => {
                return (
                  <Form onKeyDown={preventSubmitOnEnter}>
                    {isFromJobDetail && (
                      <Field name="copiedJobId" type="text">
                        {({ field, form: { touched, errors } }) => (
                          <div className="tw-pt-6 tw-border-b">
                            <CopiedFieldAndButtonWrapper>
                              <FormFieldWrapper>
                                <FormField
                                  label="You can duplicate screening task from other jobs with Job Id or fill in the screening task one by one"
                                  type="text"
                                  placeholder="Eg. 6435eea0f1f4a450db62f92c"
                                  error={touched[field.name] && errors[field.name]}
                                  icon={{ src: errorIcon, alt: 'Error Icon' }}
                                  renderIcon={touched[field.name] && errors[field.name]}
                                  full
                                  {...field}
                                />
                              </FormFieldWrapper>
                              <TcButton
                                type="contrast"
                                disabled={!values.copiedJobId}
                                label={'Copy'}
                                onClick={() => onClickCopy(values.copiedJobId)}
                              />
                            </CopiedFieldAndButtonWrapper>
                          </div>
                        )}
                      </Field>
                    )}
                    {isFromJobDetail && (
                      <Field name="title" type="text">
                        {({ field, form: { touched, errors } }) => (
                          <div className="tw-w-full tw-pt-6">
                            <FormField
                              label="Screening Task Title"
                              type="text"
                              name="title"
                              placeholder={
                                preTaskDetail?.type == 'image'
                                  ? 'Eg. Bounding this bottle'
                                  : 'Eg. Transcript this audio'
                              }
                              error={touched[field.name] && errors[field.name]}
                              icon={{ src: errorIcon, alt: 'Error Icon' }}
                              renderIcon={touched[field.name] && errors[field.name]}
                              full
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                    )}
                    {isFromJobDetail && preTaskDetail?.type == 'image' ? (
                      <Field name="label" type="text">
                        {({ field, form: { touched, errors } }) => (
                          <div className="tw-w-full">
                            <FormLabel title={'Label'} error={touched[field.name] && errors[field.name]} disabled />
                            <TcGrid className={`tw-flex tw-mb-3`}>
                              {preTaskDetail?.labels?.map((res, index) => {
                                return <TcLabel noMargin className="tw-mr-3" isChop title={res} key={index} />;
                              })}
                            </TcGrid>
                          </div>
                        )}
                      </Field>
                    ) : (
                      ''
                    )}
                    {isFromPreTaskReview && (
                      <>
                        <div className="tw-pt-8 tw-pb-8">
                          <table>
                            <tbody>
                              <tr>
                                <td className="tw-align-text-top">Screening task title</td>
                                <td className="tw-align-text-top tw-pl-6">:</td>
                                <td className="tw-font-bold">{preTaskDetail?.title}</td>
                              </tr>
                              {preTaskDetail?.type == 'audio' ? null : (
                                <tr>
                                  <td className="tw-align-text-top">Label</td>
                                  <td className="tw-align-text-top tw-pl-6">:</td>
                                  <td className="tw-font-bold">
                                    <TcGrid className={`tw-flex tw-mb-5`}>
                                      {preTaskDetail?.labels?.map((res, index) => {
                                        return <TcLabel isChop className="tw-mr-3" title={res} key={index} noMargin />;
                                      })}
                                    </TcGrid>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="tw-pb-4">Add More Files</div>
                      </>
                    )}
                    <TcRow span={24} className="tw-pb-8">
                      <DropzonePreTaskScreening
                        type={preTaskDetail?.type}
                        disabled={
                          // TO DO: find proper solution for this - Dhani 060423
                          // eslint-disable-next-line no-nested-ternary
                          isFromJobDetail
                            ? preTaskDetail?.type == 'image'
                              ? !values.title || !values.label
                              : !values.title
                            : null
                        }
                        id={id}
                        onUploading={setDroppingFile}
                        onUploadFinished={handleUploadFinished}
                      />
                    </TcRow>
                    <RenderUploadedImageCard />
                    <TcRow span={24} className="tw-pb-8 tw-text-right">
                      <TcButton
                        type="submit"
                        onClick={
                          isFromJobDetail
                            ? () => handleCreatePreTaskScreening(id, values.title, values.label, preTaskDetail?.type)
                            : () => handleCreatePreTaskScreening(id, preTaskDetail?.title, preTaskDetail?.type)
                        }
                        disabled={
                          // TO DO: find proper solution for this - Dhani 060423
                          // eslint-disable-next-line no-nested-ternary
                          isFromJobDetail
                            ? preTaskDetail?.type == 'image'
                              ? !uploadedFiles.length || !values.title || !values.label
                              : !uploadedFiles.length || !values.title
                            : !uploadedFiles.filter((item) => item.is_configured == false).length
                        }
                        label={t('label.button.nextStep')}
                      />
                    </TcRow>
                  </Form>
                );
              }}
            </Formik>
          </TcGrid>
        </TcSection>
      </div>
    </PreTaskCreateContainer>
  );
};

PreTaskCreate.propTypes = {
  createPreTaskPayload: PropTypes.object,
  changePreTaskScreeningCreatePayload: PropTypes.func,
  onStepChange: PropTypes.func,
  addToast: PropTypes.func,
  preTaskEdited: PropTypes.bool,
  onPreTaskEdited: PropTypes.func,
  type: PropTypes.string,
};

const mapStateToProperties = (state) => {
  return {
    createPreTaskPayload: state.createPreTask,
  };
};

export default connect(mapStateToProperties, { changePreTaskScreeningCreatePayload, addToast })(memo(PreTaskCreate));
