/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import { Circle, Group, Line, Rect } from 'react-konva';
import RenderBoundingBoxLabel from 'common/components/TcBoundingBox/RenderBoundingBoxLabel';
import { JobMethod } from 'common/constants/global';

const Polygon = ({
  taskMethod = '',
  polygon = {},
  handleDragPointBox = () => {},
  isFinished = false,
  handleDragMovePoint = () => {},
  handleMouseOverStartPoint = () => {},
  handleMouseOutStartPoint = () => {},
  handleShapeLineClick = () => {},
  currentMousePos = [],
  dragablePoints = false,
  zoomValue = 1,
  onElementDragStart = () => {},
  onElementDragEnd = () => {},
  label,
}) => {
  const flattenedPoints = polygon.points?.concat(isFinished ? [] : currentMousePos)?.reduce((a, b) => a.concat(b), []);

  const findBoxWidthAndBoxLabelPosition = (coordinates) => {
    let leftmostX = coordinates[0];
    let lowestY = coordinates[1];
    let rightmostX = coordinates[0];

    for (let index = 0; index < coordinates.length; index += 2) {
      leftmostX = Math.min(leftmostX, coordinates[index]);
      rightmostX = Math.max(rightmostX, coordinates[index]);
      lowestY = Math.min(lowestY, coordinates[index + 1]);
    }

    return {
      boundingBoxXPosition: leftmostX,
      boundingBoxYPosition: lowestY,
      boundingBoxWidth: rightmostX - leftmostX,
    };
  };

  return (
    <>
      {label && flattenedPoints.length > 0 && isFinished && (
        <RenderBoundingBoxLabel
          boundingBoxStrokeColor={label?.color}
          {...findBoxWidthAndBoxLabelPosition(flattenedPoints)}
          label={label?.text}
          zoomValue={zoomValue}
          isShowlabelColor={false}
        />
      )}
      <Group>
        <Line
          points={flattenedPoints}
          stroke="#16A43E"
          fill={polygon?.isSelected ? 'rgba(22, 164, 62, 0.361)' : 'rgba(22, 164, 62, 0.2)'}
          strokeWidth={400 / zoomValue}
          closed={taskMethod === JobMethod.ImageBoundingBox ? true : isFinished}
          lineJoin="round"
          onClick={handleShapeLineClick}
          lineCap="round"
          dash={taskMethod === JobMethod.ImageBoundingBox ? [10, 10] : []}
          dashEnabled={true}
        />
        {polygon?.isSelected &&
          polygon?.points?.map((point, index) => {
            const width = 12;
            const x = point[0];
            const y = point[1];
            const startPointAttribute =
              index === 0
                ? {
                    hitStrokeWidth: 12,
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint,
                  }
                : null;
            return (
              <>
                {taskMethod === JobMethod.ImageBoundingBox ? (
                  <Rect
                    key={index}
                    x={x}
                    y={y}
                    width={800 / zoomValue}
                    height={800 / zoomValue}
                    // fill="white"
                    stroke="white"
                    strokeWidth={200 / zoomValue}
                    offsetX={400 / zoomValue}
                    offsetY={400 / zoomValue}
                    onDragMove={handleDragPointBox}
                    draggable={dragablePoints}
                    onDragStart={onElementDragStart}
                    onDragEnd={onElementDragEnd}
                    {...startPointAttribute}
                  />
                ) : (
                  <Circle
                    key={index}
                    x={x}
                    y={y}
                    width={800 / zoomValue}
                    height={800 / zoomValue}
                    // fill="white"
                    stroke="white"
                    strokeWidth={200 / zoomValue}
                    onDragMove={handleDragMovePoint}
                    draggable={dragablePoints}
                    onDragStart={onElementDragStart}
                    onDragEnd={onElementDragEnd}
                    {...startPointAttribute}
                  />
                )}
              </>
            );
          })}
      </Group>
    </>
  );
};

export default Polygon;
Polygon.propTypes = {
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  polygon: PropTypes.object,
  flattenedPoints: PropTypes.arrayOf(PropTypes.number),
  currentMousePos: PropTypes.arrayOf(PropTypes.number),
  isFinished: PropTypes.bool,
  isMouseOverStartPoint: PropTypes.bool,
  handleDragMovePoint: PropTypes.func,
  handleDragEndPoint: PropTypes.func,
  onDragMove: PropTypes.func,
  onElementDragStart: PropTypes.func,
  onElementDragEnd: PropTypes.func,
  handleDragPointBox: PropTypes.func,
  handleMouseOverStartPoint: PropTypes.func,
  handleMouseOutStartPoint: PropTypes.func,
  handleShapeLineClick: PropTypes.func,
  dragablePoints: PropTypes.bool,
  thresholdPolygon: PropTypes.number,
  boundingBoxActive: PropTypes.bool,
  scaleX: PropTypes.number,
  scaleY: PropTypes.number,
  taskMethod: PropTypes.string,
  zoomValue: PropTypes.number,
  label: PropTypes.string,
};
