import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';
import { TcButton } from 'common/components';

const TcPopperWrapper = `
  tw-relative
  tw-inline-block
`;

const TcPopperTitle = `
  tw-font-medium
  tw-text-gray-1000
  tw-text-center
  tw-no-underline
  tw-leading-8
`;

const TcPopperWrapperStyled = styled.div`
  &.active .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(-825px, 10px);
    transition: 0.5s;
  }
`;

const TcPopperContentStyled = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translate(-825px, 10px);
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
  width: 900px;

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    right: 55px;
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
`;

const TcPopper = memo((props) => {
  const { title, children, show, onClick, isButton, icon, suffix } = props;

  const RenderContent = () => {
    if (isButton) {
      return (
        <TcButton
          type="plain"
          icon={{
            src: icon.src,
            alt: icon.alt,
          }}
          suffix={suffix}
          renderIcon
          noPadding
          onClick={onClick}
        />
      );
    }

    return (
      <a href="#" onClick={onClick}>
        <h2 className={TcPopperTitle}>{title}</h2>
      </a>
    );
  };

  return (
    <TcPopperWrapperStyled className={TcPopperWrapper + (show ? 'active' : '')}>
      <RenderContent />
      <TcPopperContentStyled className="popover__content">{children}</TcPopperContentStyled>
    </TcPopperWrapperStyled>
  );
});

TcPopper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  show: PropTypes.bool,
  onClick: PropTypes.func,
  isButton: PropTypes.bool,
  icon: PropTypes.object,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TcPopper.defaultProps = {
  title: '',
  show: false,
  isButton: true,
  suffix: '',
};

export default TcPopper;
