import { createMuiTheme } from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';

yellow['500'] = '#E48B01';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: yellow,
  },
});

export default defaultMaterialTheme;
