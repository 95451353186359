import { userTaskAPI } from 'configs';
import { handleAsync } from 'utils';

const getUserTaskList = async (parameters) => {
  const [res, error] = await handleAsync(userTaskAPI.getUserTaskList(parameters));
  if (error) throw error;
  return res;
};

const getUserTaskItems = async (parameters) => {
  const [res, error] = await handleAsync(userTaskAPI.getUserTaskItems(parameters));
  if (error) throw error;
  return res;
};

const unassignTaggers = async (parameter, payload) => {
  const [res, error] = await handleAsync(userTaskAPI.unassignTaggers(parameter, payload));
  if (error) throw error;
  return res;
};

const unassignAllTaggers = async (parameter) => {
  const [res, error] = await handleAsync(userTaskAPI.unassignAllTaggers(parameter));
  if (error) throw error;
  return res;
};

const removeJobBoardTask = async (parameters) => {
  const [res, error] = await handleAsync(userTaskAPI.removeJobBoardTask(parameters));
  if (error) throw error;
  return res;
};

const UserTaskService = {
  getUserTaskList,
  getUserTaskItems,
  unassignTaggers,
  unassignAllTaggers,
  removeJobBoardTask,
};

export { UserTaskService };
