import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const StyledTcSvgIcon = styled.svg.attrs(({ className }) => ({
  viewBox: '0 0 13 13',
  className,
}))`
  width: ${({ width }) => width};
  ${({ color }) => {
    return css`
      color: ${color};
    `;
  }};
`;

// Ensure the SVG's fill or stroke (if its outlined) is using "currentColor" value!
const TcSvgIcon = ({ id, width, src, color, className }) => {
  return (
    <StyledTcSvgIcon width={width} color={color} className={className}>
      <use href={src + '#' + id} />
    </StyledTcSvgIcon>
  );
};

TcSvgIcon.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  // This color is preferred instead of the color from className
  color: PropTypes.string,
};

TcSvgIcon.defaultProps = {
  width: '20px',
  className: '',
};

export default memo(TcSvgIcon);
