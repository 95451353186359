import {
  CHANGE_USER_LIST_PAGE,
  CHANGE_USER_LIST_PER_PAGE,
  CHANGE_USER_LIST_TAB,
  CHANGE_USER_LIST_SEARCH,
} from 'store/actions';
import { INITIAL_STATE } from './defaultUserListPaginationState';

const userListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_USER_LIST_PAGE:
      return { ...state, pageObject: action.payload };
    case CHANGE_USER_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_USER_LIST_TAB:
      return { ...state, tabs: action.payload };
    case CHANGE_USER_LIST_SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export default userListPaginationReducer;
