import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class LogService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(parameters) {
    const { userType, payload } = parameters;
    try {
      const queryParameters = userType === 'admin' ? buildQueryParams(payload) : buildQueryParams(payload);
      const endpoint = userType === 'admin' ? endpoints.LOG_LIST_ADMIN : endpoints.LOG_LIST_USER;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getQueryOptions() {
    try {
      const url = `${endpoints.LOG_QUERY_OPTIONS}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createLog(parameters, body) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.LOG_CREATE_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, body);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateLog(parameters, body) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.LOG_UPDATE_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, body);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async archiveLog(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.LOG_ARCHIVE_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadLogImage(parameters, body, config) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.LOG_IMAGE_UPLOAD}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, body, config);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getLogDetailAdmin(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.LOG_GET_DETAIL_ADMIN;

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getLogDetailClient(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.LOG_GET_DETAIL_CLIENT;

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
