export default class HttpService {
  constructor(httpClient) {
    this._httpClient = httpClient;
  }

  get(url, parameters = {}, responseType = 'json') {
    try {
      return this._httpClient.request({
        method: 'get',
        url,
        params: parameters,
        responseType,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  post(url, data, config = {}) {
    try {
      return this._httpClient.request({
        method: 'post',
        url,
        data,
        ...config,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  patch(url, data) {
    try {
      return this._httpClient.request({
        method: 'patch',
        url,
        data,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  put(url, data, conifg = {}) {
    try {
      return this._httpClient.request({
        method: 'put',
        url,
        data,
        ...conifg,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  delete(url, data = null) {
    try {
      return this._httpClient.request({
        method: 'delete',
        url,
        data,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
