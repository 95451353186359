export const BOUNDING_SHAPE_PROPERTIES = {
  fill: 'rgba(108, 194, 228, 0.4)',
  stroke: 'rgb(22, 164, 62)',
  strokeWidth: 3,
  dash: [10, 10],
  strokeScaleEnabled: false,
  perfectDrawEnabled: false,
};

export const SHAPE_CORNER_POINT_PROPERTIES = {
  fill: 'white',
  strokeWidth: 3,
  strokeScaleEnabled: false,
  perfectDrawEnabled: false,
};

// Based on the mobile's polygon color choices
export const COLOR_PICKER_OPTIONS = [
  {
    stroke: BOUNDING_SHAPE_PROPERTIES.stroke,
    fill: BOUNDING_SHAPE_PROPERTIES.fill,
    name: 'Default',
  },
  {
    stroke: 'rgb(255, 255, 255)',
    fill: 'rgba(255, 255, 255, 0.4)',
    name: 'White',
  },
  {
    stroke: 'rgb(0, 0, 0)',
    fill: 'rgba(0, 0, 0, 0.4)',
    name: 'Black',
  },
  {
    stroke: 'rgb(122, 198, 222)',
    fill: 'rgba(122, 198, 222, 0.4)',
    name: 'Blue',
  },
  {
    stroke: 'rgb(227, 93, 91)',
    fill: 'rgba(227, 93, 91, 0.4)',
    name: 'Red',
  },
  {
    stroke: 'rgb(255, 157, 0)',
    fill: 'rgba(255, 157, 0, 0.4)',
    name: 'Orange',
  },
  {
    stroke: 'rgb(93, 192, 167)',
    fill: 'rgba(93, 192, 167, 0.4)',
    name: 'Green',
  },
];

export const ZOOM = {
  step: 10,
  min: 10,
  max: 300,
  default: 100,
};

export const POINTER = 'pointer';
export const GRAB = 'grab';
export const CROSSHAIR = 'crosshair';
export const LABEL_TOGGLER = 'labelToggler';
