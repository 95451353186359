import { FINISH_STEP, FIRST_STEP, GOTO_STEP, NEXT_STEP, PREV_STEP, SET_DATA } from '../CreateJob/types';

// for job create steps
const resetCurrentStep = () => {
  return { type: FIRST_STEP };
};

const previousStep = () => {
  return { type: PREV_STEP };
};

const nextStep = () => {
  return { type: NEXT_STEP };
};

const finishStep = () => {
  return { type: FINISH_STEP };
};

const gotoStep = (step) => {
  return {
    type: GOTO_STEP,
    payload: step,
  };
};

const setData = (data) => {
  return {
    type: SET_DATA,
    payload: data,
  };
};

const JobsActions = {
  resetCurrentStep,
  prevStep: previousStep,
  nextStep,
  finishStep,
  gotoStep,
  setData,
};

export { JobsActions };
