import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const StyledTcSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 18,
    padding: 0,
    opacity: (props) => (props.disabled ? 0.5 : 1),
  },
  switchBase: {
    padding: 1,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '&$checked $thumb': {
      color: 'white',
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
    color: theme.palette.grey[500],
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const TcSwitch = ({ name, checked, onChange, label, disabled, ...others }) => {
  return label ? (
    <FormControlLabel
      control={
        <StyledTcSwitch
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          {...others}
          disableRipple
          color="primary"
        />
      }
      label={label}
    />
  ) : (
    <StyledTcSwitch name={name} disabled={disabled} checked={checked} onChange={onChange} disableRipple {...others} />
  );
};

TcSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TcSwitch;
