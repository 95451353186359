import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class SubJobService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getSubJobList(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.SUB_JOB_LIST;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSubJobDetail(parameter) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.SUB_JOB_DETAIL;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSubJobTaggers(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.SUB_JOB_TAGGERS;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async assignTaggers(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.SUB_JOB_ASSIGN;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async bulkAssignTaggers(parameter, payload) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.SUB_JOB_BULK_ASSIGN;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getTotalItemsAvailable(parameter) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.SUB_JOB_TOTAL_ITEMS_AVAILABLE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async unassignTaggers(parameter, payload) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.SUB_JOB_UNASSIGN;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async unassignAllTaggers(parameter) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.SUB_JOB_UNASSIGN_ALL;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
