import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class CashPrizeService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.GET_CASH_PRIZE_REDEMPTION;

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDetail(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.GET_CASH_PRIZE_REDEMPTION_DETAIL;

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async approveRedemptionRequest(payload) {
    try {
      const endpoint = endpoints.APPROVE_CASH_PRIZE_REDEMPTION;
      const url = `${endpoint}`;
      const { data } = await this.HTTP.post(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async rejectRedemptionRequest(payload) {
    try {
      const endpoint = endpoints.REJECT_CASH_PRIZE_REDEMPTION;
      const url = `${endpoint}`;
      const { data } = await this.HTTP.post(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
