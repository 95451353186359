import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcSkeletonBarStyled = styled.div.attrs({
  className: 'hexagon',
})`
  &.hexagon {
    position: relative;
    width: 95px;
    height: 54.85px;
    background-color: #e5e5e5;
    margin: auto;
    margin-bottom: 50px;
  }

  &.hexagon:before,
  &.hexagon:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 47.5px solid transparent;
    border-right: 47.5px solid transparent;
  }

  &.hexagon:before {
    bottom: 100%;
    border-bottom: 27.42px solid #e5e5e5;
  }

  &.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 27.42px solid #e5e5e5;
  }
`;

const TcSkeletonBarHexagon = ({ active, children }) => {
  return <>{active ? <TcSkeletonBarStyled /> : children}</>;
};

TcSkeletonBarHexagon.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

TcSkeletonBarHexagon.defaultProps = {
  active: true,
};

export default TcSkeletonBarHexagon;
