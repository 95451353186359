import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { captureTagIcon } from 'assets';
import { TcAvatar, TcButton, TcLabel } from 'common/components';
import { UserService } from 'services';

const TcDropZoneStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #dedede;
  border-radius: 10px;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const TcDropZoneAvatar = (props) => {
  const { setProfileUpdated, setEditMode, setUserDetails } = props;

  const maxFileSize = 5242880;
  const [title, setTitle] = useState('');
  const [imgSource, setImgSource] = useState(captureTagIcon);
  const [noClick, setNoClick] = useState(false);
  const [newPicture, setNewPicture] = useState(0);

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    // error message if files rejected?

    const uploadedImage = acceptedFiles[0];

    setNewPicture(uploadedImage);
    setTitle(uploadedImage.name);
    setNoClick(true);

    const reader = new window.FileReader();
    reader.addEventListener(
      'load',
      () => {
        setImgSource(reader.result);
      },
      false,
    );
    if (acceptedFiles && acceptedFiles.length > 0) {
      reader.readAsDataURL(uploadedImage);
    }
  }, []);

  const handleSubmitPicture = async () => {
    await UserService.setProfilePicture({
      image: newPicture,
    });
    const response = await UserService.getProfile();
    setUserDetails(response);
    setEditMode(false);
    setProfileUpdated(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: maxFileSize,
    multiple: false,
    noClick: noClick,
    onDrop,
  });

  return (
    <TcDropZoneStyled {...getRootProps({ refKey: 'innerRef' })}>
      <input {...getInputProps()} />

      <TcLabel title={isDragActive ? 'Drop Image Here' : 'Upload Image Here'} />

      <div className="tw-pb-6">
        <TcAvatar>
          <img alt="User Avatar" src={imgSource} />
        </TcAvatar>
      </div>

      {title}

      {title === '' ? (
        <TcButton
          className="tw-mb-2 tw-rounded"
          label="Add Image"
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      ) : (
        <TcButton
          className="tw-mb-2 tw-rounded"
          label="Save Image"
          onClick={(e) => {
            handleSubmitPicture();
          }}
        />
      )}
    </TcDropZoneStyled>
  );
};

TcDropZoneAvatar.propTypes = {
  setProfileUpdated: PropTypes.func,
  setEditMode: PropTypes.func,
  setUserDetails: PropTypes.func,
};

export default TcDropZoneAvatar;
