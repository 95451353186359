import { CHANGE_LOG_LIST_PAGE, CHANGE_LOG_LIST_PER_PAGE, CHANGE_LOG_LIST_TYPE } from 'store/actions';
import { INITIAL_STATE } from './defaultLogListPaginationState';

const logListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOG_LIST_PAGE:
      return { ...state, page: action.payload };
    case CHANGE_LOG_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_LOG_LIST_TYPE:
      return { ...state, type: action.payload };
    default:
      return state;
  }
};

export default logListPaginationReducer;
