import PropTypes from 'prop-types';
import React from 'react';

const TcHeaderStyleClass = `
  tw-text-2xl
  tw-font-bold
  tw-capitalize
`;

const TcHeaderWrapperStyleClass = `
  tw-flex
  tw-items-center
  tw-justify-between
`;

const TcHeader = (props) => {
  const { children } = props;
  const Children = React.Children.toArray(children);

  const RenderTitle = () => {
    return React.Children.map(Children, (item) => {
      if (item.type !== Title) return;

      return <h1 className={TcHeaderStyleClass}>{item.props.children}</h1>;
    });
  };

  const RenderSide = () => {
    return React.Children.map(Children, (item) => {
      if (item.type !== Side) return;

      return item.props.children;
    });
  };

  return (
    <div className={TcHeaderWrapperStyleClass}>
      <RenderTitle />
      <div>
        <RenderSide />
      </div>
    </div>
  );
};

TcHeader.propTypes = {
  children: PropTypes.node,
};

const Title = ({ children }) => {
  return <div>{children}</div>;
};

Title.propTypes = {
  children: PropTypes.node,
};

const Side = ({ children }) => {
  return <div>{children}</div>;
};

Side.propTypes = {
  children: PropTypes.node,
};

TcHeader.Title = Title;
TcHeader.Side = Side;

export default TcHeader;
