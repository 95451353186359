export const QcTaggingStatus = {
  RejectTagging: 'RejectTagging',
  AcceptTagging: 'AcceptTagging',
};

export const QcTaggingStatusDisplay = {
  [QcTaggingStatus.AcceptTagging]: 'Accepted',
  [QcTaggingStatus.RejectTagging]: 'Rejected',
};

export const statusFilterOptions = [
  { key: 'all', text: 'All Status' },
  { key: 'AcceptTagging', text: 'Accepted' },
  { key: 'RejectTagging', text: 'Rejected' },
];

export const ListItemTab = {
  NotReviewed: 'Not Reviewed',
  Reviewed: 'Reviewed',
};

export const PAGINATION_LIST_PER_PAGE = 20;
export const PAGINATION_INTERVAL = 3;

export const zoomOptions = [
  { key: 'fit', text: 'Fit to Canvas' },
  { key: '25', text: '25%' },
  { key: '50', text: '50%' },
  { key: '75', text: '75%' },
  { key: '100', text: '100%' },
  { key: '200', text: '200%' },
];
