import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import styled from 'styled-components';

const StyledAutoComplete = styled(Autocomplete)`
  & .MuiOutlinedInput-root {
    padding: 0 !important;
    border-radius: 5px;
  }
  & .MuiOutlinedInput-input {
    padding: 7px 14px !important;
    height: 24px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e4e4e4 !important;
  }
  background-color: ${({ disabled }) => (disabled ? '#f3f3f3' : '#fff')};
`;

const TcAutocomplete = ({
  name,
  options,
  optionLabel,
  onChange,
  onSelect,
  loading,
  error,
  helperText,
  placeHolder,
  disabled,
  value,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <FormControl fullWidth={true} error={error}>
      <StyledAutoComplete
        ListboxProps={{ style: { maxHeight: '10rem' } }}
        id={name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={disabled}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionSelected={(option, value) => option[optionLabel] === value[optionLabel]}
        options={options}
        loading={loading}
        value={value}
        onChange={(_, newValue) => onSelect(newValue)}
        renderInput={(parameters) => (
          <TextField
            {...parameters}
            variant="outlined"
            onChange={onChange}
            placeholder={placeHolder}
            InputProps={{
              ...parameters.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {parameters.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...props}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

TcAutocomplete.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
};

export default memo(TcAutocomplete);
