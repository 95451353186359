import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { useFeatureFlag } from 'utils';

const ClientRoute = (props) => {
  const { path, isLogin, featureFlagGuard } = props;
  const isFlagOn = useFeatureFlag(featureFlagGuard);

  if (isFlagOn) {
    return <Redirect to="/" />;
  }

  if (!isLogin) {
    return <Redirect to="/auth" />;
  } else if (isLogin && path === '/client/*') {
    return <Redirect to="/client/job-list" />;
  } else {
    return <Route {...props} />;
  }
};

ClientRoute.propTypes = {
  isLogin: PropTypes.bool.isRequired,
};

const mapStateToProperties = (state) => {
  return {
    isLogin: state.auth.isLogin,
  };
};

export default connect(mapStateToProperties)(ClientRoute);
