import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TcLogo } from 'common/components';
import JobCreateSidebar from './JobCreateSidebar';
import MainAdminSidebar from './MainAdminSidebar';
import MainSidebar from './MainSidebar';

const TcSidebarWrapperStyled = styled.div`
  width: 280px;
  border-right: 1px solid var(--color-gray-300);
`;

const TcSidebarWrapperClass = `
  tw-fixed
  tw-h-screen
  tw-bg-white
`;

const TcSidebar = (props) => {
  const RenderSidebarSwitcher = () => {
    switch (props.sidebarType) {
      case 'GENERAL_ADMIN':
        return <MainAdminSidebar />;
      case 'JOB_CREATE':
        return <JobCreateSidebar isQcJobCreation={false} />;
      case 'QC_JOB_CREATE':
        return <JobCreateSidebar isQcJobCreation={true} />;
      case 'GENERAL':
        return <MainSidebar profileUpdated={props.profileUpdated} />;
      default:
        return 'Loading...';
    }
  };

  return (
    <TcSidebarWrapperStyled className={TcSidebarWrapperClass}>
      <TcLogo />
      <RenderSidebarSwitcher />
    </TcSidebarWrapperStyled>
  );
};

TcSidebar.propTypes = {
  sidebarType: PropTypes.string,
  profileUpdated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    sidebarType: state.sidebar.sidebarType,
  };
};

export default connect(mapStateToProps)(TcSidebar);
