import Joi from '@hapi/joi';
import { yupResolver } from '@hookform/resolvers';
import axios from 'axios';
import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import _ from 'lodash';
import * as yup from 'yup';

const localStorage = window.localStorage;

export * from './i18n';
export { _, axios, Cookie, dayjs, yup, yupResolver, Joi, localStorage };
