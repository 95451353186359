import {
  // bankIcon,
  // bankIconActive,
  briefcaseIcon,
  briefcaseIconActive,
  newspaperIcon,
  newspaperIconActive,
  logoutIcon,
} from 'assets';

const MENU = [
  {
    icon: briefcaseIcon,
    iconActive: briefcaseIconActive,
    title: 'Jobs',
    link: '/client/job-list',
  },
  // {
  //   icon: bankIcon,
  //   iconActive: bankIconActive,
  //   title: 'Payments',
  //   link: 'payment-list'
  // },
  {
    icon: newspaperIcon,
    iconActive: newspaperIconActive,
    title: 'Logs',
    link: '/client/log-list',
  },
  {
    icon: logoutIcon,
    iconActive: logoutIcon,
    title: 'Log Out',
    link: 'logout',
  },
];

export { MENU };
