import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { TcIcon } from 'common/components';

const TcRadioCard = styled.div.attrs({
  className: `
    tw-relative
    tw-rounded
    tw-border-2
    tw-flex
    tw-items-center
    tw-pl-12
    tw-pr-6
    tw-py-4
    tw-font-bold
    tw-text-gray-1600
    tw-cursor-pointer
  `,
})`
  width: ${({ full }) => (full ? '100%' : 'unset')};
  justify-content: ${({ center }) => (center ? 'space-between' : 'flex-start')};
  ${({ active }) =>
    active &&
    css`
      border-color: var(--color-orange-400);
      color: var(--color-orange-400);
    `}
  &:after {
    content: '';
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translate(0, -50%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ active }) => (active ? 'var(--color-orange-400)' : 'none')};
  }
  &:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
    border: 1px solid ${({ active }) => (active ? 'var(--color-orange-400)' : 'var(--color-gray-800)')};
    border-radius: 50%;
  }
`;

const TcRadioIcon = styled.div`
  padding: 10px;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-gray-1200);
  border-radius: 0.25rem;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      border-color: var(--color-orange-400);
      color: var(--color-orange-400);
    `}
  & img {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

const TcRadio = ({ type, model, name, label, change, icon, full, center }) => {
  let active = model === name;
  useEffect(() => {
    active = model === name;
  }, [model]);

  const RenderIcon = () => {
    if (!icon) return null;
    return <TcIcon active={active} src={icon} alt="icon" width="25px" />;
  };

  const RenderTcRadioCard = () => {
    return (
      <TcRadioCard full={full} center={center} active={active} onClick={changeModel}>
        {label}
        <RenderIcon />
      </TcRadioCard>
    );
  };

  const RenderTcRadioIcon = () => {
    return (
      <TcRadioIcon center={center} active={active} onClick={changeModel}>
        <RenderIcon />
      </TcRadioIcon>
    );
  };

  const Render = () => {
    switch (type) {
      case 'card':
        return <RenderTcRadioCard />;
      case 'icon':
        return <RenderTcRadioIcon />;
      default:
        return null;
    }
  };

  const changeModel = () => {
    change(name);
  };

  return <Render />;
};

TcRadio.propTypes = {
  type: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  change: PropTypes.any,
  icon: PropTypes.string,
  full: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
};

TcRadio.defaultProps = {
  type: 'card',
};

export default TcRadio;
