import { coinAPI } from 'configs';
import { handleAsync } from 'utils';

const getTaggers = async (parameters) => {
  const [res, error] = await handleAsync(coinAPI.getTaggers(parameters));
  if (error) throw error;
  return res;
};

const approve = async (parameters, payload) => {
  const [res, error] = await handleAsync(coinAPI.approve(parameters, payload));
  if (error) throw error;
  return res;
};

const reject = async (parameters, payload) => {
  const [res, error] = await handleAsync(coinAPI.reject(parameters, payload));
  if (error) throw error;
  return res;
};

const calculate = async (payload) => {
  const [res, error] = await handleAsync(coinAPI.calculate(payload));
  if (error) throw error;
  return res;
};

const getQueryOptions = async () => {
  const [res, error] = await handleAsync(coinAPI.getQueryOptions());
  if (error) throw error;
  return res;
};

const increase = async (payload) => {
  const [res, error] = await handleAsync(coinAPI.increase(payload));
  if (error) throw error;
  return res;
};

const increaseAll = async (payload) => {
  const [res, error] = await handleAsync(coinAPI.increaseAll(payload));
  if (error) throw error;
  return res;
};

const decrease = async (payload) => {
  const [res, error] = await handleAsync(coinAPI.decrease(payload));
  if (error) throw error;
  return res;
};

const decreaseAll = async (payload) => {
  const [res, error] = await handleAsync(coinAPI.decreaseAll(payload));
  if (error) throw error;
  return res;
};

const CoinService = {
  getTaggers,
  approve,
  reject,
  calculate,
  getQueryOptions,
  increase,
  increaseAll,
  decrease,
  decreaseAll,
};
export { CoinService };
