import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { infoCircle, infoCircleYellow, warningCircleRed } from 'assets';
import { TcIcon } from 'common/components';

const renderColor = (type) => {
  switch (type) {
    case 'danger':
      return 'var(--color-red-500)';
    case 'warning':
      return 'var(--color-yellow-500)';
    default:
      return 'var(--color-blue-400)';
  }
};

const StyledNotification = styled.div`
  ${({ type }) => `
  color:${renderColor(type)};
  & div {
    color:${renderColor(type)}
  }`}
`;

const NotifType = {
  danger: 'danger',
  warning: 'warning',
  default: 'default',
};

const TcNotification = (props) => {
  const { message, type = NotifType.default, iconContainerClassName } = props;

  const iconSize = 24;

  const renderIcon = (type) => {
    switch (type) {
      case NotifType.danger:
        return warningCircleRed;
      case NotifType.warning:
        return infoCircleYellow;
      default:
        return infoCircle;
    }
  };

  return (
    <StyledNotification type={type}>
      <div className="tw-table-row">
        <div className={iconContainerClassName ?? 'tw-table-cell tw-align-middle tw-pr-10'}>
          <TcIcon width={iconSize + (type === NotifType.danger ? -4 : 0)} src={renderIcon(type)} alt="infoCircle" />
        </div>
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
          }}
        >
          {message}
        </div>
      </div>
    </StyledNotification>
  );
};

TcNotification.propTypes = {
  message: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(NotifType)),
};

export default TcNotification;
