import { PropTypes } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { greenCheckIcon } from 'assets';
import {
  TcTable,
  TcSection,
  TcLabel,
  TcSearchBar,
  TcSelect,
  TcSkeletonBarLoading,
  TcPagination,
  TcHeader,
  TcTabs,
  TcRow,
  TcGrid,
  TcLink,
  TcCheckbox,
} from 'common/components';
import TcEmptyList from 'common/components/TcEmptyList';
import { allReferrals, pendingReferrals, completedReferrals } from 'common/constants/global';
import { ReferralService } from 'services/ReferralService';
import { ReferralRecordListPaginationActions, addToast } from 'store/actions';
import { handleAsync } from 'utils';
import { useTranslation, dayjs } from 'utils/libraries';
import ConfigAwards from './components/ConfigRewards';
import { initTableHeadData, perPageOptions } from './constants';

const ReferralRecordListPageFrame = styled.div`
  width: 100%;
  height: 100vh;
`;

const ReferralRecordWrapperClass = `
  tw-h-full
  tw-flex
  tw-justify-center
  tw-p-8
`;

const ReferralRecordList = ({
  auth,
  currentPage,
  perPage,
  tabs,
  search,
  isCampaignsOnly,
  changeReferralsListPage,
  changeReferralsListPerPage,
  changeReferralsListTab,
  changeReferralsListSearch,
  changeReferralsListIsCampaignsOnly,
  addToast,
  ...other
}) => {
  const { t } = useTranslation();

  const [isListLoading, setListLoading] = useState(false);
  const [referralRecordList, setReferralRecordList] = useState([]);
  const [referralRecordPaginate, setReferralRecordPaginate] = useState();
  const pageOne = { [allReferrals]: 1, [pendingReferrals]: 1, [completedReferrals]: 1 };

  useEffect(() => {
    const getReferralRecordsList = async () => {
      const parameters = {
        paginate: true,
        limit: perPage,
        page: currentPage[tabs],
        searchBy: search,
        status: tabs === 'all' ? [pendingReferrals, completedReferrals] : [tabs],
        campaign: isCampaignsOnly,
      };

      setListLoading(true);

      const [res, error] = await handleAsync(ReferralService.getReferralRecords(parameters));

      setListLoading(false);

      if (error) {
        addToastMessage(`${error?.error?.message || 'Unknown error while loading page'}`, 'error', 2500);
        setReferralRecordList([]);
        return;
      }

      if (res?.data?.length === 0) {
        setReferralRecordList([]);
        changeReferralsListPage(pageOne);
        return;
      }

      setReferralRecordList(res.data);
      setReferralRecordPaginate(res.paginate);
    };

    getReferralRecordsList();
  }, [currentPage[tabs], tabs, perPage, search, isCampaignsOnly]);

  const addToastMessage = (statusCardMessage, statusCardType, closeTiming) => {
    addToast({
      message: statusCardMessage,
      styleType: statusCardType,
      closeTiming: closeTiming,
    });
  };

  const RenderPanes = () => {
    const statusReferrals = [
      { key: 'all', text: 'All' },
      { key: 'pending', text: 'Pending' },
      { key: 'completed', text: 'Completed' },
    ];

    return statusReferrals.map((item) => (
      <TcTabs.Pane key={item.key} name={item.key} label={t(item.text)}>
        <RenderTable />
      </TcTabs.Pane>
    ));
  };

  const RenderTable = () => {
    return referralRecordList?.length !== 0 ? (
      <TcTable headers={RenderTableHead()} rows={RenderTableBody()} rowHoverEffect />
    ) : (
      <TcEmptyList />
    );
  };

  const RenderTableHead = () => {
    if (referralRecordList?.length === 0 && !isListLoading) {
      return [{}];
    }

    return initTableHeadData;
  };

  const RenderTableBody = () => {
    const element = [];

    const approvedTasksList = (data) => {
      const completedTaskList = data?.tasks_approved || [];
      if (!completedTaskList && completedTaskList.length === 0) return null;

      return completedTaskList.map((task, index) => {
        const url = `user-detail/${data?.referee?.id}/task-detail/${task}`;
        return (
          <TcRow className="tw-flex tw-py-1" key={index}>
            <img src={greenCheckIcon} className="tw-pr-1 tw-pb-1" />
            <TcLink
              color="var(--color-black)"
              className="tw-font-extrabold tw-underline hover:tw-text-orange-700"
              title={`Task ${index + 1}`}
              location={url}
            />
          </TcRow>
        );
      });
    };

    if (isListLoading) {
      const columnLength = initTableHeadData.length + 1;
      const rowLength = perPage;
      const row = [];
      for (let index = 0; index < columnLength; index++) row.push({ label: <TcSkeletonBarLoading active /> });
      for (let index = 0; index < rowLength; index++) element.push(row);
    } else {
      referralRecordList.forEach((data) => {
        const row = [
          // Date Referred
          {
            label: data?.created_at ? `${dayjs(data?.created_at).format('DD MMMM YYYY, hh:mm A')}` : '-',
            align: 'left',
          },
          // Referrer
          {
            label: (
              <>
                {data?.referrer ? (
                  <TcLink
                    color="var(--color-black)"
                    className="tw-font-extrabold tw-underline hover:tw-text-orange-700"
                    title={data?.referrer?.username}
                    location={`user-detail/${data?.referrer?.id}`}
                  />
                ) : (
                  '-'
                )}
                <br />
              </>
            ),
            align: 'left',
            secondary: data?.referrer?.email,
          },
          // Referee
          {
            label: (
              <>
                {data?.referee ? (
                  <TcLink
                    color="var(--color-black)"
                    className="tw-font-extrabold tw-underline hover:tw-text-orange-700"
                    title={data?.referee?.username}
                    location={`user-detail/${data?.referee?.id}`}
                  />
                ) : (
                  '-'
                )}
                <br />
              </>
            ),
            align: 'left',
            secondary: data?.referee?.email,
          },
          // Reward Amount
          {
            label: data?.referral?.referee_reward_coins ?? '-',
            align: 'left',
          },
          // Minimum Tasks
          {
            label: data?.referral?.minimum_approved_tasks ?? '-',
            align: 'left',
          },
          // Tasks
          {
            label: approvedTasksList(data) ?? '-',
            align: 'left',
          },
        ];

        element.push(row);
      });
    }
    return element;
  };

  const RenderPaginate = () => {
    const handleCallback = (page) => {
      const newState = { ...currentPage };
      newState[tabs] = page;
      changeReferralsListPage(newState);
    };

    const handlePerPageChange = (event) => {
      changeReferralsListPerPage(event.target.value);
    };

    if (referralRecordPaginate) {
      return (
        <>
          <TcLabel title="Items Per Page: " className="tw-mr-2 tw-flex tw-self-center" />
          <TcSelect
            className="tw-mr-4 tw-my-2"
            value={perPage}
            onChange={handlePerPageChange}
            options={perPageOptions}
          />
          <TcPagination
            paginate={referralRecordPaginate}
            activePage={currentPage[tabs]}
            intervalPage={3}
            handleCallback={handleCallback}
          />
        </>
      );
    }

    return null;
  };

  const handleSearch = (searchQuery) => {
    changeReferralsListPage(pageOne);
    changeReferralsListSearch(searchQuery);
  };

  return (
    <div className={ReferralRecordWrapperClass}>
      <ReferralRecordListPageFrame>
        <TcHeader>
          <TcHeader.Title>{t('label.page.referralRecordList.title')}</TcHeader.Title>
        </TcHeader>

        <ConfigAwards
          addToastMessage={(statusCardMessage, statusCardType, closeTiming) =>
            addToastMessage(statusCardMessage, statusCardType, closeTiming)
          }
        />

        <TcSection className="tw-py-6">
          <TcHeader>
            <TcHeader.Title>{t('label.page.referralRecordList.header')}</TcHeader.Title>
            <TcHeader.Side>
              <TcGrid className="tw-grid tw-grid-cols-3 tw-pl-4">
                <TcCheckbox
                  type="label"
                  label="Show Campaigns only"
                  checked={isCampaignsOnly}
                  setChecked={(e) => {
                    changeReferralsListIsCampaignsOnly(e);
                    changeReferralsListPage(pageOne);
                  }}
                />
                <div className="tw-col-span-2">
                  <TcSearchBar
                    placeholder={t('label.page.referralRecordList.placeholder.search')}
                    initialValue={search}
                    handleSearch={handleSearch}
                  />
                </div>
              </TcGrid>
            </TcHeader.Side>
          </TcHeader>

          <div className="tw-pt-5">
            <TcTabs
              activeName={tabs}
              onTabClick={(tab) => {
                changeReferralsListTab(tab);
                changeReferralsListPage(pageOne);
              }}
            >
              {RenderPanes()}
            </TcTabs>
          </div>
        </TcSection>

        <div
          className="
            tw-float-right
            tw-inline-block
            tw-flex
            tw-content-center
            tw-py-4
          "
        >
          <RenderPaginate />
        </div>
      </ReferralRecordListPageFrame>
    </div>
  );
};

ReferralRecordList.propTypes = {
  auth: PropTypes.object.isRequired,
  currentPage: PropTypes.object,
  perPage: PropTypes.string,
  tabs: PropTypes.string,
  search: PropTypes.string,
  isCampaignsOnly: PropTypes.bool,
  changeReferralsListPage: PropTypes.func,
  changeReferralsListPerPage: PropTypes.func,
  changeReferralsListSearch: PropTypes.func,
  changeReferralsListTab: PropTypes.func,
  changeReferralsListIsCampaignsOnly: PropTypes.func,
  addToast: PropTypes.func,
};

const mapStateToProperties = (state) => {
  return {
    auth: state.auth,
    currentPage: state.pagination.referralRecordListPagination.pageObject,
    tabs: state.pagination.referralRecordListPagination.tab,
    perPage: state.pagination.referralRecordListPagination.perPage,
    search: state.pagination.referralRecordListPagination.search,
    isCampaignsOnly: state.pagination.referralRecordListPagination.isCampaignsOnly,
  };
};

export default connect(mapStateToProperties, {
  changeReferralsListPage: ReferralRecordListPaginationActions.changeReferralsListPage,
  changeReferralsListPerPage: ReferralRecordListPaginationActions.changeReferralsListPerPage,
  changeReferralsListTab: ReferralRecordListPaginationActions.changeReferralsListTab,
  changeReferralsListSearch: ReferralRecordListPaginationActions.changeReferralsListSearch,
  changeReferralsListIsCampaignsOnly: ReferralRecordListPaginationActions.changeReferralsListIsCampaignsOnly,
  addToast,
})(memo(ReferralRecordList));
