import { lazy } from 'react';

const Auth = lazy(() => import('./Auth'));
const Forgot = lazy(() => import('./Forgot'));
export * from './NotFound';
export * from './Jobs';
export * from './SubJobs';
export * from './Users';
export * from './Badges';
export * from './Logs';
export * from './CoinAdjustment';
export * from './CashPrize';
export * from './Leaderboard';
export * from './PreTaskScreening';
export * from './InviteCode';
export * from './QaMechanism';
export * from './TaskQuestionnaire';

export { Auth, Forgot };
