import PropTypes from 'prop-types';
import React from 'react';

const TcImageClass = ({ center }) => `
  tw-flex
  tw-items-center
  tw-text-center
  tw-p-8
  tw-text-black
  ${center ? 'tw-justify-center' : ''}
`;

const TcImage = (props) => {
  const { center, src, alt, className } = props;

  return (
    <div className={TcImageClass({ center }) + className}>
      <img src={src} alt={alt} />
    </div>
  );
};

TcImage.propTypes = {
  center: PropTypes.bool,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TcImage.defaultProps = {
  center: false,
  className: '',
};

export default TcImage;
