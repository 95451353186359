const CHANGE_JOB_LIST_PAGE = 'CHANGE_JOB_LIST_PAGE';
const CHANGE_JOB_LIST_TAB = 'CHANGE_JOB_LIST_TAB';
const CHANGE_JOB_LIST_PER_PAGE = 'CHANGE_JOB_LIST_PER_PAGE';
const CHANGE_JOB_LIST_FILTER = 'CHANGE_JOB_LIST_FILTER';
const CHANGE_JOB_LIST_SEARCH = 'CHANGE_JOB_LIST_SEARCH';
const CHANGE_USER_LIST_PAGE = 'CHANGE_USER_LIST_PAGE';
const CHANGE_USER_LIST_PER_PAGE = 'CHANGE_USER_LIST_PER_PAGE';
const CHANGE_USER_LIST_TAB = 'CHANGE_USER_LIST_TAB';
const CHANGE_USER_LIST_SEARCH = 'CHANGE_USER_LIST_SEARCH';
const CHANGE_USER_TASK_LIST_PAGE = 'CHANGE_USER_TASK_LIST_PAGE';
const CHANGE_USER_TASK_LIST_TAB = 'CHANGE_USER_TASK_LIST_TAB';
const CHANGE_USER_TASK_LIST_PER_PAGE = 'CHANGE_USER_TASK_LIST_PER_PAGE';
const CHANGE_COIN_TAGGER_LIST_PAGE = 'CHANGE_COIN_TAGGER_LIST_PAGE';
const CHANGE_COIN_TAGGER_LIST_PER_PAGE = 'CHANGE_COIN_TAGGER_LIST_PER_PAGE';
const CHANGE_COIN_TAGGER_LIST_SEARCH = 'CHANGE_COIN_TAGGER_LIST_SEARCH';
const CHANGE_BADGES_LIST_PAGE = 'CHANGE_BADGES_LIST_PAGE';
const CHANGE_BADGES_LIST_PER_PAGE = 'CHANGE_BADGES_LIST_PER_PAGE';
const CHANGE_BADGES_LIST_SEARCH = 'CHANGE_BADGES_LIST_SEARCH';
const CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE = 'CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE';
const CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE = 'CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE';
const CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH = 'CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH';
const CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS = 'CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS';
const CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE = 'CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE';
const CHANGE_TAGGING_REVIEW_JOB_LEVEL_LIST_PAGE = 'CHANGE_TAGGING_REVIEW_JOB_LEVEL_LIST_PAGE';
const CHANGE_INVITE_CODES_LIST_PAGE = 'CHANGE_INVITE_CODES_LIST_PAGE';
const CHANGE_INVITE_CODES_LIST_PER_PAGE = 'CHANGE_INVITE_CODES_LIST_PER_PAGE';
const CHANGE_INVITE_CODES_LIST_SEARCH = 'CHANGE_INVITE_CODES_LIST_SEARCH';
const CHANGE_REFERRAL_RECORD_LIST_PAGE = 'CHANGE_REFERRAL_RECORD_LIST_PAGE';
const CHANGE_REFERRAL_RECORD_LIST_PER_PAGE = 'CHANGE_REFERRAL_RECORD_LIST_PER_PAGE';
const CHANGE_REFERRAL_RECORD_LIST_SEARCH = 'CHANGE_REFERRAL_RECORD_LIST_SEARCH';
const CHANGE_REFERRAL_RECORD_LIST_TAB = 'CHANGE_REFERRAL_RECORD_LIST_TAB';
const CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY = 'CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY';
const CHANGE_DELETION_REQUEST_LIST_PAGE = 'CHANGE_DELETION_REQUEST_LIST_PAGE';
const CHANGE_DELETION_REQUEST_LIST_PER_PAGE = 'CHANGE_DELETION_REQUEST_LIST_PER_PAGE';
const CHANGE_DELETION_REQUEST_LIST_SEARCH = 'CHANGE_DELETION_REQUEST_LIST_SEARCH';
const CHANGE_QA_MECHANISM_DECISION_LIST_PAGE = 'CHANGE_DECISION_LIST_PAGE';
const CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE = 'CHANGE_DECISION_LIST_PER_PAGE';
const CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH = 'CHANGE_DECISION_LIST_SEARCH';
const CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE = 'CHANGE_DECISION_LIST_PAGINATE';
const CHANGE_LOG_LIST_PAGE = 'CHANGE_LOG_LIST_PAGE';
const CHANGE_LOG_LIST_PER_PAGE = 'CHANGE_LOG_LIST_PER_PAGE';
const CHANGE_LOG_LIST_TYPE = 'CHANGE_LOG_LIST_TYPE';

export {
  CHANGE_JOB_LIST_PAGE,
  CHANGE_JOB_LIST_TAB,
  CHANGE_JOB_LIST_PER_PAGE,
  CHANGE_JOB_LIST_FILTER,
  CHANGE_JOB_LIST_SEARCH,
  CHANGE_USER_LIST_PAGE,
  CHANGE_USER_LIST_PER_PAGE,
  CHANGE_USER_LIST_TAB,
  CHANGE_USER_LIST_SEARCH,
  CHANGE_USER_TASK_LIST_PAGE,
  CHANGE_USER_TASK_LIST_TAB,
  CHANGE_USER_TASK_LIST_PER_PAGE,
  CHANGE_COIN_TAGGER_LIST_PAGE,
  CHANGE_COIN_TAGGER_LIST_PER_PAGE,
  CHANGE_COIN_TAGGER_LIST_SEARCH,
  CHANGE_BADGES_LIST_PAGE,
  CHANGE_BADGES_LIST_PER_PAGE,
  CHANGE_BADGES_LIST_SEARCH,
  CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH,
  CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS,
  CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE,
  CHANGE_TAGGING_REVIEW_JOB_LEVEL_LIST_PAGE,
  CHANGE_INVITE_CODES_LIST_PAGE,
  CHANGE_INVITE_CODES_LIST_PER_PAGE,
  CHANGE_INVITE_CODES_LIST_SEARCH,
  CHANGE_REFERRAL_RECORD_LIST_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_PER_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_SEARCH,
  CHANGE_REFERRAL_RECORD_LIST_TAB,
  CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY,
  CHANGE_DELETION_REQUEST_LIST_PAGE,
  CHANGE_DELETION_REQUEST_LIST_PER_PAGE,
  CHANGE_DELETION_REQUEST_LIST_SEARCH,
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH,
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE,
  CHANGE_LOG_LIST_PAGE,
  CHANGE_LOG_LIST_PER_PAGE,
  CHANGE_LOG_LIST_TYPE,
};
