// admin endpoints
export const JOB_LIST_ADMIN = '/v1/admin/job/all';
export const JOB_DETAIL_ADMIN = '/v1/admin/job/show';
export const JOB_LIST_QUERY_OPTIONS_ADMIN = '/v1/job/query-options';
export const JOB_DOWNLOAD_FORMAT_ADMIN = '/v1/job/download-format-options';
export const JOB_LIST_TAGGER_ADMIN = '/v1/admin/job/taggers';
export const JOB_DOWNLOAD_ADMIN = '/v1/job/download';
export const JOB_DOWNLOAD_WITH_USERNAME_ADMIN = '/v1/job/download-with-username';
export const JOB_PREPARE_DOWNLOAD = '/v1/job/prepare-download';
export const JOB_PREPARE_DOWNLOAD_STATE = '/v1/job/prepare-download/state';
export const SET_JOB_TAGGER_ADMIN = 'v1/admin/job/assign';
export const JOB_BOARD_ADMIN = 'v1/admin/job-board';
export const JOB_BOARD_ADMIN_ADD_ITEMS = 'v1/admin/job-board/add-items';
export const JOB_BOARD_ADMIN_REMOVE_ITEMS = 'v1/admin/job-board/remove-items';
export const JOB_TASK_ITEMS = 'v1/tasks/job-items';
export const JOB_TASK_ITEMS_ADMIN_REVIEW = 'v1/tasks/job-items/admin-review';
export const JOB_REJECTION_REASONS = 'v1/admin/job/rejection-reasons';
export const JOB_DISABLE_REJECTION_REASONS = 'v1/admin/job/rejection-reasons/disable';
export const JOB_CURRENT_TAGGED_ADMIN = '/v1/admin/job/current-tagged';

// client endpoints
export const JOB_LIST_CLIENT = '/v1/job/all';
export const JOB_DETAIL_CLIENT = '/v1/job/show';
export const JOB_LIST_QUERY_OPTIONS_CLIENT = '/v1/job/query-options';
export const JOB_DOWNLOAD_FORMAT_CLIENT = '/v1/job/download-format-options';
export const JOB_LIST_TAGGER_CLIENT = '​​/v1​/job​/taggers';
export const JOB_DOWNLOAD_CLIENT = '/v1/job/download';
export const SET_JOB_TAGGER_CLIENT = 'v1/job/assign';
export const JOB_TYPES_CLIENT = '/v1/job/types';
export const JOB_CREATE_STEP_ONE_CLIENT = 'v1/job/add';
export const JOB_CREATE_STEP_ONE_PIPELINE_CLIENT = 'v1/job/pipeline/add';
export const JOB_CREATE_STEP_TWO_CLIENT = 'v1/job/details';
export const JOB_CREATE_STEP_THREE_CLIENT = 'v1/job/files';
export const JOB_CREATE_STEP_THREE_AUDIO_SOUNDTRACK = 'v1/job/files/audio/soundtracks';
export const JOB_CREATE_STEP_THREE_AUDIO_TRANSCRIPTION = 'v1/job/files/audio/transcriptions';
export const JOB_CREATE_STEP_THREE_VIDEOS = 'v1/job/files/videos';
export const JOB_CREATE_STEP_THREE_CONFIG_INPUT = 'v1/job/files/config';
export const JOB_CREATE_STEP_THREE_INPUT = 'v1/job/files/input';
export const JOB_CREATE_STEP_THREE_AUDIO_PROCESS = 'v1/job/files/audio/process';
export const JOB_CREATE_STEP_THREE_VIDEO_PROCESS = 'v1/job/files/videos/process';
export const JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_PROCESS = 'v1/job/files/process';
export const JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_PROCESS_STATUS = 'v1/job/files/process-status';
export const JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_CREATE_EMPTY_FILES = 'v1/job/files/static';
export const JOB_CREATE_STEP_FOUR_LABEL_CLIENT = 'v1/job/job-options/labels';
export const JOB_CREATE_STEP_FOUR_BASE_CLIENT = 'v1/job/job-options/base';
export const JOB_CREATE_STEP_FOUR_DECISION_CLIENT = 'v1/job/job-options/decision';
export const JOB_CREATE_STEP_FOUR_TEXT_TAGS_PER_LABEL_CLIENT = 'v1/job/job-options/tags-per-label';
export const JOB_CREATE_STEP_FOUR_RANGE_CLIENT = 'v1/job/job-options/range';
export const JOB_FILES_SAMPLE_IMAGES = 'v1/job/files/sample-images';
export const JOB_CREATE_STEP_FIVE_CLIENT = 'v1/job/payments';
export const JOB_EDIT_SAMPLE_IMAGES = 'v1/job/files/sample-images/edit';

export const JOB_UPDATE_DETAILS = 'v1/job/edit';
export const JOB_CONTENT_FLAG = 'v1/job/content-flag';
export const JOB_COPY_ITEMS_CLIENT = 'v1/job/copy-items';
export const JOB_ARCHIVE = 'v1/job/archive';
export const JOB_UNARCHIVE = 'v1/job/unarchive';

// admin & client endpoints
export const JOB_DOWNLOAD_DATA_FORMAT = '/v1/job/download-data-format-options';
