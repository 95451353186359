import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class ReferralService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getReferralRewards(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_REFERRAL_REWARDS}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateReferralRewards(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_REFERRAL_REWARDS}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getReferralRecords(parameters) {
    try {
      if (!parameters['searchBy']) {
        delete parameters['searchBy'];
      }
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_REFERRAL_RECORDS}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
