import { logTypes } from 'common/constants/global';

const defaultLogListPage = 1;
const defaultLogListPerPage = '10';
const defaultLogListType = logTypes.announcement;

export const INITIAL_STATE = {
  page: defaultLogListPage,
  perPage: defaultLogListPerPage,
  type: defaultLogListType,
};
