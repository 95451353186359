import { authAPI } from 'configs';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS, SIGN_OUT } from 'store/actions';
import { handleAsync } from 'utils';

// sends network request to auth provider
const login =
  (payload = {}) =>
  async (dispatch) => {
    const [res, error] = await handleAsync(authAPI.login(payload));

    if (error) {
      return dispatch({
        type: SIGN_IN_ERROR,
        payload: error.error.message,
      });
    } else {
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: res,
      });
    }
  };

// just updates local state
const logout = () => async (dispatch) => {
  dispatch({ type: SIGN_OUT });
};

// not an action creator(yet)
const register = async (payload = {}) => {
  const [res, error] = await handleAsync(authAPI.register(payload));
  if (error) throw error;
  return res;
};

const AuthService = {
  login,
  register,
  logout,
};

export { AuthService };
