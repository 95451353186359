import PropTypes from 'prop-types';
import React from 'react';
import { TcLayout } from 'common/components';

const TcBlankTemplate = (props) => {
  const { children, bgColor } = props;

  return <TcLayout bgColor={bgColor}>{children}</TcLayout>;
};

TcBlankTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
};

export default TcBlankTemplate;
