const defaultCashPrizeRequestListPage = 1;
const defaultCashPrizeRequestListPerPage = '10';
const defaultCashPrizeRequestListSearch = '';
const defaultCashPrizeRequestListStatus = 'pending';

export const INITIAL_STATE = {
  page: defaultCashPrizeRequestListPage,
  perPage: defaultCashPrizeRequestListPerPage,
  search: defaultCashPrizeRequestListSearch,
  status: defaultCashPrizeRequestListStatus,
};
