import { badgeAPI } from 'configs';
import { handleAsync } from 'utils';

const getBadgeList = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getBadgesList(parameters));
  if (error) throw error;
  return res;
};

const getBadgeDetail = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getBadgeDetail(parameters));
  if (error) throw error;
  return res;
};

const getUsersWithBadge = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getUserWithBadge(parameters));
  if (error) throw error;
  return res;
};

const getUserBadges = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getUserBadges(parameters));
  if (error) throw error;
  return res;
};

const getDefaultBadgeImage = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getDefaultBadgeImage(parameters));
  if (error) throw error;
  return res;
};

const getBadgeStatistics = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.getBadgeStatistics(parameters));
  if (error) throw error;
  return res;
};

const addBadge = async (payload) => {
  const [res, error] = await handleAsync(badgeAPI.addBadge(payload));
  if (error) throw error;
  return res;
};

const updateBadgeDetail = async (parameters, payload) => {
  const [res, error] = await handleAsync(badgeAPI.updateBadgeDetail(parameters, payload));
  if (error) throw error;
  return res;
};

const updateUserBadgeLevel = async (payload) => {
  const [res, error] = await handleAsync(badgeAPI.updateUserBadgeLevel(payload));
  if (error) throw error;
  return res;
};

const deleteBadge = async (parameters) => {
  const [, error] = await handleAsync(badgeAPI.deleteBadge(parameters));
  if (error) throw error;
};

const deleteUserBadge = async (parameters) => {
  const [res, error] = await handleAsync(badgeAPI.deleteUserBadge(parameters));
  if (error) throw error;
  return res;
};

const assignBadge = async (parameters, payload) => {
  const [res, error] = await handleAsync(badgeAPI.assignBadge(parameters, payload));
  if (error) throw error;
  return res;
};

const updateUserLevel = async (payload) => {
  const [res, error] = await handleAsync(badgeAPI.updateUserLevel(payload));
  if (error) throw error;
  return res;
};

const uploadBadgeImage = async (parameters, body, config) => {
  const [res, error] = await handleAsync(badgeAPI.uploadBadgeImage(parameters, body, config));
  if (error) throw error;
  return res;
};

const uploadDefaultBadgeImage = async (payload) => {
  const [res, error] = await handleAsync(badgeAPI.uploadDefaultBadgeImage(payload));
  if (error) throw error;
  return res;
};

const getQueryOptions = async () => {
  const [res, error] = await handleAsync(badgeAPI.getQueryOptions());
  if (error) throw error;
  return res;
};

const BadgeService = {
  getBadgeList,
  getBadgeDetail,
  getBadgeStatistics,
  getUsersWithBadge,
  getUserBadges,
  getDefaultBadgeImage,
  addBadge,
  updateBadgeDetail,
  deleteBadge,
  deleteUserBadge,
  assignBadge,
  updateUserLevel,
  updateUserBadgeLevel,
  uploadBadgeImage,
  uploadDefaultBadgeImage,
  getQueryOptions,
};

export { BadgeService };
