export const ALL_BADGES = '/v1/badges/all';
export const ADD_BADGE = '/v1/admin/badges';
export const ASSIGN_BADGE = '/v1/admin/user-badge/assign';
export const BADGE_DETAIL = '/v1/badges/all';
export const DELETE_USER_BADGE = '/v1/admin/user-badge';
export const UPDATE_USER_LEVEL = '/v1/admin/user-badge/levels';
export const GET_USER_WITH_BADGE = '/v1/admin/subjob/allowed-users';
export const GET_USER_BADGES = 'v1/admin/user-badge/all';
export const BADGE_IMAGE_UPLOAD = 'v1/admin/badges/image';
export const BADGE_DEFAULT_IMAGE = 'v1/admin/config';
export const BADGE_STATISTICS = 'v1/admin/user-badge/statistics';
export const BADGE_ADMIN = 'v1/admin/badges';
export const BADGE_OPTIONS = 'v1/badges/query-options';
