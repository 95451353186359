import PropTypes from 'prop-types';
import React from 'react';
import { Group, Rect, Text } from 'react-konva';

const RenderBoundingBoxLabel = ({
  label,
  boundingBoxStrokeColor,
  boundingBoxWidth,
  boundingBoxXPosition,
  boundingBoxYPosition,
  zoomValue,
  isShowlabelColor,
}) => {
  const zoomValuePer100 = zoomValue / 100;
  const devices50Pixels = 50 / zoomValuePer100;

  const labelLength = label.length;

  const maxLabelWidth = labelLength * (6 / (zoomValue / 100)) + 30 / (zoomValue / 100);
  const labelWidth = Math.max(boundingBoxWidth - 23 / (zoomValue / 100), devices50Pixels);

  const boundingShapeLabel =
    zoomValue !== 300 && labelWidth < maxLabelWidth
      ? label.slice(0, Math.floor((labelWidth - 30 / zoomValuePer100) / (6 / zoomValuePer100)) - 2) + '...'
      : label;

  const labelStyleSetup = isShowlabelColor
    ? {
        textXposition: boundingBoxXPosition + 23 / zoomValuePer100,
      }
    : {
        textXposition: boundingBoxXPosition + (labelLength * 1.6) / zoomValuePer100,
      };

  const widthOfBox = Math.max(
    16 / zoomValuePer100 +
      boundingShapeLabel.length * (6 / zoomValuePer100) +
      16 / zoomValuePer100 +
      (boundingShapeLabel.length * (6 / zoomValuePer100) * 2) / 100,
    devices50Pixels,
  );

  return (
    <Group>
      <Rect
        x={boundingBoxXPosition}
        y={boundingBoxYPosition - 27 / (zoomValue / 100)}
        width={widthOfBox}
        height={23 / (zoomValue / 100)}
        fill={'#6C624F'}
        cornerRadius={6 / (zoomValue / 100)}
      />
      <Rect
        x={boundingBoxXPosition + 3 / zoomValuePer100}
        y={boundingBoxYPosition - 23 / zoomValuePer100}
        width={16 / zoomValuePer100}
        height={16 / zoomValuePer100}
        fill={boundingBoxStrokeColor}
        cornerRadius={3 / (zoomValue / 100)}
        id="boundingBoxStroke"
      />
      <Text
        x={labelStyleSetup.textXposition}
        y={boundingBoxYPosition - 20 / (zoomValue / 100)}
        text={boundingShapeLabel}
        fontSize={12 / zoomValuePer100}
        fill="#FFF"
      />
    </Group>
  );
};

RenderBoundingBoxLabel.propTypes = {
  label: PropTypes.string,
  boundingBoxStrokeColor: PropTypes.string,
  boundingBoxWidth: PropTypes.number,
  boundingBoxXPosition: PropTypes.number,
  boundingBoxYPosition: PropTypes.number,
  zoomValue: PropTypes.number,
  isShowlabelColor: PropTypes.bool,
};
RenderBoundingBoxLabel.defaultProps = {
  isShowlabelColor: true,
};

export default RenderBoundingBoxLabel;
