import { pending, ongoing, approved, rejected, unassigned, expired } from 'common/constants/global';

const defaultUserTaskListPageObject = {
  [ongoing]: 1,
  [pending]: 1,
  [approved]: 1,
  [rejected]: 1,
  [unassigned]: 1,
  [expired]: 1,
};
const defaultUserTaskListTab = ongoing;
const defaultUserTaskListPerPage = '5';

export const INITIAL_STATE = {
  pageObject: defaultUserTaskListPageObject,
  tab: defaultUserTaskListTab,
  perPage: defaultUserTaskListPerPage,
};
