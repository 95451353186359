import { preTaskScreeningAPI } from 'configs';
import { handleAsync } from 'utils';

const createPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.create(payload));
  if (error) throw error;
  return res;
};

const uploadFilesPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.uploadFiles(payload));
  if (error) throw error;
  return res;
};

const getQueryOptions = async () => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.getQueryOptions());
  if (error) throw error;
  return res;
};

const getDetailPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.getDetails(payload));
  if (error) throw error;
  return res;
};

const getItemsPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.getItems(payload));
  if (error) throw error;
  return res;
};

const editPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.editPreTask(payload));
  if (error) throw error;
  return res;
};

const deleteItemsPreTask = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.deleteItems(payload));
  if (error) throw error;
  return res;
};

const disablePreTaskScreening = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.disable(payload));
  if (error) throw error;
  return res;
};

const setPreTaskBoundingBoxTags = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.setBoundingBoxTags(payload));
  if (error) throw error;
  return res;
};

const setPreTaskBoundingPolygonTags = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.setBoundingPolygonTags(payload));
  if (error) throw error;
  return res;
};

const setPreTaskAudioTranscriptionCleansing = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.setAudioTranscriptionCleansing(payload));
  if (error) throw error;
  return res;
};

const copyPreTaskItems = async (payload) => {
  const [res, error] = await handleAsync(preTaskScreeningAPI.copy(payload));
  if (error) throw error;
  return res;
};

const PreTaskScreeningService = {
  createPreTask,
  uploadFilesPreTask,
  getQueryOptions,
  getDetailPreTask,
  getItemsPreTask,
  deleteItemsPreTask,
  setPreTaskBoundingBoxTags,
  editPreTask,
  setPreTaskAudioTranscriptionCleansing,
  setPreTaskBoundingPolygonTags,
  disablePreTaskScreening,
  copyPreTaskItems,
};
export { PreTaskScreeningService };
