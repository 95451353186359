import PropTypes from 'prop-types';
import React from 'react';
import { TictagLogo } from 'assets';
import { useTranslation } from 'utils/libraries';

const TcLogoClass = ({ center }) => `
  tw-flex
  tw-items-center
  tw-text-center
  tw-p-8
  tw-text-black
  ${center ? 'tw-justify-center' : ''}
`;

const TcLogoSpanClass = `
  tw-inline-block
  tw-pl-3
  tw-text-xl
  tw-font-black
  tw-capitalize
  tw-text-gray-1300
`;

const TcLogo = (props) => {
  const { t } = useTranslation();
  const { center, className } = props;

  return (
    <div className={TcLogoClass({ center }) + className}>
      <img src={TictagLogo} alt={t('label.global.appTitle')} />
      <span className={TcLogoSpanClass}>{t('label.global.appTitle')}</span>
    </div>
  );
};

TcLogo.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
};

TcLogo.defaultProps = {
  center: false,
  className: '',
};

export default TcLogo;
