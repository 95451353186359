import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class TaskQuestionnaireService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getTaskQuestionnaire(parameter) {
    const { payload } = parameter;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.TASK_QUESTIONNAIRE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setTaskQuestionnaire(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.TASK_QUESTIONNAIRE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
  async editTaskQuestionnaire(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.TASK_QUESTIONNAIRE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async enableTaskQuestionnaire(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.ENABLE_TASK_QUESTIONNAIRE}?${queryParameters}`;
      const { data } = await this.HTTP.post(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async disableTaskQuestionnaire(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.DISABLE_TASK_QUESTIONNAIRE}?${queryParameters}`;
      const { data } = await this.HTTP.post(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getTaskQuestionnaireUserSubmit(parameter) {
    try {
      const queryParameters = buildQueryParams(parameter);
      const endpoint = endpoints.GET_TASK_QUESTIONNAIRE_USER_SUBMIT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async downloadTaskQuestionnaire(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.TASK_QUESTIONNAIRE_DOWNLOAD;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url, {}, 'arraybuffer');

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async downloadTaskQuestionnaireState(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.TASK_QUESTIONNAIRE_DOWNLOAD_STATE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
