import PropTypes from 'prop-types';
import React from 'react';
import { TcLabel } from 'common/components';

const FormLabel = (props) => {
  const { title, error, labelColor } = props;

  if (!title) return null;

  return <TcLabel title={title} error={error} color={labelColor} />;
};

FormLabel.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  labelColor: PropTypes.string,
};

export default FormLabel;
