import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { TcButton, TcLabel } from 'common/components';

const TcAlertDialog = (props) => {
  const {
    children,
    openDialog,
    onCloseDialog,
    dialogTitle,
    firstOption,
    secondOption,
    onFirstOptionClick,
    onSecondOptionClick,
    disabledSecondButton,
    isSubmitting,
    fullWidth,
    footer,
    secondButtonType,
    buttonContainerInsideContent = false,
    dialogTitleColor,
    footerColor,
  } = props;

  const RenderActions = () => {
    const SetSecondButtonType = () => {
      switch (secondButtonType) {
        case 'approve':
          return 'approve';
        case 'reject':
          return 'reject';
        default:
          return 'submit';
      }
    };

    return (
      <DialogActions>
        <span className={`tw-mr-auto tw-p-4 ${footerColor}`}>{footer}</span>
        {firstOption ? (
          <TcButton
            onClick={onFirstOptionClick}
            disabled={isSubmitting}
            type="plain"
            label={firstOption}
            className="tw-text-orange-400"
          />
        ) : null}
        {secondOption ? (
          <TcButton
            onClick={onSecondOptionClick}
            disabled={isSubmitting || disabledSecondButton}
            loading={isSubmitting}
            type={SetSecondButtonType(secondButtonType)}
            label={secondOption}
            className="tw-rounded-lg"
          />
        ) : null}
      </DialogActions>
    );
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog} disableBackdropClick fullWidth={fullWidth}>
      <DialogTitle>
        <TcLabel className={`tw-text-3xl`} title={dialogTitle} noMargin color={dialogTitleColor} />
      </DialogTitle>
      <DialogContent>
        {children}
        {buttonContainerInsideContent ? <RenderActions /> : null}
      </DialogContent>
      {!buttonContainerInsideContent ? <RenderActions /> : null}
    </Dialog>
  );
};

TcAlertDialog.propTypes = {
  children: PropTypes.any,
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  firstOption: PropTypes.string,
  secondOption: PropTypes.string,
  onFirstOptionClick: PropTypes.func,
  onSecondOptionClick: PropTypes.func,
  disabledSecondButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  footer: PropTypes.string,
  isSubmitting: PropTypes.bool,
  maxWidth: PropTypes.string,
  secondButtonType: PropTypes.string,
  buttonContainerInsideContent: PropTypes.bool,
  dialogTitleColor: PropTypes.string,
  footerColor: PropTypes.string,
};

export default TcAlertDialog;
