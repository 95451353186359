import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { Shape, Circle } from 'react-konva';
import { SHAPE_CORNER_POINT_PROPERTIES } from 'common/constants/canvas';

const BoundingPolygon = ({ boxProps, isSelected, onSelect = () => {}, draggable, pointRadius }) => {
  const shapeReference = useRef(null);

  return (
    <>
      <Shape
        onClick={(event) => {
          onSelect(event);
        }}
        onTap={(event) => {
          onSelect(event);
        }}
        ref={shapeReference}
        sceneFunc={(context, shape) => {
          context.beginPath();
          {
            boxProps.points
              // Translate to relative minX and minY, since the 0,0 begins from that
              .map((point) => ({ x: point.x - boxProps.x, y: point.y - boxProps.y }))
              .map((point, index) => {
                return index === 0 ? context.moveTo(point.x, point.y) : context.lineTo(point.x, point.y);
              });
          }
          context.closePath();
          // (!) Konva specific method, it is very important
          context.fillStrokeShape(shape);
        }}
        draggable={draggable}
        {...boxProps}
      />
      {isSelected &&
        boxProps.points.map((point, index) => (
          <Circle
            key={index}
            x={point.x}
            y={point.y}
            draggable={false}
            radius={pointRadius}
            stroke={boxProps.stroke}
            {...SHAPE_CORNER_POINT_PROPERTIES}
          />
        ))}
    </>
  );
};

BoundingPolygon.propTypes = {
  boxProps: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  draggable: PropTypes.bool,
  pointRadius: PropTypes.number,
};

BoundingPolygon.defaultProps = {
  isSelected: false,
};

// TODO: change the name to TcBoundingPolygon?
// may need to change the BoundingBox too
export default memo(BoundingPolygon);
