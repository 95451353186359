import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { zoomInIcon, zoomOutIcon } from 'assets';
import { TcIcon } from 'common/components';

const height = '36px';
const thumbWidth = 14;
const thumbHeight = 12;
const trackHeight = '4px';
const primaryColor = '#6C624F';
const trackBackground = `linear-gradient(to bottom, ${primaryColor}, ${primaryColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

const ContainerRangeSlider = styled.div`
  max-width: 280px;
  width: 100%;
  display: flex;
  align-items: center;
  & input {
    background: transparent;
  }
`;

const StyledRangeSlider = styled.input`
  appearance: none;
  flex-grow: 1;
  height: ${height};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${trackBackground};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbWidth}px;
    background: ${primaryColor};
    border-radius: 2px;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${trackBackground};
  }

  &::-moz-range-progress {
    background: ${trackBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbWidth};
    background: ${primaryColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${trackBackground};
  }

  &::-ms-fill-upper {
    background: ${trackBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbWidth};
    background: ${primaryColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${primaryColor};
    }
    &::-moz-range-thumb {
      background-color: ${primaryColor};
    }
    &::-ms-thumb {
      background-color: ${primaryColor};
    }
  }
`;

const WrapperIcon = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 4px solid ${primaryColor};
  user-select: none;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const RangeSlider = ({ value, min, max, step, onChange, onZoomOutClick, onZoomInClick }) => {
  return (
    <ContainerRangeSlider>
      <WrapperIcon onClick={() => onZoomOutClick(-step)}>
        <TcIcon src={zoomOutIcon} alt="zoom out" />
      </WrapperIcon>
      <StyledRangeSlider type="range" min={min} max={max} step={step} value={value} onChange={onChange} />
      <WrapperIcon onClick={() => onZoomInClick(step)}>
        <TcIcon src={zoomInIcon} alt="zoom in" />
      </WrapperIcon>
    </ContainerRangeSlider>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onZoomInClick: PropTypes.func,
  onZoomOutClick: PropTypes.func,
};

RangeSlider.defaultProps = {
  min: 10,
  max: 200,
  step: 10,
};

export default RangeSlider;
