import axios from 'axios';
import { store } from 'index';
import { SIGN_OUT } from 'store/actions';
import { checkToken, setToken } from 'utils';

const baseURL = process.env.REACT_APP_API_URL;
const baseTimeout = Number(process.env.REACT_APP_TIMEOUT);

const OPTIONS = {
  baseURL,
  timeout: isNaN(baseTimeout) ? 200000 : baseTimeout,
  headers: {},
};

const RestHttp = axios.create(OPTIONS);

RestHttp.interceptors.request.use((config) => {
  const token = checkToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

RestHttp.interceptors.response.use(
  (response) => {
    if (response.status === 0) {
      const error = new Error('Request timeout');
      error.response = response;

      return null;
    }

    const token = response.data?.access_token;
    if (token) setToken(token);

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch({ type: SIGN_OUT });
    }
    return Promise.reject(error);
  },
);

export default RestHttp;
