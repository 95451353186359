import Modal from '@material-ui/core/Modal';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { TcSection } from 'common/components';

const StyledModal = styled(Modal)({
  width: '80%',
  margin: 'auto',
  paddingTop: '10%',
  paddingBottom: '10%',
});

const TcModal = (props) => {
  const { children, open, handleClose } = props;
  return (
    <StyledModal open={open} onClose={handleClose}>
      <TcSection className="tw-p-8">{children}</TcSection>
    </StyledModal>
  );
};

TcModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TcModal;
