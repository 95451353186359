import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 6px;
`;

const TcCard = ({ children }) => {
  return <CardContainer>{children}</CardContainer>;
};

TcCard.propTypes = {
  children: PropTypes.node,
};

export default TcCard;
