import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const StyledColorIndicator = styled.div`
  ${({ size }) => {
    return css`
      width: ${size}px;
      height: ${size}px;
    `;
  }};
  border-radius: 50%;
  background: ${({ fill }) => fill};
  border-color: ${({ stroke }) => stroke};
  border-width: ${({ strokeWidth }) => strokeWidth}px;
`;

const TcColorIndicator = (props) => {
  return <StyledColorIndicator {...props} />;
};

TcColorIndicator.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

TcColorIndicator.defaultProps = {
  stroke: '#FFF',
  strokeWidth: '0',
};

export default memo(TcColorIndicator);
