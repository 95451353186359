import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const TcResizerStyled = styled.div`
  background-size: 7px 7px;
  background-image: repeating-linear-gradient(
    -45deg,
    var(--color-gray-600) 0,
    var(--color-gray-600) 1px,
    var(--color-gray-100) 0,
    var(--color-gray-100) 50%
  );
  ${({ props: { direction } }) => {
    return direction === 'vertical'
      ? css`
          width: 100%;
          height: 5px;
        `
      : css`
          width: 5px;
          height: 100%;
        `;
  }};
  &:hover {
    cursor: ${({ props: { direction } }) => (direction === 'horizontal' ? 'col-resize' : 'row-resize')};
  }
`;

const TcResizerClass = `
  tw-flex-shrink-0
`;

const TcResizer = (props) => {
  const { direction, handleDragFinished } = props;

  const resizerReference = useRef(null);

  useEffect(() => {
    const resizer = resizerReference?.current;
    if (!resizer) return;

    const previousSibling = resizer.previousElementSibling;
    const nextSibling = resizer.nextElementSibling;

    previousSibling.style.flexShrink = 0;
    nextSibling.style.flexShrink = 0;

    // The current position of mouse
    let x = 0;
    let y = 0;
    let previousSiblingHeight = 0;
    let previousSiblingWidth = 0;

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = (e) => {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;
      const rect = previousSibling.getBoundingClientRect();
      previousSiblingHeight = rect.height;
      previousSiblingWidth = rect.width;

      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      switch (direction) {
        case 'vertical': {
          const h = ((previousSiblingHeight + dy) * 100) / resizer.parentNode.getBoundingClientRect().height;
          previousSibling.style.height = `${h}%`;
          break;
        }

        case 'horizontal':
        default: {
          const w = ((previousSiblingWidth + dx) * 100) / resizer.parentNode.getBoundingClientRect().width;
          previousSibling.style.width = `${w}%`;

          break;
        }
      }

      const cursor = direction === 'horizontal' ? 'col-resize' : 'row-resize';
      resizer.style.cursor = cursor;
      document.body.style.cursor = cursor;

      previousSibling.style.userSelect = 'none';
      previousSibling.style.pointerEvents = 'none';

      nextSibling.style.userSelect = 'none';
      nextSibling.style.pointerEvents = 'none';
    };

    const mouseUpHandler = () => {
      resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      previousSibling.style.removeProperty('user-select');
      previousSibling.style.removeProperty('pointer-events');

      nextSibling.style.removeProperty('user-select');
      nextSibling.style.removeProperty('pointer-events');

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      // Call the callback function
      if (handleDragFinished) {
        handleDragFinished();
      }
    };

    // Attach the handler
    resizer.addEventListener('mousedown', mouseDownHandler);
  }, []);

  return <TcResizerStyled ref={resizerReference} className={TcResizerClass} props={props} />;
};

TcResizer.propTypes = {
  direction: PropTypes.string,
  handleDragFinished: PropTypes.func,
};

TcResizer.defaultProps = {
  direction: 'vertical',
};

export default TcResizer;
