import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { TcProgressBar, TcDropZone } from 'common/components';
import { PreTaskScreeningService } from 'services';
import { handleAsync, handleBundleFiles } from 'utils';

const DropzonePreTaskScreening = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [bundleFiles, setBundleFiles] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [progressUpload, setProgressUpload] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploadFinished, setUploadFinished] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { id, maxFiles, disabled, onUploading, setUploadFailed, type, onUploadFinished, uploadedCount } = props;

  const handleErrorUploading = (message) => {
    setErrorMessage(message);
    setUploadFailed && setUploadFailed(true);
    onUploading && onUploading(false);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setBundleFiles([]);
      setErrorMessage('');
      if (acceptedFiles.length + uploadedCount > maxFiles) {
        return null;
      }
      if (acceptedFiles.length > 0 && onUploading) onUploading(true);
      setSelectedFiles(acceptedFiles);
      setProcessedFiles(0);
      setTotalFiles(acceptedFiles.length);
      setUploadFailed && setUploadFailed(false);
      setUploadFinished(false);

      const newBundleFiles = await handleBundleFiles(acceptedFiles);
      if (newBundleFiles.error && onUploading && setUploadFailed) {
        setErrorMessage(newBundleFiles.error);
        setUploadFailed(true);
        onUploading(false);
        return null;
      }

      setBundleFiles(newBundleFiles);
      if (id) handleUploadFiles(newBundleFiles);
    },
    [id, setBundleFiles, totalFiles],
  );

  useEffect(() => {
    if (id) handleUploadFiles();
  }, [id]);

  const handleUploadFiles = async (newBundleFiles = bundleFiles) => {
    for (const bundleFile of newBundleFiles) {
      setProgressUpload(0);

      const payload = {
        parameters: {
          pre_task_screening_id: id,
        },
        body: bundleFile,
        config: {
          onUploadProgress: (progressEvent) => {
            setFileLoaded((progressEvent.loaded / 1000).toFixed(2));
            setFileSize((progressEvent.total / 1000).toFixed(2));
          },
          timeout: false,
        },
      };

      try {
        const [res, error] = await handleAsync(PreTaskScreeningService.uploadFilesPreTask(payload));
        if (error) throw error;
        else if (!res.length) {
          throw { message: 'Upload failed' };
        } else {
          setUploadedFiles(res);
          setProcessedFiles((currentProcessedFiles) => currentProcessedFiles + bundleFile.length);
        }
      } catch (error) {
        const { message } = error;
        let errorMessage = '';
        if (message) errorMessage = message;
        else errorMessage = error.length ? error : 'Unknown';
        handleErrorUploading(errorMessage);
        break;
      } finally {
        setProgressUpload(100);
      }
    }
  };

  useEffect(() => {
    const fileUploadPercent = Math.floor((fileLoaded / fileSize) * 100);
    if (fileUploadPercent && !isNaN(fileUploadPercent)) setProgressUpload(fileUploadPercent);
  }, [fileLoaded, fileSize]);

  useEffect(() => {
    if (totalFiles && processedFiles >= totalFiles && !isUploadFinished) {
      setTimeout(() => {
        setUploadFinished(true);
        onUploadFinished(uploadedFiles);
      }, 1000);
    }
  }, [processedFiles, totalFiles]);

  const RenderProgressBar = () => {
    if (selectedFiles.length && !isUploadFinished) {
      return (
        <div className="tw-mt-6">
          <TcProgressBar error={!!errorMessage} progress={progressUpload} />
          <div className="tw-float-left tw-text-left">
            {processedFiles} of {totalFiles} Processed
          </div>
          <p className="tw-text-right">{progressUpload}%</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <TcDropZone type={type} onDrop={onDrop} disabled={disabled} />
      <RenderProgressBar />
    </div>
  );
};

DropzonePreTaskScreening.propTypes = {
  onUploading: PropTypes.func,
  id: PropTypes.string,
  maxFiles: PropTypes.number,
  setUploadFailed: PropTypes.func,
  type: PropTypes.string,
  onUploadFinished: PropTypes.func,
  uploadedCount: PropTypes.number,
  disabled: PropTypes.bool,
};

DropzonePreTaskScreening.defaultTypes = {
  type: 'image',
};

export default memo(DropzonePreTaskScreening);
