import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcLayoutStyled = styled.div`
  background-color: ${(props) => props.bgColor};
`;

const TcLayoutClass = `
  tw-h-full
  tw-clearfix
`;

const TcLayout = (props) => {
  const { children, bgColor, className } = props;

  return (
    <TcLayoutStyled className={TcLayoutClass + className} bgColor={bgColor}>
      {children}
    </TcLayoutStyled>
  );
};

TcLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string,
};

TcLayout.defaultProps = {
  bgColor: '#F8F8F8',
  className: '',
};

export default TcLayout;
