import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcAvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  border-radius: 50%;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  overflow: hidden;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

const TcAvatar = (props) => {
  const { children, className, size } = props;

  return (
    <TcAvatarStyled className={className} size={size}>
      {children}
    </TcAvatarStyled>
  );
};

TcAvatar.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};

TcAvatar.defaultProps = {
  children: '',
  className: '',
  size: 100,
};

export default TcAvatar;
