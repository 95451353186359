import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';
import styled, { css } from 'styled-components';
import { TcIcon } from 'common/components';

const TcInputWrapperStyled = styled.div`
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcInputWrapperClass = `
  tw-inline-block
  tw-relative
`;

const TcInputStyled = styled.input`
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
  ${({ error }) =>
    error &&
    css`
      color: var(--color-red-300);
      border-color: var(--color-red-300);
      ::placeholder {
        color: var(--color-red-300);
      }
    `}
`;

const TcInputClass = `
  tw-rounded-lg
  tw-py-2
  tw-px-4
  tw-border
  tw-outline-none
  tw-text-black
  tw-placeholder-gray-600
`;

const TcIconFloatingClass = `
  tw-absolute
  tw-top-0
  tw-right-0
  tw-bottom-0
  tw-flex
  tw-items-center
  tw-justify-center
  tw-px-2
  tw-m-1
  tw-bg-white
`;

const TcInput = memo(
  forwardRef((properties, reference) => {
    const {
      name,
      type,
      defaultValue,
      min,
      placeholder,
      autoComplete,
      full,
      error,
      icon,
      renderIcon,
      className,
      disabled,
    } = properties;

    const RenderIcon = () => {
      if (!renderIcon) return null;

      return (
        <div className={TcIconFloatingClass}>
          <TcIcon src={icon?.src} alt={icon?.alt} />
        </div>
      );
    };

    return (
      <TcInputWrapperStyled className={TcInputWrapperClass} full={full}>
        <TcInputStyled
          ref={reference}
          min={min}
          name={name}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          full={full}
          error={error}
          disabled={disabled}
          // value={curValue}
          className={TcInputClass + className}
          // onChange={(event) => onChange || handleChange(event.target.value)}
          defaultValue={defaultValue}
        />
        <RenderIcon />
      </TcInputWrapperStyled>
    );
  }),
  [],
);

TcInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  full: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.object,
  renderIcon: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  min: PropTypes.number,
  onChange: PropTypes.func,
};

TcInput.defaultProps = {
  value: '',
  placeholder: '',
  autoComplete: '',
  full: false,
  error: '',
  icon: {},
  renderIcon: false,
  className: '',
  disabled: false,
  min: 0,
};

export default TcInput;
