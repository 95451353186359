import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import styled, { css } from 'styled-components';

const InputWrapper = styled.div`
  ${({ disabled }) =>
    css`
      display: flex;
      width: 100%;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      background-color: ${disabled ? '#f3f3f3' : 'inherit'};

      & input {
        background-color: inherit;
        font-size: 14px;
        height: 36px;
        box-sizing: border-box;
        padding: 7px 14px;
        width: 0;
        min-width: 30px;
        flex-grow: 1;
        border: 0;
        margin: 0;
        outline: 0;
      }
    `}
`;

const Tag = styled(({ label, onDelete, error, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    {props.canRemoveTag ? <CloseIcon onClick={onDelete} color="disabled" /> : <span className="tw-px-1"></span>}
  </div>
))`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2px;
  line-height: 22px;
  background-color: #fff;
  border: 1px solid ${({ error }) => (error ? 'var(--color-red-400)' : '#e4e4e4')};
  border-radius: 50px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ error }) => (error ? 'var(--color-red-400)' : 'black')};
  }

  & svg {
    color: red;
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
    & path {
      fill: ${({ error }) => (error ? 'var(--color-red-400)' : 'black')};
    }
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;
    border-right: 2px solid var(--color-orange-400);
  }

  & li[data-focus='true'] {
    background-color: var(--color-orange-100);
    cursor: pointer;
  }
`;

const TcMultiSelect = ({
  options,
  optionLabel,
  selectedOption,
  errorOptions,
  handleChange,
  disabledInput,
  disabledTags,
  placeholder,
  disabledPlaceholder,
  error,
  helperText,
  canRemoveTag = true,
}) => {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: selectedOption,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option[optionLabel],
  });

  useEffect(() => {
    handleChange(value);
  }, [value]);

  useEffect(() => {
    value.forEach((option) => {
      if (errorOptions?.some((errorOption) => errorOption.id === option.id)) {
        option.error = true;
      } else {
        option.error = false;
      }
    });
  }, [errorOptions]);

  const renderPlaceholder = () => {
    return disabledInput ? disabledPlaceholder : placeholder;
  };

  return (
    <div className="tw-relative">
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} disabled={disabledInput}>
          {!disabledTags &&
            value.map((option, index) => (
              <Tag
                canRemoveTag={canRemoveTag}
                error={option.error}
                key={index}
                label={option[optionLabel]}
                {...getTagProps({ index })}
              />
            ))}

          <input {...getInputProps()} disabled={disabledInput} placeholder={renderPlaceholder()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              <span>{option[optionLabel]}</span>
            </li>
          ))}
        </Listbox>
      ) : null}
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};

TcMultiSelect.propTypes = {
  options: PropTypes.array,
  selectedOption: PropTypes.array,
  errorOptions: PropTypes.array,
  optionLabel: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  disabledPlaceholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  canRemoveTag: PropTypes.bool,
};

export default memo(TcMultiSelect);
