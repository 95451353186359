import { lazy } from 'react';

const JobCreate = lazy(() => import('./JobCreate'));
const JobList = lazy(() => import('./JobList'));
const JobDetail = lazy(() => import('./JobDetail'));
const JobEdit = lazy(() => import('./JobEdit'));
const JobTaskList = lazy(() => import('./JobTaskList'));
const JobBoardDetail = lazy(() => import('./JobBoardDetail'));
const JobBoardPushEdit = lazy(() => import('./JobBoardPushEdit'));
export * from './TaggingReview';
const RejectionReason = lazy(() => import('./RejectionReason'));

export { JobCreate, JobList, JobDetail, JobEdit, JobTaskList, JobBoardDetail, JobBoardPushEdit, RejectionReason };
