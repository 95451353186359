import { cashPrizeAPI } from 'configs';
import { handleAsync } from 'utils';

const getList = async (parameters) => {
  const [res, error] = await handleAsync(cashPrizeAPI.getList(parameters));
  if (error) throw error;
  return res;
};

const getDetail = async (parameters) => {
  const [res, error] = await handleAsync(cashPrizeAPI.getDetail(parameters));
  if (error) throw error;
  return res;
};

const approveRedemptionRequest = async (payload) => {
  const [res, error] = await handleAsync(cashPrizeAPI.approveRedemptionRequest(payload));
  if (error) throw error;
  return res;
};

const rejectRedemptionRequest = async (payload) => {
  const [res, error] = await handleAsync(cashPrizeAPI.rejectRedemptionRequest(payload));
  if (error) throw error;
  return res;
};

const CashPrizeService = {
  getList,
  getDetail,
  approveRedemptionRequest,
  rejectRedemptionRequest,
};

export { CashPrizeService };
