import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcLoadingCircleWrapperClass = `
  tw-relative
  tw-h-full
`;

const TcLoadingCircleWrapperStyled = styled.div``;

const TcLoadingCircleFrameClass = `
  tw-m-auto
`;

const TcLoadingCircleFrameStyled = styled.div`
  z-index: 1;
`;

const TcLoadingCircleClass = `
  tw-relative
  tw-m-auto
`;

const TcLoadingCircleStyled = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const TcLoadingCircleSVGClass = `
  tw-absolute
  tw-m-auto
  tw-top-0
  tw-left-0
  tw-h-full
  tw-w-full
`;

const TcLoadingCircleSVGStyled = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const TcLoadingCircleSVGCircleStyled = styled.circle`
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  stroke: ${(props) => props.color};
  -webkit-animation: dash 6s ease-in-out infinite;
  animation: dash 6s ease-in-out infinite;
  stroke-linecap: round;

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
`;

const TcLoadingCircle = (props) => {
  const { color, size, className } = props;

  return (
    <TcLoadingCircleWrapperStyled className={TcLoadingCircleWrapperClass + className}>
      <TcLoadingCircleFrameStyled className={TcLoadingCircleFrameClass}>
        <TcLoadingCircleStyled size={size} className={TcLoadingCircleClass}>
          <TcLoadingCircleSVGStyled viewBox="25 25 50 50" className={TcLoadingCircleSVGClass}>
            <TcLoadingCircleSVGCircleStyled
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke={color}
              color={color}
              strokeWidth="2"
            />
          </TcLoadingCircleSVGStyled>
        </TcLoadingCircleStyled>
      </TcLoadingCircleFrameStyled>
    </TcLoadingCircleWrapperStyled>
  );
};

TcLoadingCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

TcLoadingCircle.defaultProps = {
  color: 'var(--color-white)',
  size: 30,
  className: '',
};

export default TcLoadingCircle;
