import { CHANGE_ACTIVE_MENU, CHANGE_SIDEBAR_TYPE } from '../../actions';
import { INITIAL_STATE } from './defaultSidebarState';

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_TYPE:
      return { ...state, sidebarType: action.payload };
    case CHANGE_ACTIVE_MENU:
      return { ...state, activeMenu: action.payload };
    default:
      return state;
  }
};

export default sidebarReducer;
