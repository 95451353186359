import { qaMechanismAPI } from 'configs';
import { handleAsync } from 'utils';

const getDecisionList = async (parameters) => {
  const [res, error] = await handleAsync(qaMechanismAPI.getDecisionList(parameters));

  if (error) throw error;
  return res;
};

const getPopulationSize = async (parameters) => {
  const [res, error] = await handleAsync(qaMechanismAPI.getPopulationSize(parameters));

  if (error) throw error;
  return res;
};

const getDownloadFormatOptions = async (parameters) => {
  const [res, error] = await handleAsync(qaMechanismAPI.getDownloadSample(parameters));
  if (error) throw error;
  return res;
};

const getSamples = async (parameters) => {
  const [res, error] = await handleAsync(qaMechanismAPI.getSamples(parameters));
  if (error) throw error;
  return res;
};

const setSamples = async (parameters) => {
  const [res, error] = await handleAsync(qaMechanismAPI.setSamples(parameters));
  if (error) throw error;
  return res;
};

const QaMechanismService = {
  getDecisionList,
  getDownloadFormatOptions,
  getPopulationSize,
  getSamples,
  setSamples,
};

export { QaMechanismService };
