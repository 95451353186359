import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class DeletionRequestService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getAllDeletionRequests(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.DELETION_REQUEST_ALL}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserDeletionRequest(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.DELETION_REQUEST_USER}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteUser(parameters) {
    const { payload } = parameters;

    try {
      if (payload['force_approve'] === false) {
        delete payload['force_approve'];
      }
      const queryParameters = payload ? buildQueryParams(payload) : null;
      const url = `${endpoints.DELETION_REQUEST_APPROVAL}?${queryParameters}`;
      const { data } = await this.HTTP.post(url);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
