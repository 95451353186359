import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TcDropzoneJob, TcLabel, TcWarning, TcTable, TcSection, TcGrid } from 'common/components';
import { configInputStep3, imageInputStep3 } from 'common/constants/global';
import { JobService } from 'services';
import { handleAsync } from 'utils';

const TcInputFilesDropzone = (props) => {
  const { jobId, setUploadFailed, onUploading, jobDetail, setReadyToProcess, uploading } = props;
  const [disableInputDropzone, setDisableInputDropzone] = useState(false);
  const [currentUploadedConfigFiles, setCurrentUploadedConfigFiles] = useState([]);
  const [currentUploadedInputFiles, setCurrentUploadedInputFiles] = useState([]);
  const [allUploadedConfigFiles, setAllUploadedConfigFiles] = useState([]);
  const [allUploadedInputFiles, setAllUploadedInputFiles] = useState([]);

  useEffect(() => {
    getProcessStatus(jobId);
    setTimeout(() => {
      getProcessStatus(jobId);
    }, 1000);
  }, [jobId, uploading, onUploading]);

  useEffect(() => {
    if (currentUploadedInputFiles && currentUploadedInputFiles.length > 0) {
      const numberOfFilesUploaded = `${currentUploadedInputFiles.length} image files`;
      setAllUploadedInputFiles((current) => [...current, numberOfFilesUploaded]);
    }
  }, [currentUploadedInputFiles]);

  useEffect(() => {
    if (currentUploadedConfigFiles && currentUploadedConfigFiles.length > 0) {
      setAllUploadedConfigFiles((current) => [...current, ...currentUploadedConfigFiles]);
    }
  }, [currentUploadedConfigFiles]);

  const RenderTableBody = (uploadedFiles) => {
    const element = uploadedFiles.map((fileInfo, index) => {
      return [
        {
          label: index + 1,
          align: 'left',
        },
        {
          label: fileInfo,
          align: 'left',
        },
      ];
    });
    return element;
  };

  const RenderUploadedFilesTable = ({ uploadedFiles }) => {
    if (!uploadedFiles || uploadedFiles.length === 0) return null;
    return (
      <TcSection>
        <TcTable headers={[]} rows={RenderTableBody(uploadedFiles)} rowHoverEffect />
      </TcSection>
    );
  };

  const getProcessStatus = async (jobId) => {
    const parameters = {
      payload: {
        job_id: jobId,
      },
    };
    const [res] = await handleAsync(JobService.getInputAgnosticFilesProcessStatus(parameters));
    setReadyToProcess(res?.ready_to_process ?? false);
    setDisableInputDropzone(res?.ready_to_process || res?.total_unprocessed_items === 0);
  };

  const RenderConfigInputDropzone = () => {
    return (
      <>
        <TcDropzoneJob
          type={configInputStep3}
          id={jobId}
          onUploading={onUploading}
          setUploadFailed={setUploadFailed}
          allowMultipleUpload
          setSuccessfulFilenames={setCurrentUploadedConfigFiles}
        />
        <RenderUploadedFilesTable uploadedFiles={allUploadedConfigFiles} />
      </>
    );
  };

  const RenderImageInputDropzone = () => {
    return (
      <>
        <TcDropzoneJob
          type={imageInputStep3}
          id={jobId}
          onUploading={onUploading}
          setUploadFailed={setUploadFailed}
          allowMultipleUpload
          disabled={disableInputDropzone}
          setSuccessfulFilenames={setCurrentUploadedInputFiles}
        />
        <RenderUploadedFilesTable uploadedFiles={allUploadedInputFiles} />
      </>
    );
  };

  return (
    <>
      {/* using show/hide instead of conditional render to avoid a remount problem */}
      <div
        className={
          !(jobDetail?.input_type?.image === true && jobDetail?.input_type?.text === true) ? 'tw-hidden' : null
        }
      >
        <TcGrid className="tw-mb-8">
          <TcWarning message="Before uploading, make sure the image files match the names in the config file" />
        </TcGrid>
        <div className="tw-flex">
          <div className="tw-w-1/2 tw-pr-6 tw-border-r-2 tw-border-gray-500">
            <TcLabel className="tw-text-lg" title="1. Please select the CSV file" />
            <RenderConfigInputDropzone />
          </div>
          <div className="tw-w-1/2 tw-pl-6">
            <TcLabel className="tw-text-lg" title="2. Please select the image file" />
            <RenderImageInputDropzone />
          </div>
        </div>
      </div>
      <div
        className={jobDetail?.input_type?.image === true && jobDetail?.input_type?.text === true ? 'tw-hidden' : null}
      >
        <RenderConfigInputDropzone />
      </div>
    </>
  );
};

TcInputFilesDropzone.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  setUploadFailed: PropTypes.func.isRequired,
  onUploading: PropTypes.func.isRequired,
  addToastMessage: PropTypes.func.isRequired,
  setReadyToProcess: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

export default TcInputFilesDropzone;
