import {
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH,
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE,
} from 'store/actions';
import { INITIAL_STATE } from './defaultQaMechanismDecisionLIstPaginationState';

const qaMechanismDecisionListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_QA_MECHANISM_DECISION_LIST_PAGE:
      return { ...state, page: action.payload };
    case CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH:
      return { ...state, search: action.payload };
    case CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE:
      return { ...state, paginate: action.payload };
    default:
      return state;
  }
};

export default qaMechanismDecisionListPaginationReducer;
