import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TcButton, TcLabel, TcProgressBar, TcDropZone } from 'common/components';
import {
  srtStep3,
  audioStep3,
  sampleImages,
  configInputStep3,
  imageInputStep3,
  videoStep3,
} from 'common/constants/global';
import { JobService } from 'services';
import { addToast } from 'store/actions';
import { handleAsync, handleBundleFiles } from 'utils';

const TextFileName = styled.p`
  text-align: left;
  color: ${(props) => (props.error ? '#C42300' : 'black')};
  white-space: pre;
`;

const TcDropzoneJob = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileName, setFileName] = useState(0);
  const [fileLoaded, setFileLoaded] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [progressProcessed, setProgressProcessed] = useState(0);
  const [progressUpload, setProgressUpload] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [filesLeft, setFilesLeft] = useState(0);
  const [successfulUploads, setSuccessfulUploads] = useState(0);
  const [failedUploads, setFailedUploads] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFailedNote, setShowFailedNote] = useState(false);

  const {
    id,
    auth,
    addToast,
    onUploading,
    setUploadFailed,
    type,
    isSampleImages,
    disabled,
    setFiles,
    setSuccessfulFilenames,
    allowMultipleUpload,
    failedNote,
    setReadyToProcess,
  } = props;

  const addToastMessage = (statusCardMessage, statusCardType, closeTiming) => {
    addToast({
      message: statusCardMessage,
      styleType: statusCardType,
      closeTiming: closeTiming,
    });
  };

  const handleChangeDropzone = useCallback(
    (files) => {
      setSelectedFiles(files);
      setFiles && setFiles(files);
      setTotalFiles(successfulUploads + failedUploads + files.length);
    },
    [successfulUploads, failedUploads, setSelectedFiles, setTotalFiles],
  );

  const handleErrorUploading = (message, successfulUploadCount, failedUploadCount) => {
    setErrorMessage(message);
    setFilesLeft((files) => (files -= 1));
    setFailedUploads(failedUploadCount);
    setSuccessfulUploads(successfulUploadCount);
    setUploadFailed && setUploadFailed(true);
    onUploading && onUploading(false);
    setShowFailedNote(true);
  };

  const getSuccessfulFileNames = (res) => {
    // set successful filenames
    if (res.data.success_upload) {
      const successFiles = res.data.files.filter((file) => file.severity === 'success');
      const successFileNames = successFiles[0].files.map((file) => file.originalname);
      setSuccessfulFilenames && setSuccessfulFilenames(successFileNames);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0 && onUploading) onUploading(true);
      setUploadFailed && setUploadFailed(false);
      setFilesLeft(acceptedFiles.length);

      const bundleFiles = handleBundleFiles(acceptedFiles, type);
      if (bundleFiles.error) {
        setErrorMessage(bundleFiles.error);
        setUploadFailed && setUploadFailed(true);
        onUploading && onUploading(false);
        return null;
      }

      let successfulUploadCount = successfulUploads;
      let failedUploadCount = failedUploads;
      for (const [index, bundleFile] of bundleFiles.entries()) {
        setFileName(`Bundle ${index + 1} with ${bundleFile.length} files`);
        setProgressUpload(0);

        let step = 3;
        if (isSampleImages) {
          step = sampleImages;
        } else if (
          type === audioStep3 ||
          type === videoStep3 ||
          type === srtStep3 ||
          type === configInputStep3 ||
          type === imageInputStep3
        ) {
          step = type;
        }

        const parameters = {
          type: auth.user.type,
          step,
          payload: {
            job_id: id,
          },
          payloadBody: bundleFile,
          config: {
            onUploadProgress: (progressEvent) => {
              setFileLoaded((progressEvent.loaded / 1000).toFixed(2));
              setFileSize((progressEvent.total / 1000).toFixed(2));
            },
            timeout: false,
          },
        };

        if (type === configInputStep3) {
          parameters.payload['allow_repeat_rows'] = true;
        }

        try {
          const [res, error] = await handleAsync(JobService.create(parameters));
          if (!isSampleImages) {
            if (error) throw error;
            else if (res.data.failed_upload) {
              const errorFiles = res.data.files.filter((file) => file.severity !== 'success');
              let { message } = errorFiles[0];
              const errorFileName = errorFiles[0].files[0].originalname;
              const errorsArray = errorFiles?.[0]?.files?.[0].error ? JSON.parse(errorFiles[0].files[0].error) : [];
              const { message: detailedMessage, lineNumber } = errorsArray?.[0] ?? {};
              message = detailedMessage ? `${message}\nError: ${detailedMessage}\nLine number: ${lineNumber}` : message;
              failedUploadCount += res.data.failed_upload ?? 0;
              if (res.data.success_upload) successfulUploadCount += res.data.success_upload ?? 0;
              handleErrorUploading(`${errorFileName} - ${message}`, successfulUploadCount, failedUploadCount);
              setProcessedFiles((currentProcessedFiles) => currentProcessedFiles + bundleFile.length);
              getSuccessfulFileNames(res);
              break;
            } else if (res.data.success_upload) {
              setProcessedFiles((currentProcessedFiles) => currentProcessedFiles + bundleFile.length);
              setSuccessfulUploads(
                (currentSuccessfulUploads) => currentSuccessfulUploads + res.data.success_upload ?? 0,
              );
              successfulUploadCount += res.data.success_upload ?? 0;
              getSuccessfulFileNames(res);
            }
          } else {
            if (error) throw error;
            else {
              setProcessedFiles((currentProcessedFiles) => currentProcessedFiles + bundleFile.length);
              setSuccessfulUploads((currentSuccessfulUploads) => currentSuccessfulUploads + bundleFile.length ?? 0);
              successfulUploadCount += bundleFile.length ?? 0;
            }
          }
          setShowFailedNote(false);
          addToastMessage(`File upload successful`, 'success', 3000);
        } catch (error) {
          const message = error?.error?.message ?? error?.message;
          let errorMessage = '';
          if (message) errorMessage = message;
          else errorMessage = error.length ? error : 'Unknown';
          failedUploadCount += bundleFile.length;
          handleErrorUploading(errorMessage, successfulUploadCount, failedUploadCount);
          addToastMessage('File upload failed', 'error', 3000);
          break;
        } finally {
          setProgressUpload(100);
        }
      }

      // Set ready to process for componenets that pass it in (currently used for video)
      setReadyToProcess?.(true);
    },
    [auth, id, processedFiles, setProcessedFiles, type],
  );

  useEffect(() => {
    const fileUploadPercent = Math.floor((fileLoaded / fileSize) * 100);
    if (fileUploadPercent && !isNaN(fileUploadPercent)) setProgressUpload(fileUploadPercent);
  }, [fileLoaded, fileSize]);

  useEffect(() => {
    const processedPercent = Math.floor((processedFiles / totalFiles) * 100);

    setFilesLeft(totalFiles - processedFiles || 0);
    onUploading && onUploading(totalFiles - processedFiles !== 0);

    if (processedPercent) setProgressProcessed(processedPercent);
  }, [processedFiles]);

  const handleContinueUpload = () => {
    // reset values that are used to calculate upload progress
    setErrorMessage('');
    setSelectedFiles([]);
    setTotalFiles(0);
    setSuccessfulUploads(0);
    setFailedUploads(0);
    setProcessedFiles(0);
    onUploading && onUploading(false);
  };

  const RenderContinueUpload = () => {
    return errorMessage ? (
      <>
        (Upload stopped) <TcButton className="tw-ml-2" label="Continue Upload" onClick={handleContinueUpload} />
      </>
    ) : null;
  };
  const returnToDropZone = () => {
    return !errorMessage && allowMultipleUpload && progressProcessed >= 100 && progressUpload >= 100;
  };

  useEffect(() => {
    if (returnToDropZone()) {
      handleContinueUpload();
    }
  }, [progressUpload, progressProcessed, errorMessage]);

  const RenderFailedNote = () => {
    return showFailedNote ? (
      <TcLabel
        color={'var(--color-red-400)'}
        className="tw-mb-3 tw-mt-3"
        title={`Note: ${failedNote ?? 'Upload process failed, please check your file and try again'}`}
      />
    ) : null;
  };

  const RenderTextFileName = () => {
    return (
      <TextFileName error={!!errorMessage}>
        {fileName} {errorMessage && `\n${errorMessage}`}
      </TextFileName>
    );
  };

  return (
    <div>
      {/* using show/hide instead of conditional render to avoid a remount problem */}
      <div className={selectedFiles.length ? 'tw-hidden' : null}>
        <TcDropZone disabled={disabled} type={type} onDrop={onDrop} onChange={handleChangeDropzone} />
      </div>
      <div className={!selectedFiles.length ? 'tw-hidden' : null}>
        <div>
          <div>
            <div className="tw-mb-8">
              <div className="tw-float-left tw-text-left">
                {processedFiles} of {totalFiles} Processed <RenderContinueUpload />
              </div>
              <p className="tw-text-right tw-mb-4">{progressProcessed}%</p>
              <TcProgressBar error={!!errorMessage} progress={progressProcessed} />
            </div>
            <div className="tw-mb-10">
              <RenderTextFileName />
              <p className="tw-text-right tw-mb-4">
                {fileLoaded} / {fileSize} KB
              </p>
              <TcProgressBar error={!!errorMessage} progress={progressUpload} />
            </div>
          </div>
          <div>
            <p className="tw-text-left tw-my-4">Processed Files: {processedFiles}</p>
            <p className="tw-text-left tw-my-4">Files Left: {filesLeft}</p>
            <p className="tw-text-left tw-my-4">Successful Uploads: {successfulUploads}</p>
            <p className="tw-text-left tw-my-4">Failed Upload: {failedUploads}</p>
          </div>
        </div>
      </div>
      <RenderFailedNote />
    </div>
  );
};

TcDropzoneJob.propTypes = {
  onUploading: PropTypes.func,
  id: PropTypes.string,
  setUploadFailed: PropTypes.func,
  auth: PropTypes.object,
  type: PropTypes.string,
  isSampleImages: PropTypes.bool,
  disabled: PropTypes.bool,
  setFiles: PropTypes.func,
  setSuccessfulFilenames: PropTypes.func,
  allowMultipleUpload: PropTypes.bool,
  addToast: PropTypes.func,
  failedNote: PropTypes.string,
  setReadyToProcess: PropTypes.func,
};

TcDropzoneJob.defaultTypes = {
  type: 'image',
  isSampleImages: false,
};

const mapStateToProperties = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProperties, { addToast })(TcDropzoneJob);
