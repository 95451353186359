import { JobCreate, JobEdit, JobList, JobDetail, UserDetail, UserEdit, LogList, LogDetail } from 'pages';

const ClientRoutes = [
  {
    name: 'Job Create',
    component: JobCreate,
    exact: true,
    path: ['/client/job-create', '/client/qc-job-create'],
  },
  {
    name: 'Job Edit',
    component: JobEdit,
    exact: true,
    path: '/client/job-edit/:id',
  },
  {
    name: 'Job List',
    component: JobList,
    exact: true,
    path: '/client/job-list',
  },
  {
    name: 'Job Detail',
    component: JobDetail,
    exact: true,
    path: '/client/job-detail/:id',
  },
  {
    name: 'User Detail',
    component: UserDetail,
    exact: true,
    path: '/client/user-detail',
  },
  {
    name: 'User Edit',
    component: UserEdit,
    exact: true,
    path: '/client/user-edit',
  },
  {
    name: 'Log List',
    component: LogList,
    exact: true,
    path: '/client/log-list',
  },
  {
    name: 'Log Detail',
    component: LogDetail,
    exact: true,
    path: '/client/log-detail/:id',
  },
];

export default ClientRoutes;
