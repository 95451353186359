import PropTypes from 'prop-types';
import React from 'react';
import { PasswordFormInput, WrappedFormInput } from '../FormInput';
import FormLabel from '../FormLabel';
import TcCheckbox from '../TcCheckbox';

const FormFieldClass = ({ noMargin }) => `
  ${!noMargin ? 'tw-mb-8' : ''}
`;

const FormField = (props) => {
  const {
    isPasswordField,
    label,
    labelColor,
    error,
    disabled,
    className,
    showCheckbox = false,
    uncheckedLabel = '',
    checked = false,
    onCheckboxChange,
    noMargin,
    full,
  } = props;

  const RenderLabel = () => {
    if (!label) return null;

    return <FormLabel title={label} error={error} labelColor={labelColor} disabled={disabled} />;
  };

  const RenderField = () => {
    return isPasswordField ? <PasswordFormInput {...props} /> : <WrappedFormInput {...props} />;
  };

  const RenderDisplayField = () => {
    if (!showCheckbox) return RenderField();

    return (
      <div className="tw-flex">
        <TcCheckbox checked={checked} onChange={onCheckboxChange} />
        {!checked ? (
          <WrappedFormInput type="text" placeholder={uncheckedLabel} disabled={true} full={full} />
        ) : (
          RenderField()
        )}
      </div>
    );
  };

  return (
    <div className={className ?? FormFieldClass({ noMargin })}>
      <RenderLabel />
      {RenderDisplayField()}
    </div>
  );
};

FormField.propTypes = {
  // for FormLabel
  label: PropTypes.string,
  labelColor: PropTypes.string,
  error: PropTypes.string,
  // for TcInput
  isPasswordField: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  full: PropTypes.bool,
  textarea: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  uncheckedLabel: PropTypes.string,
  onCheckboxChange: PropTypes.func,
  noMargin: PropTypes.bool,
};

export default FormField;
