import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcContainerStyled = styled.div`
  width: ${({ isPublic }) => (isPublic ? '100%' : 'calc(100% - 280px)')};
  margin-left: ${({ isPublic }) => (isPublic ? '0' : '280px')};
`;

const TcContainerClass = `
  float-left
  pt-8
  pl-8
  pr-8
`;

const TcContainer = (props) => {
  const { children, className, isPublic } = props;

  return (
    <TcContainerStyled isPublic={isPublic} className={TcContainerClass + className}>
      {children}
    </TcContainerStyled>
  );
};

TcContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isPublic: PropTypes.bool,
};

TcContainer.defaultProps = {
  className: '',
};

export default TcContainer;
