import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { featureFlagValue } from 'common/constants/global';
import FeatureFlagsUtils from 'utils/helper/featureFlagsUtils';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    [value],
  );

  return debouncedValue;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useFunctionDebounce = (_function, wait) => {
  let timeout;

  return (..._arguments) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      _function(..._arguments);
    }, wait);
  };
};

const useFeatureFlag = (flagName) => {
  const [isFlagOn, setIsFlagOn] = useState(false);
  useEffect(() => {
    const getVariant = async () => {
      try {
        const variant = await FeatureFlagsUtils.getFlag(flagName);
        setIsFlagOn(variant !== featureFlagValue.off);
      } catch (error) {
        console.error(`Error fetching ${flagName} flag:`, error);
      }
    };
    getVariant();
  }, []);

  return isFlagOn;
};

const useAllFeatureFlags = () => {
  const [allFlags, setAllFlags] = useState([]);
  useEffect(() => {
    const getVariants = async () => {
      try {
        const variant = await FeatureFlagsUtils.getAllFlags();
        setAllFlags(variant);
      } catch (error) {
        console.error('Error fetching feature flags:', error);
      }
    };
    getVariants();
  }, []);

  return allFlags;
};

export { useDebounce, useQuery, useFunctionDebounce, useFeatureFlag, useAllFeatureFlags };
