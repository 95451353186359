import { subJobAPI } from 'configs';
import { handleAsync } from 'utils';

const getSubJobList = async (parameters) => {
  const [res, error] = await handleAsync(subJobAPI.getSubJobList(parameters));
  if (error) throw error;
  return res;
};

const getSubJobDetail = async (parameter) => {
  const [res, error] = await handleAsync(subJobAPI.getSubJobDetail(parameter));
  if (error) throw error;
  return res;
};

const getSubJobTaggers = async (parameters) => {
  const [res, error] = await handleAsync(subJobAPI.getSubJobTaggers(parameters));
  if (error) throw error;
  return res;
};

const assignTaggers = async (parameters, payload) => {
  const [res, error] = await handleAsync(subJobAPI.assignTaggers(parameters, payload));
  if (error) throw error;
  return res;
};

const bulkAssignTaggers = async (parameters, payload) => {
  const [res, error] = await handleAsync(subJobAPI.bulkAssignTaggers(parameters, payload));
  if (error) throw error;
  return res;
};

const getTotalItemsAvailable = async (parameter) => {
  const [res, error] = await handleAsync(subJobAPI.getTotalItemsAvailable(parameter));
  if (error) throw error;
  return res;
};

const unassignTaggers = async (parameter, payload) => {
  const [res, error] = await handleAsync(subJobAPI.unassignTaggers(parameter, payload));
  if (error) throw error;
  return res;
};

const unassignAllTaggers = async (parameter) => {
  const [res, error] = await handleAsync(subJobAPI.unassignAllTaggers(parameter));
  if (error) throw error;
  return res;
};

const SubJobService = {
  getSubJobList,
  getSubJobDetail,
  getSubJobTaggers,
  assignTaggers,
  bulkAssignTaggers,
  getTotalItemsAvailable,
  unassignTaggers,
  unassignAllTaggers,
};

export { SubJobService };
