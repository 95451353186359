import { FINISH_STEP, FIRST_STEP, GOTO_STEP, NEXT_STEP, PREV_STEP, SET_DATA } from 'store/actions';

const STEPS = {
  1: 'Select Job Type',
  2: 'Job Details & Parameters',
  3: 'Job Item Configuration',
  4: 'Define Labels',
  5: 'Review Job',
};

const INITIAL_STATE = {
  currentStep: 1,
  step: STEPS[1],
  steps: STEPS,
  data: null,
};

const createJobReducer = (state = INITIAL_STATE, action) => {
  const { currentStep, steps } = state;
  switch (action.type) {
    case PREV_STEP:
      return {
        ...state,
        currentStep: currentStep - 1,
        step: steps[currentStep - 1],
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: currentStep + 1,
        step: steps[currentStep + 1],
      };
    case FIRST_STEP:
      return {
        ...state,
        currentStep: 1,
        step: steps[1],
      };
    case FINISH_STEP:
      return {
        ...state,
        currentStep: 1,
        step: steps[1],
      };
    case GOTO_STEP:
      return {
        ...state,
        currentStep: action.payload,
        step: steps[action.payload],
      };
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default createJobReducer;
