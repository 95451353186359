import { CHANGE_TAGGING_REVIEW_JOB_LEVEL_LIST_PAGE } from 'store/actions';
import { INITIAL_STATE } from './default';

const taggingReviewJobLevelListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_TAGGING_REVIEW_JOB_LEVEL_LIST_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default taggingReviewJobLevelListPaginationReducer;
