import AdminQcReviewAPI from './AdminQCReviewAPI';
import AssistedTaggingAPI from './AssistedTaggingAPI';
import AuthAPI from './AuthAPI';
import BadgeAPI from './BadgeAPI';
import CashPrizeAPI from './CashPrizeAPI';
import CoinAPI from './CoinAPI';
import DeletionRequestAPI from './DeletionRequestAPI';
import HttpService from './HttpService';
import InviteCodesAPI from './InviteCodesAPI';
import JobAPI from './JobAPI';
import LeaderboardAPI from './LeaderboardAPI';
import LogAPI from './LogAPI';
import PreTaskScreeningAPI from './PreTaskScreeningAPI';
import QaMechanismAPI from './QaMechanismAPI';
import ReferralAPI from './ReferralAPI';
import RestHttp from './RestHttp';
import SubJobAPI from './SubJobAPI';
import TaskQuestionnaireAPI from './TaskQuestionnaireAPI';
import UserAPI from './UserAPI';
import UserTaskAPI from './UserTaskAPI';

const httpService = new HttpService(RestHttp);
const authAPI = new AuthAPI(httpService);
const jobAPI = new JobAPI(httpService);
const subJobAPI = new SubJobAPI(httpService);
const userTaskAPI = new UserTaskAPI(httpService);
const coinAPI = new CoinAPI(httpService);
const badgeAPI = new BadgeAPI(httpService);
const userAPI = new UserAPI(httpService);
const logAPI = new LogAPI(httpService);
const leaderboardAPI = new LeaderboardAPI(httpService);
const preTaskScreeningAPI = new PreTaskScreeningAPI(httpService);
const cashPrizeAPI = new CashPrizeAPI(httpService);
const inviteCodesAPI = new InviteCodesAPI(httpService);
const deletionRequestAPI = new DeletionRequestAPI(httpService);
const referralAPI = new ReferralAPI(httpService);
const qaMechanismAPI = new QaMechanismAPI(httpService);
const taskQuestionnaireAPI = new TaskQuestionnaireAPI(httpService);
const assistedTaggingAPI = new AssistedTaggingAPI(httpService);
const adminQcReviewAPI = new AdminQcReviewAPI(httpService);

export {
  authAPI,
  jobAPI,
  subJobAPI,
  coinAPI,
  badgeAPI,
  userAPI,
  logAPI,
  userTaskAPI,
  preTaskScreeningAPI,
  leaderboardAPI,
  cashPrizeAPI,
  inviteCodesAPI,
  deletionRequestAPI,
  referralAPI,
  qaMechanismAPI,
  taskQuestionnaireAPI,
  assistedTaggingAPI,
  adminQcReviewAPI,
};
