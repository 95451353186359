const initTableHeadData = [
  {
    key: 'date-referred',
    label: 'Date Referred',
    align: 'left',
  },
  {
    key: 'referrer',
    label: 'Referrer',
    align: 'left',
  },
  {
    key: 'referee',
    label: 'Referee',
    align: 'left',
  },
  {
    key: 'reward-amount',
    label: 'Reward Amount',
    align: 'left',
  },
  {
    key: 'min-approved-tasks',
    label: 'Minimum Approved Tasks',
    align: 'left',
  },
  {
    key: 'tasks',
    label: 'Tasks',
    align: 'left',
    width: 15,
  },
];

const perPageOptions = [
  { key: '10', text: '10' },
  { key: '15', text: '15' },
  { key: '20', text: '20' },
  { key: '50', text: '50' },
];

export { initTableHeadData, perPageOptions };
