import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class BadgeService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getBadgesList(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.ALL_BADGES}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getBadgeDetail(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.BADGE_DETAIL}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserBadges(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_USER_BADGES}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserWithBadge(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_USER_WITH_BADGE}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async addBadge(payload) {
    try {
      const url = `${endpoints.ADD_BADGE}`;
      const { data, status } = await this.HTTP.post(url, payload);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateBadgeDetail(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.BADGE_ADMIN}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteBadge(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);

      const url = `${endpoints.BADGE_ADMIN}?${queryParameters}`;

      await this.HTTP.delete(url);
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteUserBadge(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);

      const url = `${endpoints.DELETE_USER_BADGE}?${queryParameters}`;

      const { data, status } = await this.HTTP.delete(url);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async assignBadge(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const endpoint = endpoints.ASSIGN_BADGE;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateUserLevel(payload) {
    try {
      const url = `${endpoints.UPDATE_USER_LEVEL}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadBadgeImage(parameters, body, config) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.BADGE_IMAGE_UPLOAD}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, body, config);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadDefaultBadgeImage(payload) {
    try {
      const url = `${endpoints.BADGE_DEFAULT_IMAGE}`;
      const { data } = await this.HTTP.put(url, payload);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDefaultBadgeImage(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.BADGE_DEFAULT_IMAGE}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getBadgeStatistics(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.BADGE_STATISTICS}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getQueryOptions() {
    try {
      const url = `${endpoints.BADGE_OPTIONS}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
