const USER_LIST_ADMIN = '/v1/users/all';
const USER_TASK_ADMIN = '/v1/users/tasks';
const USER_ALLOWED_LIST_ADMIN = '/v1/admin/subjob/allowed-users';

const USER_DETAIL_ADMIN = '/v1/users/details';
const USER_TASK_DETAIL_ADMIN = '/v1/users/task-detail';
const SET_USER_DETAIL_ADMIN = '/v1/users';
const USER_PROFILE = 'v1/profile';
const USER_AVATAR = 'v1/profile/avatar';
const USER_NATIONALITIES = 'v1/users/nationalities';
const USER_WALLET_HISTORY = 'v1/users/wallet-history';
const SET_USER_REFERRAL = 'v1/users/referral';

export {
  USER_LIST_ADMIN,
  USER_TASK_ADMIN,
  USER_ALLOWED_LIST_ADMIN,
  USER_DETAIL_ADMIN,
  USER_TASK_DETAIL_ADMIN,
  SET_USER_DETAIL_ADMIN,
  USER_PROFILE,
  USER_AVATAR,
  USER_NATIONALITIES,
  USER_WALLET_HISTORY,
  SET_USER_REFERRAL,
};
