import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TcShimmerListStyled = styled.div`
  height: 20px;
  background: #777;

  &.animate {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

const TcShimmer = (props) => {
  const { type, children, show } = props;

  const RenderShimmerList = () => {
    if (!show) return null;

    return <TcShimmerListStyled className="animate" />;
  };

  const RenderShimmerCard = () => {
    if (!show) return null;

    return <div>card</div>;
  };

  const RenderShimmerCustom = () => {
    if (!show) return null;

    return <div>{children}</div>;
  };

  const RenderShimmerImage = () => {
    if (!show) return null;

    return <div>image</div>;
  };

  const RenderShimmer = () => {
    if (type === 'list') return <RenderShimmerList />;
    else if (type === 'card') return <RenderShimmerCard />;
    else if (type === 'custom') return <RenderShimmerCustom />;
    else return <RenderShimmerImage />;
  };

  return (
    <div>
      <RenderShimmer />
    </div>
  );
};

TcShimmer.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
};

TcShimmer.defaultProps = {
  type: 'image',
  show: false,
};

export default TcShimmer;
