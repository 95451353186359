import { user, client, admin } from 'common/constants/global';

const defaultUserListPageObject = {
  [user]: 1,
  [client]: 1,
  [admin]: 1,
};
const defaultUserListTab = user;
const defaultUserListPerPage = '5';
const defaultUserListSearch = '';

export const INITIAL_STATE = {
  pageObject: defaultUserListPageObject,
  tabs: defaultUserListTab,
  perPage: defaultUserListPerPage,
  search: defaultUserListSearch,
};
