import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { warningIcon, infoIcon } from 'assets';
import { TcButton, TcRow, TcLabel, TcIcon } from 'common/components';

const WarningStyle = styled.div`
  background: rgba(203, 125, 0, 0.15);
  border-left: 5px solid var(--color-orange-700);
  color: var(--color-orange-700);
  padding: 1rem;
  display: flex;

  & div {
    color: var(--color-orange-700);
  }
`;

const InfoStyle = styled.div`
  background: rgba(12, 122, 169, 0.15);
  border-left: 5px solid var(--color-blue-100);
  color: var(--color-blue-100);
  padding: 1rem;
  display: flex;

  & div {
    color: var(--color-blue-100);
  }
`;

const TcWarningWithButtons = (props) => {
  const { title, message, label, secondButtonLabel, onClick, onSecondButtonClick, twoButtons, info } = props;

  const RenderIcon = () => {
    const icon = info ? infoIcon : warningIcon;
    return <TcIcon src={icon} alt="warning" width="23px" />;
  };

  const RenderOneButton = () => {
    return <TcButton label={label} onClick={onClick} type={info ? 'info-filled' : ''} />;
  };

  const RenderTwoButtons = () => {
    return (
      <div className="tw-flex">
        {' '}
        <TcButton label={label} onClick={onClick} type={info ? 'info-filled' : ''} />{' '}
        <div className="tw-pl-4">
          {' '}
          <TcButton label={secondButtonLabel} onClick={onSecondButtonClick} type={info ? 'info' : 'contrast'} />{' '}
        </div>{' '}
      </div>
    );
  };

  const RenderButtons = () => {
    return twoButtons ? <RenderTwoButtons /> : <RenderOneButton />;
  };

  const BodyLayout = () => {
    return (
      <TcRow span={24} className="tw-flex tw-items-center tw-justify-between">
        <div>
          <TcRow span={24} className="tw-flex tw-my-2">
            <RenderIcon />
            <TcLabel capitalized={false} className="tw-text-lg tw-items-center" title={title} noMargin />
          </TcRow>
          <TcLabel className="tw-text-sm" title={message} noMargin />
        </div>
        <RenderButtons />
      </TcRow>
    );
  };

  return info ? (
    <InfoStyle>
      <BodyLayout />
    </InfoStyle>
  ) : (
    <WarningStyle>
      <BodyLayout />
    </WarningStyle>
  );
};

TcWarningWithButtons.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  label: PropTypes.string,
  secondButtonLabel: PropTypes.string,
  onClick: PropTypes.func,
  onSecondButtonClick: PropTypes.func,
  twoButtons: PropTypes.bool,
  info: PropTypes.bool,
};

export default TcWarningWithButtons;
