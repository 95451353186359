import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { backIcon, removeIcon } from 'assets';
import {
  TcButton,
  TcGrid,
  TcRow,
  TcLabel,
  TcSection,
  TcHeader,
  TcSkeletonBarLoading,
  TcLabelStatus,
  TcLink,
} from 'common/components';
import { assigned, ongoing } from 'common/constants/global';
import { UserService, UserTaskService } from 'services';
import { addToast } from 'store/actions';
import { handleAsync } from 'utils';
import { useTranslation, dayjs } from 'utils/libraries';

const UserTaskDetailWrapperClass = `
  tw-h-full
  tw-flex
  tw-justify-center
  tw-p-8
`;

const UserTaskDetailPageFrame = styled.div`
  width: 100%;
  height: 100vh;
`;

const UserTaskDetail = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, taskId } = useParams();

  const [userTaskDetail, setUserTaskDetail] = useState({});
  const [loading, setLoading] = useState(true);

  const addToastMessage = (statusCardMessage, statusCardType, closeTiming) => {
    props.addToast({
      message: statusCardMessage,
      styleType: statusCardType,
      closeTiming: closeTiming,
    });
  };
  // GET tagger list
  const getUserTaskDetail = async () => {
    setLoading(true);
    const parameters = {
      user_id: id,
      usertask_id: taskId,
    };

    const [response, error] = await handleAsync(UserService.getTaskDetail(parameters));

    if (error) {
      addToastMessage('Failed to fetch User Task Detail', 'error', 3000);
    } else {
      setUserTaskDetail(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUserTaskDetail();
  }, []);

  const handleBack = () => {
    history.push(`/admin/user-detail/${id}`);
  };

  const handleUnassignTask = async (userTask) => {
    const parameter = {
      job_id: userTask.job_id,
    };

    const payload = [];

    if (userTask) {
      payload.push({ userTaskId: userTask.id });
    }

    const [, error] = await handleAsync(UserTaskService.unassignTaggers(parameter, payload));
    if (error) {
      addToastMessage('Failed to unassign user task', 'error', 3000);
    } else {
      // So that pressing back button doesnt bring you back to a non-existent page
      history.replace(`/admin/user-detail/${id}`);
    }
  };

  const RenderUnassignButton = () => {
    if (userTaskDetail?.status !== ongoing && userTaskDetail?.status !== assigned) return null;
    return (
      <TcButton
        onClick={(event) => {
          event.stopPropagation();
          handleUnassignTask(userTaskDetail);
        }}
        label="Unassign"
        icon={{
          src: removeIcon,
          alt: 'remove icon',
        }}
        renderIcon
        type="contrast"
      />
    );
  };

  const RenderBackButton = () => {
    return (
      <TcButton
        icon={{
          src: backIcon,
          alt: 'back icon',
        }}
        label="Back to User Details"
        type="plain"
        renderIcon
        bold
        onClick={() => handleBack()}
      />
    );
  };

  const RenderLabelDetails = () => {
    if (userTaskDetail?.options?.labels?.length) {
      return userTaskDetail?.options?.labels?.map((item, key) => {
        return (
          <TcRow className="tw-px-1" span={12} key={`label-detail-${key}`}>
            <TcLabel title={`Label ${key + 1}`} color="var(--color-gray-800)" />
            <TcLabel className="tw-font-extrabold" title={item} />
          </TcRow>
        );
      });
    }

    return (
      <TcRow className="tw-px-1" span={12} key={`label-detail-free-input`}>
        <TcLabel title={`Label`} color="var(--color-gray-800)" />
        <TcLabel className="tw-font-extrabold" title={'Free Input Label'} />
      </TcRow>
    );
  };

  return (
    <div className={UserTaskDetailWrapperClass}>
      <UserTaskDetailPageFrame>
        <TcHeader>
          <TcHeader.Title>User Task Detail</TcHeader.Title>
        </TcHeader>
        <TcGrid>
          <TcRow span={24} className="tw-py-6">
            <RenderBackButton />
          </TcRow>
        </TcGrid>

        <TcSection>
          <TcGrid className="tw-border-b-2 tw-mb-6">
            <div className="tw-flex tw-justify-between tw-py-5">
              <TcLabel className="tw-font-extrabold tw-text-xl" title={userTaskDetail?.username} />
              <RenderUnassignButton />
            </div>
          </TcGrid>
          <TcGrid className="tw-border-b-2 tw-mb-6 tw-pb-6">
            <TcRow span={12} className="tw-pr-3">
              <div className="tw-px-1" span={4}>
                <TcLabel title="Job Type" color="var(--color-gray-800)" />
                <TcSkeletonBarLoading active={loading}>
                  <TcLabel
                    className="tw-font-extrabold"
                    title={userTaskDetail?.metadata?.type + ' ' + userTaskDetail?.metadata?.type_name || '-'}
                  />
                </TcSkeletonBarLoading>
              </div>
              <div className="tw-px-1" span={4}>
                <TcLabel title="Task Name" color="var(--color-gray-800)" />
                <TcSkeletonBarLoading active={loading}>
                  <TcLabel className="tw-font-extrabold" title={userTaskDetail?.metadata?.name || '-'} />
                </TcSkeletonBarLoading>
              </div>
              <div className="tw-px-1" span={4}>
                <TcLabel title="Job Id" color="var(--color-gray-800)" />
                <TcSkeletonBarLoading active={loading}>
                  <TcLink
                    color="var(--color-black)"
                    className="tw-font-extrabold tw-underline"
                    title={userTaskDetail?.job_id || '-'}
                    location={`/admin/job-task-list/${userTaskDetail?.job_id}`}
                  />
                </TcSkeletonBarLoading>
              </div>
              <div className="tw-px-1" span={4}>
                <TcLabel title="Description" color="var(--color-gray-800)" />
                <TcSkeletonBarLoading active={loading}>
                  <TcLabel className="tw-font-extrabold" title={userTaskDetail?.metadata?.descriptions || '-'} />
                </TcSkeletonBarLoading>
              </div>
            </TcRow>
            <TcRow span={12} className="tw-pl-3 tw-border-l-2">
              <TcGrid className="tw-py-2" span={12}>
                <TcRow span={4}>
                  <TcLabel title="Date Added" color="var(--color-gray-800)" />
                </TcRow>
                <TcRow span={8}>
                  <TcSkeletonBarLoading active={loading}>
                    <TcLabel
                      capitalized={false}
                      className="tw-font-extrabold"
                      title={
                        userTaskDetail?.created_at
                          ? ': ' + dayjs(userTaskDetail?.created_at).format('DD MMMM YYYY, hh:mm A')
                          : '-'
                      }
                    />
                  </TcSkeletonBarLoading>
                </TcRow>
              </TcGrid>
              <TcGrid className="tw-py-2" span={12}>
                <TcRow span={4}>
                  <TcLabel title="Expiry Date" color="var(--color-gray-800)" />
                </TcRow>
                <TcRow span={8}>
                  <TcSkeletonBarLoading active={loading}>
                    <TcLabel
                      capitalized={false}
                      className="tw-font-extrabold"
                      title={
                        userTaskDetail?.metadata?.expiry
                          ? ': ' + dayjs(userTaskDetail?.metadata?.expiry).format('DD MMMM YYYY, hh:mm A')
                          : '-'
                      }
                    />
                  </TcSkeletonBarLoading>
                </TcRow>
              </TcGrid>
              <TcGrid className="tw-py-2" span={12}>
                <TcRow span={4}>
                  <TcLabel title="Coins Awarded" color="var(--color-gray-800)" />
                </TcRow>
                <TcRow span={8}>
                  <TcSkeletonBarLoading active={loading}>
                    <TcLabel
                      className="tw-font-extrabold"
                      title={
                        userTaskDetail?.payment_info?.coins ? ': ' + Number(userTaskDetail?.payment_info?.coins) : '-'
                      }
                    />
                  </TcSkeletonBarLoading>
                </TcRow>
              </TcGrid>
              <TcGrid className="tw-py-2" span={12}>
                <TcRow span={4}>
                  <TcLabel title="Status" color="var(--color-gray-800)" />
                </TcRow>
                <TcRow span={8}>
                  <TcSkeletonBarLoading active={loading}>
                    <TcLabelStatus type={userTaskDetail?.status} title={userTaskDetail?.status} />
                  </TcSkeletonBarLoading>
                </TcRow>
              </TcGrid>
            </TcRow>
          </TcGrid>
          <TcGrid className="tw-pl-3">
            <TcRow className="tw-pb-3" span={24}>
              <TcLabel className="tw-text-lg tw-underline" title={t('label.page.jobDetail.labelDetails')} />
            </TcRow>
            <TcRow className="tw-pb-3" span={24}>
              <TcSkeletonBarLoading active={loading}>
                <RenderLabelDetails />
              </TcSkeletonBarLoading>
            </TcRow>
          </TcGrid>
        </TcSection>
        <TcSection>
          <TcGrid className="tw-py-6">
            <TcLabel className="tw-text-lg tw-underline" title={t('label.page.jobDetail.progress')} />
          </TcGrid>
          <TcGrid className="tw-pb-3">
            <TcRow className="tw-px-1" span={4}>
              <TcLabel title="Assigned Items" color="var(--color-gray-800)" />
              <TcSkeletonBarLoading active={loading}>
                <TcLabel className="tw-font-extrabold" title={userTaskDetail?.metadata?.item_size} />
              </TcSkeletonBarLoading>
            </TcRow>
            <TcRow className="tw-px-1" span={4}>
              <TcLabel title="Completed Items" color="var(--color-gray-800)" />
              <TcSkeletonBarLoading active={loading}>
                <TcLabel className="tw-font-extrabold" title={userTaskDetail?.metadata?.item_done} />
              </TcSkeletonBarLoading>
            </TcRow>
            <TcRow className="tw-px-1" span={4}>
              <TcLabel title="Items Left" color="var(--color-gray-800)" />
              <TcSkeletonBarLoading active={loading}>
                <TcLabel
                  className="tw-font-extrabold"
                  title={Number(userTaskDetail?.metadata?.item_size) - Number(userTaskDetail?.metadata?.item_done)}
                />
              </TcSkeletonBarLoading>
            </TcRow>
            <TcRow className="tw-px-1" span={4}>
              <TcLabel title="% Done" color="var(--color-gray-800)" />
              <TcSkeletonBarLoading active={loading}>
                <TcLabel className="tw-font-extrabold" title={userTaskDetail?.metadata?.progress} />
              </TcSkeletonBarLoading>
            </TcRow>
          </TcGrid>
        </TcSection>
      </UserTaskDetailPageFrame>
    </div>
  );
};

UserTaskDetail.propTypes = {
  addToast: PropTypes.func,
};

export default connect(null, { addToast })(memo(UserTaskDetail));
