import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledInfo = styled.div`
  background: rgba(12, 122, 169, 0.15);
  border-left: 5px solid var(--color-blue-400);
  color: var(--color-blue-400);
  padding: 1rem;

  & div {
    color: var(--color-blue-400);
  }
`;

const TcInfo = (props) => {
  const { children } = props;

  return (
    <StyledInfo>
      <div className="tw-mb-2 tw-font-bold">Info!</div>
      {children}
    </StyledInfo>
  );
};

TcInfo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TcInfo;
