import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { editWhiteIcon } from 'assets';
import { TcSection, TcGrid, TcHeader, TcAlertDialog, FormField, TcRow, TcButton, TcLabel } from 'common/components';
import { referralReward } from 'common/constants/global';
import { ReferralService } from 'services/ReferralService';
import { handleAsync } from 'utils';
import { yup } from 'utils/libraries';

const ConfigAwards = ({ addToastMessage }) => {
  const [isActionProcessing, setActionProcessing] = useState(false);
  const [isConfigureModalOpen, setConfigureModalOpen] = useState(false);
  const [rewardAmount, setRewardAmount] = useState();
  const [minTasks, setMinTasks] = useState();

  const getCurrentConfig = async () => {
    const parameters = { key: referralReward };

    const [res, error] = await handleAsync(ReferralService.getReferralRewards(parameters));
    if (error) {
      addToastMessage(error?.error?.message, 'error', 3000);
    } else {
      setRewardAmount(res?.referral_reward?.referrer_reward_coins);
      setMinTasks(res?.referral_reward?.minimum_approved_tasks);
    }
  };

  useEffect(() => {
    getCurrentConfig();
  }, []);

  const handleClose = () => {
    formik.resetForm();
    setConfigureModalOpen(false);
  };

  const handleSubmit = async (values) => {
    const parameters = { key: referralReward };
    const payload = {
      referralReward: {
        referrerRewardCoins: values.rewardConfig,
        refereeRewardCoins: values.rewardConfig,
        minimumApprovedTasks: values.minTasksConfig,
      },
    };
    setActionProcessing(true);

    const [, error] = await handleAsync(ReferralService.updateReferralRewards(parameters, payload));
    if (error) {
      addToastMessage(error?.error?.message, 'error');
    } else {
      addToastMessage('Configuration Updated Successfully', 'success', 3000);
      getCurrentConfig();
    }

    handleClose();
    setActionProcessing(false);
  };

  const ConfigureRewardsSchema = yup.object().shape({
    rewardConfig: yup
      .number()
      .integer('Reward value must be an integer')
      .required('Valid input required')
      .moreThan(0, 'Please input a value between 0 - 10,000')
      .lessThan(10000, 'Please input a value between 0 - 10,000'),
    minTasksConfig: yup
      .number()
      .integer('Minimum approved tasks must be an integer')
      .required('Valid input required')
      .moreThan(0, 'Please input a value above 0'),
  });

  const initialValues = { rewardConfig: rewardAmount, minTasksConfig: minTasks };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validationSchema: ConfigureRewardsSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <TcSection>
        <TcGrid className="tw-py-6">
          <TcHeader>
            <TcHeader.Title>One-time Referral Reward</TcHeader.Title>
          </TcHeader>
          <div className="tw-text-gray-1200 tw-pb-4">
            Newly referred users must complete a Minimum Number of Approved tasks for the Referral Reward to be released
            to Referrer and Referee.
          </div>
          <TcRow span={8} className="tw-bg-gray-200 tw-px-5 tw-py-4 tw-rounded-lg">
            <TcRow className="tw-flex">
              <TcRow className="tw-flex-row">
                <TcRow className="tw-flex tw-justify-between">
                  <TcLabel title="Reward Amount" />
                  <div className="tw-pr-10">
                    <span className="tw-text-orange-400 tw-pr-2">{rewardAmount}</span>
                    Coins
                  </div>
                </TcRow>
                <TcRow className="tw-flex tw-justify-between">
                  <TcLabel title="Minimum Approved Tasks" />
                  <div className="tw-pr-10">
                    <span className="tw-text-orange-400 tw-pr-2">{minTasks}</span>
                    Tasks
                  </div>
                </TcRow>
              </TcRow>
              <div className="tw-flex tw-items-center">
                <TcButton
                  className="tw-pr-2 tw-py-3"
                  icon={{
                    src: editWhiteIcon,
                    alt: 'edit icon',
                  }}
                  renderIcon
                  onClick={(e) => {
                    setConfigureModalOpen(true);
                  }}
                />
              </div>
            </TcRow>
          </TcRow>
        </TcGrid>
      </TcSection>
      <TcAlertDialog
        dialogTitle={'Configure One-time Referral Reward'}
        openDialog={isConfigureModalOpen}
        onCloseDialog={handleClose}
        firstOption={'Cancel'}
        onFirstOptionClick={handleClose}
        secondOption={'Update'}
        onSecondOptionClick={formik.handleSubmit}
        isSubmitting={isActionProcessing}
        fullWidth
        secondButtonType={'reject'}
      >
        <p className="tw-text-red-600 tw-mb-6">
          This will update the Reward Amount and/or Approved Tasks for all future Referrals. Current Referrals will not
          be affected.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <FormField
            label="Reward Amount"
            type="number"
            onChange={formik.handleChange}
            name="rewardConfig"
            value={formik.values.rewardConfig}
            error={(formik.handleChange || formik.touched.rewardConfig) && formik.errors.rewardConfig}
            full
            textarea={false}
            placeholder={'Eg. 100'}
            disabled={isActionProcessing}
          />
          <FormField
            label="Minimum Approved Tasks"
            type="number"
            onChange={formik.handleChange}
            name="minTasksConfig"
            value={formik.values.minTasksConfig}
            error={(formik.handleChange || formik.touched.minTasksConfig) && formik.errors.minTasksConfig}
            full
            textarea={false}
            placeholder={'Eg. 1'}
            disabled={isActionProcessing}
          />
        </form>
      </TcAlertDialog>
    </>
  );
};

ConfigAwards.propTypes = {
  rewardAmount: PropTypes.string,
  minTasks: PropTypes.string,
  addToastMessage: PropTypes.func,
};

export default connect(null, {})(memo(ConfigAwards));
