import PropTypes from 'prop-types';
import React from 'react';
import { TcButton, TcGrid, TcLabel, TcRow, TcFormField } from 'common/components';

const TcForm = (props) => {
  const { children, onSubmit, useGrid } = props;
  const Children = React.Children.toArray(children);

  const RenderChildren = () => {
    if (useGrid) {
      return (
        <div>
          <RenderGrid />
          <RenderFormOptionalAction />
          <RenderFormAction />
        </div>
      );
    }

    return (
      <div>
        <RenderFormContent />
        <RenderFormOptionalAction />
        <RenderFormAction />
      </div>
    );
  };

  const RenderGrid = (props) => {
    const { children } = props;
    let element = '';

    element = children ? children : Children;

    return React.Children.map(element, (item, itemIndex) => {
      if (item.type !== Grid) return null;

      const { children } = item.props;

      return (
        <TcGrid key={`form-grid-${itemIndex}`} {...item.props}>
          <RenderRow>
            <children />
          </RenderRow>
        </TcGrid>
      );
    });
  };

  const RenderRow = (props) => {
    const { children } = props;

    return React.Children.map(children, (item, itemIndex) => {
      if (item.type !== Row) return null;

      const { children } = item.props;

      return (
        <TcRow key={`form-row-${itemIndex}`} {...item.props}>
          <RenderFormContent>
            <children />
          </RenderFormContent>
        </TcRow>
      );
    });
  };

  const RenderFormContent = (props) => {
    let child = Children;

    if (Object.keys(props).length > 0) {
      const { children } = props;
      child = children;
    }

    return React.Children.map(child, (item, itemIndex) => {
      if (item.type === FormInput) {
        return RenderFormField(item, itemIndex);
      }

      if (item.type === FormLabel) {
        return RenderFormLabel(item, itemIndex);
      }

      if (item.type === Row) {
        const properties_ = item.props;
        return RenderRow(properties_);
      }

      if (item.type === Grid) {
        const properties_ = item.props;
        return RenderGrid(properties_);
      }

      return null;
    });
  };

  const RenderFormLabel = (item, itemIndex) => {
    const properties_ = item.props;

    return <TcLabel key={`form-label-${itemIndex}`} {...properties_} />;
  };

  const RenderFormField = (item, itemIndex) => {
    const forwardReference = item.props.forwardRef;
    const properties_ = item.props;

    return <TcFormField key={`form-field-${itemIndex}`} ref={forwardReference} {...properties_} />;
  };

  const RenderFormAction = () => {
    return React.Children.map(Children, (item, itemIndex) => {
      if (item.type !== FormAction) return;
      return <TcButton key={`form-action-${itemIndex}`} {...item.props} />;
    });
  };

  const RenderFormOptionalAction = () => {
    return React.Children.map(Children, (item, itemIndex) => {
      if (item.type !== FormOptionalAction) return;

      return (
        <div key={`form-optionalAction-${itemIndex}`} className="tw-mb-4">
          {children}
        </div>
      );
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <RenderChildren />
    </form>
  );
};

TcForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  useGrid: PropTypes.bool,
};

TcForm.defaultProps = {
  useGrid: false,
};

const Grid = ({ children }) => {
  return <div>{children}</div>;
};

Grid.propTypes = {
  children: PropTypes.any,
};

const Row = ({ children }) => {
  return <div>{children}</div>;
};

Row.propTypes = {
  children: PropTypes.any,
};

const FormInput = ({ children }) => {
  return <div>{children}</div>;
};

FormInput.propTypes = {
  children: PropTypes.any,
};

const FormLabel = ({ children }) => {
  return <div>{children}</div>;
};

FormLabel.propTypes = {
  children: PropTypes.any,
};

const FormAction = ({ children }) => {
  return <div>{children}</div>;
};

FormAction.propTypes = {
  children: PropTypes.any,
};

const FormOptionalAction = ({ children }) => {
  return <div>{children}</div>;
};

FormOptionalAction.propTypes = {
  children: PropTypes.any,
};

TcForm.Grid = Grid;
TcForm.Row = Row;
TcForm.FormInput = FormInput;
TcForm.FormLabel = FormLabel;
TcForm.FormAction = FormAction;
TcForm.FormOptionalAction = FormOptionalAction;

export default TcForm;
