import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { useFeatureFlag } from 'utils';

const AdminRoute = (props) => {
  const { path, isLogin, sidebar, setShowSidebar, featureFlagGuard } = props;
  const isFlagOn = useFeatureFlag(featureFlagGuard);

  if (isFlagOn) {
    return <Redirect to="/" />;
  }

  if (sidebar === false) {
    setShowSidebar(false);
  } else {
    setShowSidebar(true);
  }

  if (!isLogin) {
    return <Redirect to="/auth" />;
  } else if (isLogin && path === '/admin/*') {
    return <Redirect to="/admin/job-list" />;
  } else {
    return <Route {...props} />;
  }
};

AdminRoute.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
};

const mapStateToProperties = (state) => {
  return {
    isLogin: state.auth.isLogin,
  };
};

export default connect(mapStateToProperties)(AdminRoute);
