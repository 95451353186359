import MomentUtils from '@date-io/moment'; // pick a date util library
import FormHelperText from '@material-ui/core/FormHelperText';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';

const TcInputDateTime = (props) => {
  const { name, errorMessage, form, field, minDate, maxDate, disablePast, disableFuture } = props;
  const currentError = form.errors[field.name];

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        autoOk
        name={name}
        variant="inline"
        inputVariant="outlined"
        size="small"
        value={field.value}
        onChange={(date) => form.setFieldValue(field.name, date, false)}
        disablePast={disablePast}
        disableFuture={disableFuture}
        KeyboardButtonProps={{
          className: 'focus:tw-outline-none',
          'aria-label': 'change date',
        }}
        minDate={minDate}
        maxDate={maxDate}
        helperText={currentError}
        error={Boolean(currentError)}
        onError={(error) => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        {...props}
      />
      {<FormHelperText error>{errorMessage}</FormHelperText>}
    </MuiPickersUtilsProvider>
  );
};

TcInputDateTime.propTypes = {
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.date,
  maxDate: PropTypes.date,
};

export default TcInputDateTime;
