import {
  CHANGE_JOB_LIST_PAGE,
  CHANGE_JOB_LIST_TAB,
  CHANGE_JOB_LIST_PER_PAGE,
  CHANGE_JOB_LIST_FILTER,
  CHANGE_JOB_LIST_SEARCH,
} from 'store/actions';
import { INITIAL_STATE } from './defaultJobListPaginationState';

const jobListPaginationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_JOB_LIST_PAGE:
      return { ...state, pageObject: action.payload };
    case CHANGE_JOB_LIST_TAB:
      return { ...state, tab: action.payload };
    case CHANGE_JOB_LIST_PER_PAGE:
      return { ...state, perPage: action.payload };
    case CHANGE_JOB_LIST_FILTER:
      return { ...state, filter: action.payload };
    case CHANGE_JOB_LIST_SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export default jobListPaginationReducer;
