import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class AssistedTaggingService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async enableAssistedTagging(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.ENABLE_ASSISTED_TAGGING}?${queryParameters}`;
      const { data, status } = await this.HTTP.post(url);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }
  async regenerateResourceAssistedTagging(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.REGENERATE_RESOURCE}?${queryParameters}`;
      const { data, status } = await this.HTTP.post(url);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async disableAssistedTagging(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.DISABLE_ASSISTED_TAGGING}?${queryParameters}`;
      const { data, status } = await this.HTTP.post(url);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }

  async getAiAssistedGenerationStatus(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.GET_GENERATION_STATUS}?${queryParameters}`;
      const { data, status } = await this.HTTP.get(url);
      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }
}
