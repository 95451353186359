import { taskQuestionnaireAPI } from 'configs';
import { handleAsync } from 'utils';

const getTaskQuestionnaire = async (parameter) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.getTaskQuestionnaire(parameter));
  if (error) throw error;
  return res;
};

const setTaskQuestionnaire = async (parameters) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.setTaskQuestionnaire(parameters));
  if (error) throw error;
  return res;
};

const editTaskQuestionnaire = async (parameters) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.editTaskQuestionnaire(parameters));
  if (error) throw error;
  return res;
};

const enableTaskQuestionnaire = async (parameter) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.enableTaskQuestionnaire(parameter));
  if (error) throw error;
  return res;
};

const disableTaskQuestionnaire = async (parameter) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.disableTaskQuestionnaire(parameter));
  if (error) throw error;
  return res;
};

const getTaskQuestionnaireUserSubmit = async (parameter) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.getTaskQuestionnaireUserSubmit(parameter));
  if (error) throw error;
  return res;
};

const downloadTaskQuestionnaire = async (parameters) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.downloadTaskQuestionnaire(parameters));
  if (error) throw error;
  return res;
};

const downloadTaskQuestionnaireState = async (parameters) => {
  const [res, error] = await handleAsync(taskQuestionnaireAPI.downloadTaskQuestionnaireState(parameters));
  if (error) throw error;
  return res;
};

const TaskQuestionnaireService = {
  getTaskQuestionnaire,
  setTaskQuestionnaire,
  enableTaskQuestionnaire,
  disableTaskQuestionnaire,
  editTaskQuestionnaire,
  getTaskQuestionnaireUserSubmit,
  downloadTaskQuestionnaire,
  downloadTaskQuestionnaireState,
};

export { TaskQuestionnaireService };
