import PropTypes from 'prop-types';
import React from 'react';

const TcSectionClass = `
  tw-rounded-lg
  tw-bg-white
  tw-pb-4
  tw-px-8
  tw-mt-6
`;

const TcSection = (props) => {
  const { children, className } = props;

  return <div className={TcSectionClass + className}>{children}</div>;
};

TcSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TcSection.defaultProps = {
  className: '',
};

export default TcSection;
