import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class QaMechanismService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getDecisionList(parameters) {
    const { userType, payload } = parameters || {};
    try {
      const queryParameters = userType === 'admin' ? buildQueryParams(payload) : null;
      const url = `${endpoints.QA_MECHANISM_DECISION_LIST}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getPopulationSize(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.POPULATION_SIZE}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDownloadSample(parameters) {
    const { userType, payload } = parameters || {};
    try {
      const queryParameters = userType === 'admin' ? buildQueryParams(payload) : null;
      const url = `${endpoints.QA_MECHANISM_DOWNLOAD_SAMPLE}?${queryParameters}`;
      const data = await this.HTTP.post(
        url,
        {
          'Access-Control-Expose-Headers': 'Content-Disposition',
        },
        { responseType: 'blob' },
      );

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSamples(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.SAMPLES}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setSamples(parameters) {
    const { payloadBody } = parameters;

    try {
      const url = `${endpoints.SAMPLES}`;
      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response?.data;
    }
  }
}
