import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const TcLabelStatusStyled = styled.div`
  color: ${(props) => props.color};
  background-color: ${({ type }) => handleTypeColor(type)};

  ${({ error }) =>
    error &&
    css`
      color: var(--color-red-300);
    `}
`;

const handleTypeColor = (type) => {
  switch (type) {
    case 'completed':
      return 'var(--color-label-completed)';
    case 'ongoing':
      return 'var(--color-label-ongoing)';
    case 'pending':
      return 'var(--color-label-ongoing)';
    case 'approved':
      return 'var(--color-label-completed)';
    case 'rejected':
      return 'var(--color-red-200)';
    case 'partial':
      return 'var(--color-label-partial)';
    case 'archived':
      return 'var(--color-label-archived)';
    case 'expired':
      return 'var(--color-red-200)';
    default:
      return 'var(--color-transparent)';
  }
};

const TcLabelStatusClass = `
  tw-text-xs
  tw-text-center
  tw-capitalize
  tw-px-2
  tw-py-1
  tw-rounded-lg
`;

const TcLabelStatus = (props) => {
  const { title, color, type, className } = props;

  return (
    <TcLabelStatusStyled className={TcLabelStatusClass + className} color={color} type={type}>
      {title}
    </TcLabelStatusStyled>
  );
};

TcLabelStatus.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

TcLabelStatus.defaultProps = {
  color: 'var(--color-white)',
  type: '',
  className: '',
};

export default TcLabelStatus;
